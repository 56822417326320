export enum tableConfigKeys {
  ESTIMATE_CONFIG = "estimate-config",
  WAREHOUSE_PRODUCT_CONFIG = "warehouse-product-config",
  ORDER_CONFIG = "supply-order-config",
  WAREHOUSE_ORDER_CONFIG = "warehouse-order-config",
  IMPOST_ORDER_CONFIG = "impost-order-config",
  WAREHOUSE_PRODUCT_OFFER_CONFIG = "warehouse-product-offer-config",
  PAYMENT_ORDER_CONFIG = "payment-order-config",
  PAYMENT_INCOME_OR_EXPENSE = "payment-income-or-expense",
  COMPANY_PERSON = "company-person",
  WAREHOUSE_PRODUCTS = "warehouse-products",
  INVENTORY = "inventory",
  WAREHOUSE_SINGLE_PRODUCT = "warehouse-single-product",
  WAREHOUSE_TRANSFERS = "warehouse-transfers",
  WAREHOUSE_DEFECTS = "warehouse-defects",
  INVENTORY_REQUESTS = "inventory-requests",
  INVENTORY_DEFECTS = "inventory-defects",
  MONITORING_PAYMENTS = "monitoring-payments",
  MONITORING_WORKS = "monitoring-works",
  COUNTERPARTS_EXPECTED_PAYMENT_INCOME = "counter-expected-payments-income",
  COUNTERPARTS_EXPECTED_PAYMENT_EXPENSE = "counter-expected-payments-expense",
  COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_INCOME = "counter-expected-payments-template-income",
  COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_EXPENSE = "counter-expected-payments-template-expense",
  COUNTERPARTS_PAYMENTS_WORKS = "counter-payments-works",
  COUNTERPARTS_PAYMENTS_INVENTORY = "counter-payments-inventory",
  PAYMENT_EXPECTED_INCOME = "payment-expected-income",
  PAYMENT_EXPECTED_EXPENSE = "payment-expected-expense",
  PAYMENT_EXPECTED_TEMPLATE_INCOME = "payment-expected-template-income",
  PAYMENT_EXPECTED_TEMPLATE_EXPENSE = "payment-expected-template-expense",
  PAYMENT_WORKS = "payment-works",
  LABORATORY = "laboratory",
  TRANSFER_MAKING_TABLE = "transfer-making-table",
  COUNTERPARTS_INCOME_OR_EXPENSE = "counterparts-income-or-expense",
  COUNTERPARTS_ORDER_CONFIG = "counterparts-order-config",
  LIST_OF_WORKS = 'list-of-works',
  DONE_WORKS = "done-works",
  PROCESS_DATA = 'process-data',
  WAREHOUSE_INCOME_EXPENSE_HISTORY = "warehouse-income-expense-history",
  WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY = "warehouse-income-expense-history-group-by"
}
