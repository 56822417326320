import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { getPopupContainer, PartyForm, useAgents } from "modules/party/create";
import Discount from "modules/party/create/form/right/top/discount";

import { DatePicker, Select } from "components";

type Props = {
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const RightTop: React.FC<Props> = ({ form, isUpdate }) => {
  const { visible } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { visible: updateVisible } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { agents, isLoading } = useAgents({ enabled: visible || updateVisible });
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <Select
        allowClear
        name="agent_id"
        label={t("payment.Vositachi")}
        loading={isLoading}
        withoutMessage={true}
        control={form.control}
        placeholder={t("payment.Tanlang")}
        getPopupContainer={getPopupContainer(isUpdate ? "party-right-content-update" : undefined)}
        options={agents?.map(agent => ({
          key: agent?.id,
          label: agent?.full_name,
          value: agent?.id,
          props: {
            name: agent?.full_name
          }
        }))}
      />
      <DatePicker
        allowClear
        name="delivery_date"
        withoutMessage={true}
        placeholder={t("payment.Tanlang")}
        control={form.control}
        label={t("payment.Yetkazish sanasi")}
        onChange={(_, date) => {
          form.setValue("delivery_date", (date as string) || undefined);
        }}
      />
      <DatePicker
        allowClear
        name="payment_date"
        withoutMessage={true}
        placeholder={t("payment.Tanlang")}
        control={form.control}
        label={t("payment.To'lash sanasi")}
        onChange={(_, date) => {
          form.setValue("payment_date", (date as string) || undefined);
        }}
      />
      <Discount isUpdate={isUpdate} form={form} />
    </div>
  );
};

export default RightTop;
