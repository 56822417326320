import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { WidgetSettingsForm } from "modules/dashboard";

import { Select } from "components/fields";

interface IProps {
  form: UseFormReturn<WidgetSettingsForm>;
}

const Limit: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();

  const selectOptions = [
    {
      label: "10",
      value: 10
    },
    {
      label: "20",
      value: 20
    },
    {
      label: "30",
      value: 30
    },
    {
      label: "40",
      value: 40
    },
    {
      label: "50",
      value: 50
    }
  ];

  return <Select options={selectOptions} name="limit" label={t("global.Limit")} control={form.control} allowClear />;
};

export default Limit;
