import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoComplete, Button, Dropdown, Form, Input, Select, Switch } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { MenuProps } from "antd/lib";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import WIthNDSIcon from "features/supply/assets/icons/WIthNDSIcon";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { useGetCurrenciesSelect } from "../../../../../app/service/queries";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { PHONE_MASK } from "../../../../../app/utils/constants/phoneMask";
import { cx } from "../../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import PencilIcon from "../../../../../payment/assets/icons/PencilIcon";
import PhoneCallIcon from "../../../../assets/icons/PhoneCallIcon";
import { OfferErrorModel, OfferFormProductModel } from "../../../../utils/models/OfferFormModel";

import styles from "./offerList.module.scss";

const { Option } = Select;
const { TextArea } = Input;

type Props = OfferFormProductModel & {
  productKey: string;
  index: number;
};

const OfferListItem: React.FC<Props> = props => {
  const { index, productKey, uniqueId, description, phone, vat } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: currencies } = useGetCurrenciesSelect();
  const { data: companyPersons } = useGetCompanyPersonSelect();
  const [viewDesc, setViewDesc] = useState<boolean>(!!description);
  const [viewPhone, setViewPhone] = useState<boolean>(!!phone);
  const [viewVAT, setVAt] = useState<boolean>(vat!);
  const { warehouseOffers, offerErrors } = useAppSelector(state => state.supplyOfferReducer.offerModal);
  const { setDeleteOfferItem, setChangeOfferItem, setOfferModalErrorItem } = supplyOfferActions;

  const onDeleteOffer = () => {
    dispatch(
      setDeleteOfferItem({
        productKey,
        uniqueId
      })
    );
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    dispatch(
      setChangeOfferItem({
        value: localeFormatter(value) as never,
        productKey,
        uniqueId,
        key: "amount"
      })
    );

    if (offerErrors?.length > 0) {
      dispatch(
        setOfferModalErrorItem({
          key: "amount",
          uniqueId,
          action: !value
        })
      );
    }
  };

  const onViewPhone = () => {
    setViewPhone(true);
  };

  const onViewDesc = () => {
    setViewDesc(true);
  };

  const onViewNDS = () => {
    setVAt(true);
  };

  const companyPersonOptions = companyPersons?.map(item => ({
    label: item?.name,
    value: item?.name,
    key: JSON.stringify({
      id: item?.id,
      name: item?.name,
      phone: item?.person?.phone
    })
  }));

  const filterCompanyPersonSelect = (inputValue: string, option: DefaultOptionType | undefined) => {
    const optionValue = option?.value as string;

    return optionValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  };

  const onSelectCompanyPerson = (_: string, option: DefaultOptionType) => {
    const record = JSON.parse(option.key as string) as {
      id: number;
      name: string;
      tin: string;
      phone: string;
    };

    dispatch(
      setChangeOfferItem({
        value: record.id as never,
        productKey,
        uniqueId,
        key: "company_person_id"
      })
    );

    dispatch(
      setChangeOfferItem({
        value: record.phone as never,
        productKey,
        uniqueId,
        key: "phone"
      })
    );

    setViewPhone(true);
  };

  const onChangeCurrency = (e: number) => {
    dispatch(
      setChangeOfferItem({
        value: e as never,
        productKey,
        uniqueId,
        key: "currency_id"
      })
    );
  };

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(
      setChangeOfferItem({
        value: value as never,
        productKey,
        uniqueId,
        key: "phone"
      })
    );
  };

  const onChangeDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setChangeOfferItem({
        value: e.target.value as never,
        productKey,
        uniqueId,
        key: "description"
      })
    );
  };

  const onChangePerson = (e: string) => {
    dispatch(
      setChangeOfferItem({
        value: e as never,
        productKey,
        uniqueId,
        key: "name"
      })
    );

    dispatch(
      setChangeOfferItem({
        value: undefined as never,
        productKey,
        uniqueId,
        key: "company_person_id"
      })
    );

    if (offerErrors?.length > 0) {
      dispatch(
        setOfferModalErrorItem({
          key: "name",
          uniqueId,
          action: !e
        })
      );
    }
  };

  const onChangeWithNDs = (e: boolean) => {
    dispatch(
      setChangeOfferItem({
        value: e as never,
        productKey,
        uniqueId,
        key: "vat"
      })
    );
  };

  const offerItemValue = (key: keyof OfferFormProductModel): string | number | boolean | undefined =>
    warehouseOffers?.find(item => item.productKey === productKey)?.offers?.find(item => item.uniqueId === uniqueId)?.[
      key
    ];

  const ordinalNumber = () => {
    const countOffers = warehouseOffers?.find(item => item.productKey === productKey)?.offers?.length ?? 0;

    return countOffers - index;
  };

  const getOfferItemError = useCallback(
    (key: keyof OfferErrorModel, uniqueId: string) => {
      if (offerErrors?.length > 0) {
        return offerErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [offerErrors]
  );

  const errorStyle = (key: keyof OfferErrorModel) => ({
    border: getOfferItemError(key, uniqueId) ? "1px solid red" : "",
    height: "max-content"
  });

  const returnItems = () => {
    const items: MenuProps["items"] = [
      {
        key: "2",
        label: (
          <div
            className="flex items-center gap-2"
            onClick={e => {
              e.stopPropagation();
              onDeleteOffer();
            }}
          >
            <DeleteIcon /> {t("payment.O'chirish")}
          </div>
        )
      }
    ];

    if (!viewVAT) {
      items.splice(0, 0, {
        key: "1",
        label: (
          <div
            className="flex items-center gap-2"
            onClick={e => {
              e.stopPropagation();
              onViewNDS();
            }}
          >
            <WIthNDSIcon /> {t("payment.NDS qo'shish")}
          </div>
        )
      });
    }

    return items;
  };

  return (
    <div draggable className={styles.list__item}>
      <div className={styles.form__item}>
        <AutoComplete
          placeholder={t("payment.Nomi")}
          onChange={onChangePerson}
          style={errorStyle("name")}
          options={companyPersonOptions}
          onSelect={onSelectCompanyPerson}
          value={offerItemValue("name") as string}
          filterOption={filterCompanyPersonSelect}
        />
      </div>
      <div className={cx(styles.list__item__amount, "amount")} style={errorStyle("amount")}>
        <Form.Item rules={formRules()}>
          <Input placeholder={t("payment.Narx")} onChange={onChangeAmount} value={offerItemValue("amount") as number} />
        </Form.Item>
        <Select
          value={Number(offerItemValue("currency_id"))}
          suffixIcon={<SelectSuffixIcon />}
          onChange={onChangeCurrency}
        >
          {currencies?.map(item => (
            <Option key={item?.id} value={item?.id}>
              {item?.symbol}
            </Option>
          ))}
        </Select>
      </div>
      <ConditionalRender if={viewPhone}>
        <div className={styles.form__item}>
          <ReactInputMask
            mask={PHONE_MASK}
            onChange={onChangePhone}
            placeholder={t("payment.Telefon raqam")}
            className={cx("phone-input")}
            value={offerItemValue("phone") as string}
          />
        </div>
      </ConditionalRender>
      <ConditionalRender if={viewDesc}>
        <div className={styles.form__item}>
          <TextArea
            placeholder={t("payment.Izoh")}
            onChange={onChangeDesc}
            value={offerItemValue("description") as string}
          />
        </div>
      </ConditionalRender>
      <ConditionalRender if={viewVAT}>
        <div className={styles.form__item}>
          <div className="flex items-center justify-between rounded-lg border border-solid border-gray-100 bg-gray-100 p-2">
            <span className="text-sm font-medium text-gray-500">{t("payment.NDS bilanmi")}</span>
            <Switch onChange={onChangeWithNDs} checked={offerItemValue("vat") as boolean} />
          </div>
        </div>
      </ConditionalRender>

      <div className={styles.list__item__info}>
        <p>
          {t("payment.Taklif")} #{ordinalNumber()}
        </p>
        <div className={styles.actions}>
          <ConditionalRender if={!viewDesc}>
            <Button onClick={onViewDesc}>
              <PencilIcon />
            </Button>
          </ConditionalRender>
          <ConditionalRender if={!viewPhone}>
            <Button onClick={onViewPhone}>
              <PhoneCallIcon />
            </Button>
          </ConditionalRender>
          <Dropdown trigger={["click"]} menu={{ items: returnItems() as MenuProps["items"] }}>
            <Button>
              <DotsVerticalIcon />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default OfferListItem;
