import React, { useEffect, useRef } from "react";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import RollerBrushIcon from "features/app/assets/icons/RollerBrushIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { colors } from "features/app/utils/constants/colors";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import ProductIcon from "features/supply/assets/icons/ProductIcon";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { isEmptyObj, RU } from "modules/common";
import { processTableConfig } from "modules/works";
import { useProcessView } from "modules/works/queries/use-process-view";
import { ProcessTableData } from "modules/works/schema";

import { LoadingIndicator, Table } from "components";

import useDebounce from "../../../../../components/use-debounce/use-debounce";
import { ProcessHistoryViewDrawer } from "../../../drawers";
import { ProcessViewModal } from "../../../modals";

import styles from "./process-table.module.scss";

// eslint-disable-next-line react-refresh/only-export-components
export const icons: Record<string, React.ReactNode> = {
  mechanism: <RollerBrushIcon />,
  product: <ProductIcon />
};

const ProcessTable: React.FC = () => {
  const { t, i18n } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const baseCurrency = getBaseCurrency();
  const { queries } = useQueryParams();
  const { group_by } = queries();
  const [visible, setVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState<ProcessTableData | null>(null);

  const [tableColumns, setColumns] = React.useState<ColumnsType<ProcessTableData>>([]);

  const { processView, isLoading } = useProcessView({ enabled: true });
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.PROCESS_DATA, processTableConfig);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.PROCESS_DATA]);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PROCESS_DATA, true, false);

  const getName = (record: ProcessTableData) => {
    if (!isEmptyObj(record.company_person!)) {
      return `${record.company_person?.name![0].toUpperCase()}${record.company_person?.name![1].toUpperCase()}`;
    }
    if (!isEmptyObj(record.user!)) {
      return <CustomAvatar image={record.user?.image as string} name={record?.user?.full_name} />;
    }
    if (!isEmptyObj(record?.inventory)) {
      return `${record.inventory?.name![0].toUpperCase()}${record.inventory?.name![1].toUpperCase()}`;
    }
    return "";
  };

  const columns: ColumnsType<ProcessTableData> = [
    {
      title: t("works.Resurs turi va nomi"),
      render: (_value: any, record) => (
        <>
          {record?.product?.resource?.type === "product" ? (
            <div className="flex items-center gap-3">
              <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
                {icons[record?.product?.resource?.type as keyof typeof icons] ||
                  `${record?.product?.name[i18n.language][0]?.toUpperCase()}${record?.product?.name[i18n.language][1]?.toUpperCase()}`}
              </div>
              <span className="text-sm font-normal text-gray-900">
                <PopoverShortName title={record?.product?.name[i18n.language]} length={20} />
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border-2 border-solid border-white bg-gray-100 text-base font-medium text-gray-600">
                {icons[record?.product?.resource?.type as string] || getName(record)}
              </div>
              <span className="text-sm font-normal text-gray-900">
                <PopoverShortName
                  title={
                    (record?.company_person?.name as string) ||
                    (record?.user?.full_name as string) ||
                    (record?.inventory?.name as string)
                  }
                  length={20}
                />
              </span>
            </div>
          )}
        </>
      ),
      width: 668,
      key: "resource_type_and_name",
      className: "resource_type_and_name",
      fixed: "left"
    },
    {
      title: t("works.Sana"),
      render: (_value: any, record) => <div>{record?.created_date || "-"}</div>,
      key: "created_at",
      className: "created_at",
      width: 189
    },
    {
      title: t("works.Miqdori"),
      dataIndex: "quantity",
      key: "quantity",
      className: "quantity",
      width: 190
    },
    {
      title: t("works.Birlik"),
      render: (_value: any, record) => (
        <div className="text-sm font-medium text-gray-700">{record?.unit?.symbol[i18n.language]}</div>
      ),
      width: 119,
      key: "unit.symbol",
      className: "unit.symbol"
    },
    {
      title: t("works.Birlik summasi"),
      render: (_value: any, record) => (
        <div className="text-sm font-medium text-gray-700">
          {record?.amount?.toLocaleString(RU)}{" "}
          {group_by && group_by === "true" ? baseCurrency.symbol : record?.currency?.symbol}
        </div>
      ),
      width: 189,
      key: "unit.amount",
      className: "unit.amount"
    },
    {
      title: t("works.Umumiy summasi"),
      render: (_value: any, record) => (
        <div className="text-sm font-medium text-gray-700">
          {record?.total_amount?.toLocaleString(RU)}{" "}
          {group_by && group_by === "true" ? baseCurrency.symbol : record?.currency?.symbol}
        </div>
      ),
      width: 189,
      key: "total_amount",
      className: "total_amount"
    }
  ];

  const saveColumns = () => {
    const columnsToSave = tableColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.PROCESS_DATA,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ProcessTableData>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const footer = () => (
    <Pagination
      paginationProps={{
        current: processView?.current_page,
        total: processView?.total
      }}
    />
  );

  const onRow = (record: ProcessTableData) => ({
    onClick: () => {
      if (!group_by) {
        setVisible(true);
        setRecord(record);
      } else {
        setOpen(true);
        setRecord(record);
      }
    }
  });

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns: (group_by && group_by === "true"
            ? columns?.filter(col => col?.className !== "created_at")
            : columns) as never,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              defaultData={processTableConfig}
              configKey={tableConfigKeys.PROCESS_DATA}
              locations={[CustomFieldLocationEnum.PROCESS_DATA]}
              isNeedSize
            />
          ),
          render: (_, _record: ProcessTableData) => (
            <div className={styles.arrow}>
              <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
            </div>
          ),
          width: 70,
          className: "process_action_dots",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields, group_by]);

  return (
    <div>
      <div className={styles.top}>
        <Table<ProcessTableData>
          columns={tableColumns}
          dataSource={processView?.data}
          rootClassName={styles.process_table}
          rowClassName={styles.row}
          footer={footer}
          pagination={false}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          scroll={{ y: "calc(100vh - 315px)" }}
          onChangeColumns={onChangeColumns}
          onRow={onRow}
        />
      </div>
      <ProcessViewModal record={record!} visible={visible} setVisible={setVisible} />
      <ProcessHistoryViewDrawer record={record!} visible={open} setVisible={setOpen} />
    </div>
  );
};

export default ProcessTable;
