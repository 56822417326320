import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CompanyPersonEmpty, getPopupContainer, PartyForm } from "modules/party/create";
import { useCompanyPersons } from "modules/party/view";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const [search, setSearch] = useState<string>();
  const { companyPersons, isLoading } = useCompanyPersons();
  const { t } = useTranslation();

  const onChange = (e: number, option: any) => {
    if (option) {
      form.setValue(`warehouse_products.${index}.company_person_id`, e);
      form.setValue(`warehouse_products.${index}.company_person_name`, option.props?.name);
    } else {
      form.setValue(`warehouse_products.${index}.company_person_id`, null);
      form.setValue(`warehouse_products.${index}.company_person_name`, undefined);
    }
  };

  return (
    <Select
      allowClear
      suffixIcon={null}
      loading={isLoading}
      onChange={onChange}
      control={form.control}
      placeholder={t("payment.Ta'minotchi")}
      wrapperClassName="h-full"
      popupMatchSelectWidth={false}
      onSearch={value => setSearch(value)}
      name={`warehouse_products.${index}.company_person_id`}
      notFoundContent={<CompanyPersonEmpty search={search} fieldName={`warehouse_products.${index}`} />}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={companyPersons?.map(person => ({
        key: person?.id,
        value: person?.id,
        label: (
          <TooltipShortName
            length={11}
            arrow={false}
            title={person?.name as string}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: person?.name
        }
      }))}
    />
  );
};

export default Index;
