import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// self.addEventListener("notificationclick", (event) => {
//   event.stopImmediatePropagation();
//   event.notification.close();
//   event.waitUntil(window.open("https://example.com"));
// });

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const sendRequest = (setToken: (token: string) => void) => {
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
      })
        .then(currentToken => {
          if (currentToken) {
            setToken(currentToken);
          } else {
            console.warn("Failed to generate the registration token.");
          }
        })
        .catch(err => {
          console.error("An error occurred when requesting to receive the token.", err);
        });
    }
    return undefined;
  });
};

// onBackgroundMessage(messaging, (payload) => {
//   const dataFromServer = payload.notification;
//   const notificationTitle = dataFromServer?.title;
//   const notificationOptions = {
//     body: dataFromServer?.body,
//     image: dataFromServer?.image,
//     data: {
//       url: "https://google.com",
//     },
//   };
//
//   return self.registration.showNotification(notificationTitle, notificationOptions);
// });
//
// self.addEventListener("notificationclick", (event) => {
//   const e = event as any;
//   const urlToRedirect = e.notification.data.url;
//   e.notification.close();
//   e.waitUntil(self.clients.openWindow(urlToRedirect));
// });
