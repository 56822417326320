export const parseParamsId = (params?: string) => {
  if (params) {
    const decodedParams = decodeURIComponent(params);
    const splitParams = decodedParams.split("-");
    const id =
      splitParams.length > 1 && /^\d+$/.test(splitParams[splitParams.length - 1]) ? splitParams.pop() : undefined;
    const name = splitParams.join("-");

    return { name, id };
  }
  return { name: undefined, id: undefined };
};
