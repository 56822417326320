export type RouteSubmodules = [
  {
    key: "Kirim-chiqim";
    actions: [
      {
        key: "Kirim-chiqim yaratish";
        create: true;
      },
      {
        key: "O'tkazma qilish";
        transfer: true;
      },
      {
        key: "Statistika";
        statistics: true;
      },
      {
        key: "To'lovlarni ko'rish";
        view: true;
      },
      {
        key: "Kirim-chiqim chernovoy yaratish";
        createDraft: true;
      },
      {
        key: "To'lovni o'chirish";
        delete: true;
      },
      {
        key: "Transferni tasdiqlash yoki rad etish";
        transferCheck: true;
      },
      {
        key: "Kirim-chiqimni tahrirlash";
        update: true;
      },
      {
        key: "Kirim-chiqimda chat";
        chatView: true;
      },
      {
        key: "Kirim-chiqmni yuklab olish";
        upload: true;
      },
      {
        key: "Tolovlar tarixini o'chirish";
        deleteDeletedArchive: true;
      },
      {
        key: "Kirim qilish";
        makeIncome: true;
      }
    ];
  },
  {
    key: "Kutilayotgan to'lovlar";
    actions: [
      {
        key: "Kutilayotgan to'lovni kiritish";
        create: true;
      },
      {
        key: "Kutilayotgan to'lovlar jadvali";
        view: true;
      },
      {
        key: "Statistika";
        statistics: true;
      },
      {
        key: "Jadvalni o'chirish";
        delete: true;
      }
    ];
  },
  {
    key: "Qarzlarim";
    actions: [
      {
        key: "Qarzdorlikni kiritish";
        create: true;
      },
      {
        key: "Chiqim qilish";
        makeExpense: true;
      },
      {
        key: "Statistika";
        statistics: true;
      },
      {
        key: "Qazdorlik ro'yxati";
        view: true;
      },
      {
        key: "Jadvalni o'chirish";
        delete: true;
      }
    ];
  },
  {
    key: "Buyurtmalarga to'lovlar";
    actions: [
      {
        key: "Buyurtmalar to'lovini o'chirish";
        deleteOrders: true;
      },
      {
        key: "Buyurtma to'lovlar tarixi";
        orderHistory: true;
      },
      {
        key: "Buyurtmalarga to'lov qilish";
        create: true;
      },
      {
        key: "Buyurmalarga to'lovlar jadvali";
        orderView: true;
      },
      {
        key: "Buyurtmalarda chat";
        chatView: true;
      },
      {
        key: "Buyurtmalarni yuklab olish";
        upload: true;
      },
      {
        key: "Buyurtmalarni tahrirlash";
        update: true;
      },
      {
        key: "Buyurtmani yakunlash";
        completed: true;
      },
      {
        key: "Buyurtmani bekor qilish";
        rejected: true;
      }
    ];
  },
  {
    key: "Ishlar uchun to'lovlar";
    actions: [
      {
        key: "Statistika";
        statistics: true;
      },
      {
        key: "Ishlar uchun to'lovlar jadvali";
        view: true;
      },
      {
        key: "Ishlarni yuklab olish";
        upload: true;
      }
    ];
  },
  {
    key: "Inventarlarga to'lovlar";
    actions: [];
  },
  {
    key: "To'lov kalendari";
    actions: [];
  },
  {
    key: "Maxsus imkoniyatlar";
    actions: [
      {
        key: "O'tkazma qilish";
        transfer: true;
      },
      {
        key: "Kutilayotgan o'tkazmalar";
        viewPendingTransfers: true;
      },
      {
        key: "Konvertatsiya qilish";
        exchange: true;
      },
      {
        key: "Partiya yaratish";
        createParty: true;
      },
      {
        key: "Hisobotni yuklab olish";
        downloadReport: true;
      },
      {
        key: "To'lovlar tarixi";
        paymentHistory: true;
      }
    ];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "Kirim-chiqim",
    actions: [
      {
        key: "Kirim-chiqim yaratish",
        create: true
      },
      {
        key: "O'tkazma qilish",
        transfer: true
      },
      {
        key: "Statistika",
        statistics: true
      },
      {
        key: "To'lovlarni ko'rish",
        view: true
      },
      {
        key: "Kirim-chiqim chernovoy yaratish",
        createDraft: true
      },
      {
        key: "To'lovni o'chirish",
        delete: true
      },
      {
        key: "Transferni tasdiqlash yoki rad etish",
        transferCheck: true
      },
      {
        key: "Kirim-chiqimni tahrirlash",
        update: true
      },
      {
        key: "Kirim-chiqimda chat",
        chatView: true
      },
      {
        key: "Kirim-chiqmni yuklab olish",
        upload: true
      },
      {
        key: "Tolovlar tarixini o'chirish",
        deleteDeletedArchive: true
      },
      {
        key: "Kirim qilish",
        makeIncome: true
      }
    ]
  },
  {
    key: "Kutilayotgan to'lovlar",
    actions: [
      {
        key: "Kutilayotgan to'lovni kiritish",
        create: true
      },
      {
        key: "Kutilayotgan to'lovlar jadvali",
        view: true
      },
      {
        key: "Statistika",
        statistics: true
      },
      {
        key: "Jadvalni o'chirish",
        delete: true
      }
    ]
  },
  {
    key: "Qarzlarim",
    actions: [
      {
        key: "Qarzdorlikni kiritish",
        create: true
      },
      {
        key: "Chiqim qilish",
        makeExpense: true
      },
      {
        key: "Statistika",
        statistics: true
      },
      {
        key: "Qazdorlik ro'yxati",
        view: true
      },
      {
        key: "Jadvalni o'chirish",
        delete: true
      }
    ]
  },
  {
    key: "Buyurtmalarga to'lovlar",
    actions: [
      {
        key: "Buyurtmalar to'lovini o'chirish",
        deleteOrders: true
      },
      {
        key: "Buyurtma to'lovlar tarixi",
        orderHistory: true
      },
      {
        key: "Buyurtmalarga to'lov qilish",
        create: true
      },
      {
        key: "Buyurmalarga to'lovlar jadvali",
        orderView: true
      },
      {
        key: "Buyurtmalarda chat",
        chatView: true
      },
      {
        key: "Buyurtmalarni yuklab olish",
        upload: true
      },
      {
        key: "Buyurtmalarni tahrirlash",
        update: true
      },
      {
        key: "Buyurtmani yakunlash",
        completed: true
      },
      {
        key: "Buyurtmani bekor qilish",
        rejected: true
      }
    ]
  },
  {
    key: "Ishlar uchun to'lovlar",
    actions: [
      {
        key: "Statistika",
        statistics: true
      },
      {
        key: "Ishlar uchun to'lovlar jadvali",
        view: true
      },
      {
        key: "Ishlarni yuklab olish",
        upload: true
      }
    ]
  },
  {
    key: "Inventarlarga to'lovlar",
    actions: []
  },
  {
    key: "To'lov kalendari",
    actions: []
  },
  {
    key: "Maxsus imkoniyatlar",
    actions: [
      {
        key: "O'tkazma qilish",
        transfer: true
      },
      {
        key: "Kutilayotgan o'tkazmalar",
        viewPendingTransfers: true
      },
      {
        key: "Konvertatsiya qilish",
        exchange: true
      },
      {
        key: "Partiya yaratish",
        createParty: true
      },
      {
        key: "Hisobotni yuklab olish",
        downloadReport: true
      },
      {
        key: "To'lovlar tarixi",
        paymentHistory: true
      }
    ]
  }
];
