import { Key, useMemo, useState } from "react";
import { Avatar, Button, Dropdown, Input, Popover, Tree, TreeDataNode, TreeProps } from "antd";
import { useTranslation } from "react-i18next";

import PlusIcon from "../../../../../../../../../../../app/assets/icons/PlusIcon";
import { SearchIcon } from "../../../../../../../../../../../app/assets/icons/SearchIcon";
import { projectStatuses } from "../../../../../../../../../../utils/enums/projectStatuses";
import { CompanyPersonModel } from "../../../../../../../../../../utils/models/workModel";
import { useTaskUpdate } from "../../../../services/mutation";
import { useGetCompanyPersons, useGetUsersSelect } from "../../../../services/queries";
import { GanttTaskType } from "../../../../utils/models/GanttTaskType";

import styles from "./ganttTableConfirmationUsers.module.scss";

interface UsersModel {
  id: number;
  image: string;
  full_name: string;
}

type Props = {
  companyPersons: CompanyPersonModel[];
  id: number;
  sectionId: number;
  elementType?: GanttTaskType;
  projectStatus?: projectStatuses;
  users?: UsersModel[];
};

const GanttTableConfirmationUsers = ({ companyPersons, id, sectionId, elementType, projectStatus, users }: Props) => {
  const [searchText, setSearchText] = useState("");
  const [checkedKeys, setCheckedKeys] = useState<{ checked: Key[]; halfChecked: Key[] } | Key[]>([""]);
  const [open, setOpen] = useState(false);
  const { data: usersSelect } = useGetUsersSelect();
  const { data: companyPersonsSelect } = useGetCompanyPersons();
  const taskUpdate = useTaskUpdate(sectionId);
  const { t } = useTranslation();

  const usersFiltered: typeof usersSelect = useMemo(() => {
    if (searchText) {
      return usersSelect?.filter(user => {
        const name = user.full_name.replace(/\s/g, "").toLowerCase();

        return name.includes(searchText);
      });
    }

    return usersSelect;
  }, [searchText, usersSelect]);

  const companyPersonsFiltered: typeof companyPersonsSelect = useMemo(() => {
    if (searchText) {
      return companyPersonsSelect?.filter(user => {
        const name = user?.person?.name.replace(/\s/g, "").toLowerCase();

        return name.includes(searchText);
      });
    }

    return companyPersonsSelect;
  }, [searchText, companyPersonsSelect]);

  const treeData: TreeDataNode[] = [
    {
      title: t("project.Xodim"),
      key: "parent-0",
      children: usersFiltered?.map(item => ({
        title: item.full_name,
        key: JSON.stringify({
          value: item.id,
          key: "user_ids"
        })
      }))
    },
    {
      title: t("project.Kontragent"),
      key: "parent-1",
      children: companyPersonsFiltered?.map(item => ({
        title: item?.person?.name,
        key: JSON.stringify({
          value: item.id,
          key: "company_person_ids"
        })
      }))
    }
  ];

  const usersData = ():
    | {
        id: number;
        image: string;
        name: string;
      }[]
    | undefined => {
    if (companyPersons && companyPersons?.length > 0 && users && users?.length === 0) {
      return companyPersons?.map(item => ({
        id: item?.id,
        name: item?.name,
        image: ""
      }));
    }
    if (users && users?.length > 0 && companyPersons && companyPersons?.length === 0) {
      return users?.map(item => ({
        id: item?.id,
        name: item?.full_name,
        image: item?.image
      }));
    }
    if (companyPersons && companyPersons?.length > 0 && users && users?.length > 0) {
      return [
        ...users.map(item => ({
          id: item?.id,
          name: item?.full_name,
          image: item?.image
        })),
        ...companyPersons.map(item => ({
          id: item?.id,
          name: item?.name,
          image: ""
        }))
      ];
    }
    return undefined;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const onCheck: TreeProps["onCheck"] = checkedKeys => {
    const filteredCheckedKeys = (checkedKeys as string[])?.filter(item => !item.includes("parent"));

    setCheckedKeys(filteredCheckedKeys);
  };

  const onClose = () => {
    setOpen(false);
    setCheckedKeys([]);
  };

  const onUpdate = () => {
    const user_ids: number[] = [];
    const company_person_ids: number[] = [];

    (checkedKeys as string[]).forEach(item => {
      const parsed: { value: number; key: string } = JSON.parse(item);

      if (parsed.key === "user_ids") {
        user_ids.push(parsed.value);
      } else {
        company_person_ids.push(parsed.value);
      }
    });

    taskUpdate.mutateAsync({ id, user_ids, company_person_ids }).then(onClose);
  };

  const onChangeOpen = () => {
    const companyPersonsKeys =
      companyPersons?.map(item => JSON.stringify({ value: item.id, key: "company_person_ids" })) ?? [];
    const usersKeys = users?.map(item => JSON.stringify({ value: item.id, key: "user_ids" })) ?? [];

    setCheckedKeys([...companyPersonsKeys, ...usersKeys]);
    setOpen(prev => !prev);
  };

  const content = (
    <div className={styles.popover__content}>
      <div className={styles.popover__content__top}>
        <Input onChange={onSearch} prefix={<SearchIcon />} placeholder={t("project.Qidiruv")} />
      </div>
      <Tree
        checkable
        selectable={false}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        treeData={treeData}
        className={styles.tree}
      />
      <div className={styles.popover__content__bottom}>
        <Button onClick={onClose}>{t("project.Yopish")}</Button>
        <Button type="primary" onClick={onUpdate} loading={taskUpdate.isLoading}>
          {t("project.Saqlash")}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.confirmation_users}>
      {elementType === "section" || projectStatus !== projectStatuses.PLANNING ? (
        <Dropdown
          trigger={["click", "hover"]}
          {...(!usersData() && { open: false })}
          dropdownRender={() => (
            <div className={styles.dropdownContent}>
              {usersData()?.map(item => (
                <div key={item?.id} className={styles.dropdownContent_item}>
                  <Avatar src={item?.image}>{item?.name[0]}</Avatar>
                  {item?.name}
                </div>
              ))}
            </div>
          )}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.1}
        >
          <div className={`${styles.item} ${styles.item_responsible}`}>
            <Avatar.Group
              max={{
                count: 4,
                style: { color: "#475467", backgroundColor: "#F2F4F7" },
                popover: { content: null }
              }}
            >
              {usersData()
                ? usersData()?.map(item => (
                    <Avatar src={item?.image} key={item.id}>
                      {item?.name[0]}
                    </Avatar>
                  ))
                : ""}
            </Avatar.Group>
          </div>
        </Dropdown>
      ) : (
        <Popover
          content={content}
          trigger="click"
          placement="bottomLeft"
          overlayClassName={styles.popover}
          open={open}
          onOpenChange={onChangeOpen}
          zIndex={9999}
        >
          <Dropdown
            trigger={["hover"]}
            {...(!usersData() && { open: false })}
            placement="topRight"
            dropdownRender={() => (
              <div className={styles.dropdownContent}>
                {usersData()?.map(item => (
                  <div key={item?.id} className={styles.dropdownContent_item}>
                    <Avatar src={item?.image}>{item?.name[0]}</Avatar>
                    {item?.name}
                  </div>
                ))}
              </div>
            )}
          >
            <div className={styles.item}>
              <Avatar.Group
                max={{
                  count: 4,
                  style: { color: "#475467", backgroundColor: "#F2F4F7" },
                  popover: { content: null }
                }}
              >
                {usersData() ? (
                  usersData()?.map(item => (
                    <Avatar src={item?.image} key={item.id}>
                      {item?.name[0]}
                    </Avatar>
                  ))
                ) : (
                  <Button className={styles.add_user} type="dashed" shape="circle">
                    <PlusIcon color="#98a2b3" />
                  </Button>
                )}
              </Avatar.Group>
            </div>
          </Dropdown>
        </Popover>
      )}
    </div>
  );
};

export default GanttTableConfirmationUsers;
