import React from "react";
import GeneralEditing from "features/app/components/general-editing/GeneralEditing";
import { generalEditKeys } from "features/app/utils/constants/generalEditData";
import SettingsAdminsModal from "features/settings/components/admins/admins/modal/SettingsAdminsModal";

import { useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import ResourceSpendingDrawer from "./drawers/resource-spending";
import { Top } from "./top";

const Works: React.FC = () => {
  const { generalEdit } = useAppSelector(state => state.appReducer);
  const { setSelectOrderProducts } = supplyActions;

  const generalUpdateMutations = {
    [generalEditKeys.DONE_WORKS]: {
      isDelete: true,
      dispatchFunc: setSelectOrderProducts
    }
  };

  return (
    <div className="overflow-hidden">
      <Top />
      <GeneralEditing {...generalUpdateMutations[generalEdit.key as keyof typeof generalUpdateMutations]} />
      <ResourceSpendingDrawer />
      <SettingsAdminsModal />
    </div>
  );
};

export default Works;
