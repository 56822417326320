import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Collapse } from "antd";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";
import CustomFields from "modules/party/create/form/right/custom-fields";

import { ConditionalRender } from "components";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, isUpdate }) => {
  const [activeKey, setActiveKey] = useState<string[]>();
  const { visible } = useAppSelector(state => state.partyReducer.create);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const { t } = useTranslation();

  const onChange = (key: string | string[]) => {
    setActiveKey(key as string[]);

    if (!isUpdate) {
      if (isEmptyArr(key as string[])) {
        form.setValue("custom_field_values", []);
      } else {
        form.setValue(
          "custom_field_values",
          customFields?.map(field => ({
            custom_field_id: field?.id,
            value: null
          }))
        );
      }
    }
  };

  useEffect(() => {
    if (!visible) {
      setActiveKey(undefined);
    }
  }, [visible]);

  return (
    <div>
      <Collapse
        ghost
        onChange={onChange}
        activeKey={activeKey}
        rootClassName={styles.collapse}
        expandIcon={({ isActive }) => <ChevronDownIcon rotate={isActive} width={20} />}
        items={[
          {
            key: 1,
            label: t("partyCreate.O'zgaruvchilar"),
            children: (
              <div className="flex flex-col gap-4">
                <ConditionalRender if={activeKey}>
                  {customFields?.map((field, index) => (
                    <CustomFields key={field?.id} form={form} field={field} index={index} isUpdate={isUpdate} />
                  ))}
                </ConditionalRender>
              </div>
            )
          }
        ]}
      />
    </div>
  );
};

export default Index;
