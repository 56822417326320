import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { isEmptyValue } from "modules/common";

import { Select } from "components";

import { useWidget } from "../../hooks";
import { useDynamicSettingsSelect } from "../../queries";
import { StaticWidget, WidgetSettingsForm } from "../../schema";

import styles from "./settings.module.scss";

type Props = Omit<StaticWidget["settings"]["form"][number], "name"> & {
  control: UseFormReturn<WidgetSettingsForm>["control"];
  formInstance: UseFormReturn<WidgetSettingsForm>;
  name: keyof WidgetSettingsForm;
  forDisabled?: { fieldKey: string; requestKey: string };
};

const Content: React.FC<Props> = ({
  name,
  label,
  control,
  queryKey,
  endPoint,
  isNotMultiple,
  formInstance,
  forDisabled
}) => {
  const { t } = useTranslation();
  const { settingsVisible } = useWidget();
  const searchParamValue = useWatch({ control, name: forDisabled?.fieldKey as keyof WidgetSettingsForm });

  const { select, isLoading } = useDynamicSettingsSelect({
    enabled: forDisabled ? !isEmptyValue(searchParamValue) : settingsVisible,
    endPoint,
    queryKey,
    params: { [forDisabled?.requestKey as string]: searchParamValue as string }
  });
  const selectValue = useWatch({
    control,
    name
  });

  const onChange = (value: number) => {
    formInstance.setValue(name, value);

    if (name === "task_custom_field_id") {
      formInstance.setValue("type", "task");
      formInstance.setValue("task_custom_field_id", value);
      formInstance.setValue("section_custom_field_id", null);
      formInstance.setValue("custom_field_id", value);
    }

    if (name === "section_custom_field_id") {
      formInstance.setValue("type", "section");
      formInstance.setValue("section_custom_field_id", value);
      formInstance.setValue("task_custom_field_id", null);
      formInstance.setValue("custom_field_id", value);
    }
  };

  return (
    <Select
      name={name}
      maxTagCount={2}
      control={control}
      key={name + label}
      loading={isLoading}
      onChange={onChange}
      value={selectValue}
      label={t(`global.${label}`)}
      disabled={forDisabled ? isEmptyValue(searchParamValue) : false}
      rootClassName={styles.multiple_select}
      mode={isNotMultiple ? undefined : "multiple"}
      options={select?.map(item => ({
        value: item.id,
        label: typeof item.name === "string" ? item.name : item.name?.[i18n.language],
        props: {
          name: typeof item.name === "string" ? item.name : item.name?.[i18n.language]
        }
      }))}
    />
  );
};

export default Content;
