import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { WidgetSettingsForm } from "modules/dashboard/schema";

import { Select } from "components";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
};

const CompanyPersonType: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const selectOptions = [
    {
      label: t("global.Ishchi"),
      value: "worker"
    },
    {
      label: t("global.Ta'minotchi"),
      value: "supplier"
    }
  ];

  return (
    <Select name="type" options={selectOptions} control={form.control} allowClear label={t("global.Kontragent turi")} />
  );
};

export default CompanyPersonType;
