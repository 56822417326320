import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { CustomFieldProps } from "../../utils/helpers/customFields";

const TextCustomField = ({ placeholder = "Kiriting", disabled, onChange, hasValue, value }: CustomFieldProps) => {
  const { t } = useTranslation();

  return (
    <Input
      placeholder={t(`app.${placeholder}`)}
      disabled={disabled}
      onChange={e => {
        if (onChange) {
          onChange(e.currentTarget.value);
        }
      }}
      {...((hasValue || value) && { value })}
    />
  );
};

export default TextCustomField;
