import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { warehouseDefectTableDefaultData } from "features/warehouse/utils/constants/warehouseDefectTableDefaultData";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "modules/common";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { defectViewActions } from "../../../../store/reducers/defectViewReducer";
import ConfirmationUsersList from "../../../app/components/confirmation-users-list/ConfirmationUsersList";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../app/components/pagination/Pagination";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../app/utils/constants/languages";
import { GetComponentProps } from "../../../app/utils/models/getComponentPropsType";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import { useDefectRejected, useExportWarehouseDefectExcel } from "../../service/mutation";
import { useGetWarehouseDefect } from "../../service/query";
import { defectStatusIcons } from "../../utils/constants/DefectStatusIcons";
import { defectStatusValue } from "../../utils/constants/defectStatusValue";
import { defectTypes } from "../../utils/constants/DefectType";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { WarehouseDefectModel } from "../../utils/models/warehouseDefectModel";
import DefectModal from "../warehouse/defect-modal/DefectModal";

import ActionDropdown from "./action-dropdown/ActionDropdown";
import DefectView from "./defect-view/DefectView";
import FilterDrawerDefects from "./filter-drawer/FilterDrawerDefects";
import MakingDefectDrawer from "./making-defect-drawer/MakingDefectDrawer";

import styles from "./defect.module.scss";

// const useDebounce = (callback: () => void, delay: number) => {
//   const timeoutRef = useRef<NodeJS.Timeout | null>(null);

//   const debouncedCallback = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//     timeoutRef.current = setTimeout(() => {
//       callback();
//     }, delay);
//   };

//   useEffect(
//     () => () => {
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     },
//     []
//   );

//   return debouncedCallback;
// };

const Defect: React.FC = () => {
  const { i18n, t } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const defectRejected = useDefectRejected();
  const { data, isLoading } = useGetWarehouseDefect();
  const { setDefectView } = defectViewActions;
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const defectActions = actions("Yaroqsiz mahsulotlar");
  const [openDrawer, setDrawerOpen] = useState(false);

  const exportExcel = useExportWarehouseDefectExcel();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_DEFECTS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_DEFECTS, warehouseDefectTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_DEFECT]);
  const [renderColumns, setColumns] = useState<ColumnsType<WarehouseDefectModel>>([]);

  const columns: ColumnsType<WarehouseDefectModel> = [
    {
      title: `${t("warehouse.Resurs turi va nomi")}`,
      render: (record: WarehouseDefectModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "product")?.width;

        return (
          <div className="resource">
            <div
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
            >
              {record?.product.resource?.symbol[i18n.language]}
            </div>
            <PopoverShortName
              title={record?.product?.name[i18n.language]}
              length={((columnWidth as number) + 150) / 10}
            />
          </div>
        );
      },
      width: 700,
      className: "product",
      fixed: "left"
    },
    {
      title: `${t("warehouse.Birligi")}`,
      render: (record: WarehouseDefectModel) => record.unit.symbol[i18n.language],
      width: 150,
      className: "unit",
      key: "unit"
    },
    {
      title: `${t("warehouse.Soni")}`,
      dataIndex: "quantity",
      render: (quantity: number) => quantity?.toLocaleString(RU),
      width: 150,
      className: "quantity",
      key: "quantity"
    },
    {
      title: `${t("warehouse.Omborxona")}`,
      render: (record: WarehouseDefectModel) => record.warehouse.name,
      width: 350,
      className: "warehouse",
      key: "warehouse"
    },
    {
      title: t("works.Ish nomi va bo'limi"),
      render: (_, record: WarehouseDefectModel) => (
        <>
          {record?.task ? (
            <div className="flex flex-col">
              <div className="flex gap-1 text-sm font-medium text-gray-900">
                <span>{record?.task?.place}</span>{" "}
                <span>
                  <PopoverShortName title={record?.task?.name} length={100} />
                </span>
              </div>
              <span className="text-xs font-normal text-gray-900">{record?.task?.section?.name}</span>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
      width: 400,
      key: "work_name_and_section",
      className: "work_name_and_section"
    },
    {
      title: `${t("warehouse.Loyiha")}`,
      render: (record: WarehouseDefectModel) => record.project.name,
      width: 250,
      className: "project",
      key: "project"
    },
    {
      title: `${t("warehouse.Yaroqsizlik turi")}`,
      render: (record: WarehouseDefectModel) => (
        <div className={`${styles.type} ${styles[record.type]}`}>{defectTypes[record.type]}</div>
      ),
      width: 250,
      className: "defect_type",
      key: "type"
    },
    {
      title: `${t("warehouse.Status")}`,
      render: (record: WarehouseDefectModel) => (
        <div className={`${styles.status} ${styles[record.status]}`}>
          {defectStatusIcons[record.status]} {defectStatusValue[record.status]}
        </div>
      ),
      width: 150,
      className: "defect_status",
      key: "status"
    },
    {
      title: `${t("warehouse.Guvohlar")}`,
      render: (record: WarehouseDefectModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "victims")?.width;

        return (
          <ConfirmationUsersList
            record={record}
            length={columnWidth ? (columnWidth as number) / record?.confirmation_users?.length : 150 / 10}
          />
        );
      },
      width: 150,
      className: "victims",
      key: "victims"
    },
    {
      title: `${t("warehouse.Sana")}`,
      dataIndex: "defect_date",
      render: date => <span>{dayjs(date, dayjsFormats.DATE).format(dayjsFormats.DATE)}</span>,
      className: "defect_date",
      key: "defect_date",
      width: 200
    }
  ];

  const saveColumns = () => {
    const columnsToSave = renderColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.WAREHOUSE_DEFECTS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WarehouseDefectModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const tableTitle = () => (
    <div className={styles.table_title}>
      {defectActions.makeExpired && (
        <div>
          <Button onClick={() => setDrawerOpen(true)}>
            <PlusIcon /> {t("warehouse.Yaroqsizga chiqarish")}
          </Button>
          <span className={styles.line} />
        </div>
      )}
      <PaymentSearch />
      {/* <Input prefix={<SearchIcon />} placeholder="Qidiruv" /> */}
      <FilterDrawerDefects exportExcelMutate={exportExcel} />
    </div>
  );

  const onRow: GetComponentProps<WarehouseDefectModel> | undefined = record => ({
    onClick: () => {
      defectActions.edit &&
        defectActions.rejected &&
        dispatch(
          setDefectView({
            visible: true,
            data: record
          })
        );
    }
  });

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              configKey={tableConfigKeys.WAREHOUSE_DEFECTS}
              defaultData={warehouseDefectTableDefaultData}
              locations={[CustomFieldLocationEnum.WAREHOUSE_DEFECT]}
              isNeedSize
            />
          ),
          render: (record: WarehouseDefectModel) => (
            <div className={styles.actions}>
              <ActionDropdown record={record} />
            </div>
          ),
          width: 70,
          className: "action-dots",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div className={styles.defect}>
      <div className={styles.top}>
        <Table<WarehouseDefectModel>
          title={tableTitle}
          columns={renderColumns}
          onChangeColumns={onChangeColumns}
          dataSource={data?.data}
          pagination={false}
          onRow={onRow}
          rowKey={row => row.id}
          scroll={{ y: "65vh" }}
          rowClassName={() => styles.on_row}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading || defectRejected.isLoading,
            indicator: LoadingIndicator
          }}
        />
        <Pagination />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
        />
      </div>
      <DefectView />
      <DefectModal />
      <MakingDefectDrawer drawerOpen={openDrawer} setDrawerOpen={setDrawerOpen} />
    </div>
  );
};

export default Defect;
