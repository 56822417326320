import React, { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Spin } from "antd";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import PopoverShortName from "../../../../../app/components/popover-short-name/PopoverShortName";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../app/service/queries";
import { tableConfigKeys } from "../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { CustomFieldSelectModel } from "../../../../../app/utils/models/customFieldSelectModel";
import { useGetDynamicEstimateProduct } from "../../../../service/queries";
import { estimateTableDefaultData } from "../../../../utils/constants/estimateTableDefaultData";
import { DynamicEstimateEnum } from "../../../../utils/enums/dynamicEstimateEnum";
import { estimateColumnsWidth } from "../../../../utils/helpers/estimateColumnsWidth";
import { DynamicEstimateProductModel } from "../../../../utils/models/dynamicEstimateModel";
import DynamicCustomField from "../../estimate-custom-field/DynamicCustomField";
import ProductView from "../product-view/ProductView";
import WithOutEstimate from "../with-out-estimate/WithOutEstimate";

import ExpectedAmount from "./expected/ExpectedAmount";
import ExpectedQuantity from "./expected/ExpectedQuantity";
import ExpectedTotalAmount from "./expected/ExpectedTotalAmount";
import SpendAmount from "./spend/SpendAmount";
import SpendQuantity from "./spend/SpendQuantity";
import SpendTotalAmount from "./spend/SpendTotalAmount";

import styles from "./product.module.scss";

type Props = {
  taskId: number;
  isActive: boolean;
  isFeature: boolean;
  taskStatus?: DynamicEstimateEnum;
  sectionPlan: boolean;
  taskPlan: boolean;
};

const Product: React.FC<Props> = ({ taskId, isActive, isFeature, taskStatus, sectionPlan, taskPlan }) => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<DynamicEstimateProductModel>();
  const { data, isLoading } = useGetDynamicEstimateProduct(isActive, taskId);
  const { smetaContainer } = useOutletContext<OutletContextType>();

  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col?.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  const gridStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth(tableConfigData)} 16.25rem`
  };

  const onOpenProductView = (record: DynamicEstimateProductModel) => () => {
    setOpen(true);
    setProduct(record);
  };

  const unitContent = <p className={styles.unit}>{product?.unit?.symbol?.[i18n.language]}</p>;

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <ConditionalRender if={!isEmptyArr(data)}>
        <div className={styles.products}>
          {data?.map(product => (
            <div className={styles.product} style={gridStyle} key={product?.id} onClick={onOpenProductView(product)}>
              <div className={styles.left}>
                {!product?.plan && sectionPlan && taskPlan && <WithOutEstimate />}
                <div className={`${styles.name} resource`}>
                  <div
                    style={{
                      borderColor: product?.product?.resource?.color,
                      color: product?.product?.resource?.color
                    }}
                  >
                    {product?.product?.resource?.symbol?.[i18n.language]}
                  </div>
                  <p>
                    <PopoverShortName
                      getPopoverContainer={() => smetaContainer}
                      title={product?.product?.name?.[i18n.language]}
                      length={80}
                    />
                  </p>
                </div>
              </div>
              {isFeature ? (
                <>
                  <ExpectedQuantity
                    getPopoverContainer={() => smetaContainer}
                    product={product}
                    taskStatus={taskStatus}
                  />
                  {unitContent}
                  <ExpectedAmount
                    getPopoverContainer={() => smetaContainer}
                    product={product}
                    taskStatus={taskStatus}
                  />
                  {/* {EstimateCustomField({ */}
                  {/*  isDynamicEstimate: true, */}
                  {/*  values: product?.custom_field_values, */}
                  {/*  locations: CustomFieldLocationEnum.TASK_PRODUCT, */}
                  {/*  customFields: customFieldsColumnRender, */}
                  {/*  className: cx(styles.form__item) */}
                  {/* })} */}
                  <DynamicCustomField
                    values={product?.custom_field_values}
                    customFields={customFieldsColumnRender}
                    locations={CustomFieldLocationEnum.TASK_PRODUCT}
                  />
                  <div className={styles.right}>
                    <ExpectedTotalAmount product={product} getPopoverContainer={() => smetaContainer} />
                  </div>
                </>
              ) : (
                <>
                  <SpendQuantity product={product} taskStatus={taskStatus} getPopoverContainer={() => smetaContainer} />
                  {unitContent}
                  <SpendAmount product={product} taskStatus={taskStatus} getPopoverContainer={() => smetaContainer} />
                  <DynamicCustomField
                    values={product?.custom_field_values}
                    customFields={customFieldsColumnRender}
                    locations={CustomFieldLocationEnum.TASK_PRODUCT}
                  />
                  {/* {EstimateCustomField({ */}
                  {/*  isDynamicEstimate: true, */}
                  {/*  values: product?.custom_field_values, */}
                  {/*  locations: CustomFieldLocationEnum.TASK_PRODUCT, */}
                  {/*  customFields: customFieldsColumnRender, */}
                  {/*  className: cx(styles.form__item) */}
                  {/* })} */}
                  <div className={styles.right}>
                    <SpendTotalAmount product={product} getPopoverContainer={() => smetaContainer} />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <ProductView open={open} setOpen={setOpen} product={product} smetaContainer={() => smetaContainer} />
      </ConditionalRender>
      <ConditionalRender if={isEmptyArr(data)}>
        <div className={styles.empty_product}>
          <TableEmpty description={t("project.Mahsulotlar mavjud emas")} />
        </div>
      </ConditionalRender>
    </Spin>
  );
};

export default Product;
