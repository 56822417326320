import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ILicensingExpiringInitialState {
  licenceExpiring: number;
}

const date = localStorage.getItem("licenceExpiring");

const initialState: ILicensingExpiringInitialState = {
  licenceExpiring: date ? parseInt(date, 10) : 0
};

const licenceExpiringSlice = createSlice({
  name: "licenceExpiring",
  initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<number>) => {
      localStorage.setItem("licenceExpiring", action.payload.toString());
      state.licenceExpiring = action.payload;
    }
  }
});

export default licenceExpiringSlice.reducer;
export const licenceExpiringActions = licenceExpiringSlice.actions;
