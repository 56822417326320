import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import { OfferFormProductModel } from "../../../../utils/models/OfferFormModel";

import OfferListItem from "./OfferListItem";

import styles from "./offerList.module.scss";

type Props = {
  data: OfferFormProductModel[];
  productKey: string;
};

const OfferList: React.FC<Props> = ({ data, productKey }) => {
  const dispatch = useDispatch();
  const baseCurrency = getBaseCurrency();
  const { warehouseOffers } = useAppSelector(state => state.supplyOfferReducer.offerModal);
  const { setAddOfferItem } = supplyOfferActions;
  const { t } = useTranslation();

  const onAddOffer = () => {
    const ordinalNumber = warehouseOffers?.find(item => item.productKey === productKey);

    dispatch(
      setAddOfferItem({
        productKey,
        data: {
          name: "",
          amount: "",
          currency_id: baseCurrency?.id,
          company_person_id: undefined,
          description: undefined,
          phone: undefined,
          uniqueId: uid(12),
          ordinalNumber: (ordinalNumber?.offers?.length ?? 0) + 1
        }
      })
    );
  };

  const getStyle = (style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) => {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.0000001s`
    };
  };

  return (
    <div className={styles.list}>
      <div className={styles.children}>
        <Button className={styles.add_offer} onClick={onAddOffer}>
          <PlusIcon />
          {t("payment.Taklif qo'shish")}
        </Button>
        {data?.map((field, index) => (
          <Draggable index={index} key={field?.uniqueId} draggableId={field?.uniqueId}>
            {(provided, snapshot) => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getStyle(provided.draggableProps.style, snapshot)}
                >
                  <OfferListItem {...field} index={index} key={field?.uniqueId} productKey={productKey} />
                </div>
                <ConditionalRender if={snapshot.isDragging}>
                  <OfferListItem {...field} index={index} key={field?.uniqueId} productKey={productKey} />
                </ConditionalRender>
              </>
            )}
          </Draggable>
        ))}
      </div>
    </div>
  );
};

export default OfferList;
