import React from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import Loading from "features/app/components/loading/Loading";

import { login, useDispatch } from "store";

import { http } from "../../../services";
import { useAuth, useLogout } from "../hooks";
import { AUTH } from "../keys";
import { Currency, Permission, User, userSchema } from "../schema";

interface AuthProps {
  children: React.ReactNode;
}

const Auth: React.FC<AuthProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { accessToken, isFetched } = useAuth();
  const { logout } = useLogout();

  useQuery<{ user: User; permissions: Permission[]; currencies: Currency[] }, AxiosError>({
    queryKey: AUTH.profile({ accessToken }).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get("auth/me");

      const user = userSchema.parse(data.data);

      const { data: permissionData } = await http.request.get("auth/permission");
      const { data: currencyData } = await http.request.get("company-currency/basic");

      return { user, permissions: permissionData?.data, currencies: currencyData?.data };
    },
    onSuccess: async values => dispatch(login(values)),
    onError: async () => {
      await logout();
    },
    enabled: !!accessToken,
    retry: false
  });

  if (!isFetched) return <Loading />;

  return children;
};

export default Auth;
