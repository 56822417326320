export function parseTreeValue<T extends Record<string, number[]>>(value: string[], result: T): T {
  const parsed: { key: string; value: number }[] = value.map(item => JSON.parse(item));

  parsed.forEach(item => {
    const key = item.key as keyof T;

    // Ensure the key exists in result and is an array
    if (!Array.isArray(result[key])) {
      result[key] = [] as any;
    }

    // Push only if the value is not already in the array
    if (!result[key].includes(item.value)) {
      result[key].push(item.value);
    }
  });

  return result;
}
