import React from "react";
import { UseFormReturn } from "react-hook-form";
import { TreeSelect } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { PartyForm, useProjects } from "modules/party/create";

import styles from "./index.module.scss";

type Props = { form: UseFormReturn<PartyForm> } & (
  | {
      type: "initial";
    }
  | {
      type: "group";
      parentIndex: number;
    }
);

const Index: React.FC<Props> = props => {
  const { type, form } = props;
  const { isLoading, projects } = useProjects();
  const { t } = useTranslation();

  const onChange = (e: number) => {
    if (type === "initial") {
      const oldValue = form.getValues("warehouse_products");

      form.setValue(
        "warehouse_products",
        oldValue?.map(item => ({ ...item, project_id: e }))
      );
    }

    if (props.type === "group") {
      const oldValue = form.getValues(`company_person_group.${props.parentIndex}`);

      form.setValue(`company_person_group.${props.parentIndex}`, {
        ...oldValue,
        products: [
          ...(oldValue.products?.map(product => ({
            ...product,
            project_id: e
          })) || [])
        ]
      });
    }
  };

  return (
    <TreeSelect
      loading={isLoading}
      onChange={onChange}
      placeholder={t("payment.Loyiha")}
      popupMatchSelectWidth={false}
      rootClassName={styles.project}
      suffixIcon={<ChevronDownIcon rotate width={18} />}
      treeData={projects?.map(project => ({
        key: project?.id,
        value: project?.id,
        label: (
          <>
            <span>{project?.name}</span>
            <span>{t("payment.Loyiha")}</span>
          </>
        ),
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          key: child?.id,
          value: child?.id,
          label: (
            <>
              <span>{child?.name}</span>
              <span>Loyiha</span>
            </>
          ),
          props: {
            name: child?.name
          }
        }))
      }))}
    />
  );
};

export default Index;
