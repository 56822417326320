import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, MenuProps, Popover } from "antd";
import { ProductModel } from "features/settings/utils/models/product/productModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { chatActions } from "../../../../../../store/reducers/chatReducer";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { cx } from "../../../../../app/utils/helpers/cx";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import MessageIcon from "../../../../../inventory/assets/icons/MessageIcon";
import ClockFastForwardIcon from "../../../../assets/icons/ClockFastForwardIcon";
import { WarehouseProductOffers } from "../../../../utils/models/OfferFormModel";
import OfferList from "../offer-list/OfferList";

import styles from "./offerProduct.module.scss";

const OfferProduct: React.FC<WarehouseProductOffers & { zIndex?: number }> = ({
  id,
  date,
  count,
  offers,
  unitId,
  zIndex,
  productKey,
  warehouse_product: product,
  warehouse_resource: resource
}) => {
  const dispatch = useDispatch();
  const { setVisible } = chatActions;
  const { setOfferHistory } = supplyOfferActions;
  const { isCatalog, data } = useAppSelector(state => state.supplyOfferReducer.offerModal);
  const { i18n, t } = useTranslation();

  const onOpenChat = () => {
    dispatch(
      setVisible({
        objectId: id,
        visible: true,
        type: "warehouse_products",
        zIndex: zIndex ? zIndex + 1 : undefined
      })
    );
  };

  const onOpenOfferHistory = () => {
    dispatch(
      setOfferHistory({
        unitId,
        productKey,
        visible: true,
        productId: product?.id
      })
    );
  };

  const dropdownItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          <MessageIcon />
          <span>{t("payment.Chat")}</span>
        </div>
      ),
      onClick: onOpenChat
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-2">
          <ClockFastForwardIcon />
          <span>{t("payment.Takliflar tarixi")}</span>
        </div>
      ),
      onClick: onOpenOfferHistory
    }
  ];

  return (
    <div className={styles.product}>
      <div className={styles.product__top} style={{ height: !isCatalog ? "100px" : "" }}>
        <div className={styles.name}>
          <div className={cx("resource")}>
            <div
              style={{
                borderColor: isCatalog ? (data![0] as unknown as ProductModel)?.resource?.color : resource?.color,
                color: isCatalog ? (data![0] as unknown as ProductModel)?.resource?.color : resource?.color
              }}
            >
              {isCatalog ? (data![0] as unknown as ProductModel)?.resource?.name[i18n.language] : resource?.name}
            </div>
            {!isCatalog && product?.name?.length > 22 ? (
              <Popover title={product?.name}>{sliceText(product?.name, 22)}</Popover>
            ) : (
              product?.name
            )}
            {isCatalog && (data![0] as unknown as ProductModel)?.name[i18n.language]?.length > 22 ? (
              <Popover title={(data![0] as unknown as ProductModel)?.name[i18n.language]} zIndex={9999999}>
                {sliceText((data![0] as unknown as ProductModel)?.name[i18n.language], 22)}
              </Popover>
            ) : (
              <>{isCatalog && (data![0] as unknown as ProductModel)?.name[i18n.language]}</>
            )}
          </div>
          <ConditionalRender if={!isCatalog}>
            <Dropdown menu={{ items: dropdownItems }} trigger={["click"]}>
              <span>
                <DotsVerticalIcon />
              </span>
            </Dropdown>
          </ConditionalRender>
        </div>
        <ConditionalRender if={!isCatalog}>
          <div className={styles.info}>
            <p>{date}</p>
            <p>
              {count} <span>{t("payment.ta")}</span>
            </p>
          </div>
        </ConditionalRender>
      </div>
      <div className={styles.product__bottom}>
        <OfferList data={offers} productKey={productKey} />
      </div>
    </div>
  );
};

export default OfferProduct;
