import React from "react";
import { Form, FormInstance } from "antd";

import { ResourceSpendingDrawerEnums } from "modules/works";

import Bottom from "./bottom";
import Top from "./top";

const { Item } = Form;

type Props = {
  form: FormInstance<any>;
  currentTab: ResourceSpendingDrawerEnums;
  setCurrentTab: React.Dispatch<React.SetStateAction<ResourceSpendingDrawerEnums>>;
};

const Left: React.FC<Props> = ({ form, currentTab, setCurrentTab }) => (
  <div className="flex h-auto w-4/5 flex-col gap-4 overflow-y-auto p-5">
    <Top form={form} />
    <Bottom currentTab={currentTab} form={form} setCurrentTab={setCurrentTab} />
    <Item name="product_details" className="hidden" initialValue={[]} />
    <Item name="person_details" className="hidden" initialValue={[]} />
    <Item name="mechanism_details" className="hidden" initialValue={[]} />
    <Item name="unit_name" className="hidden" />
  </div>
);

export default Left;
