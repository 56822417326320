import React from "react";
import { useTranslation } from "react-i18next";

import EmptyPlan from "../../../../app/assets/images/emptyPlan.png";

import styles from "./dynamicEstimate.module.scss";

const EmptyDynamicEstimate: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.empty_data}>
      <img src={EmptyPlan} alt="EmptyPlan" />
      <h4>{t("project.Loyihani rejalashtirishni boshlang")}</h4>
      <p>{t("project.Loyihaga ishlar kiriting, resurslar biriktiring, ish grafigini tuzib chiqing")}</p>
    </div>
  );  
};

export default EmptyDynamicEstimate;
