import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { ProductModel, SingleProductIncomeExpenseHistoryViewModel } from "features/app/utils/models/notificationModel";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";
import WorkModalTabs from "features/projects/components/detailed-project/work/modal/tabs/WorkModalTabs";
import DefectView from "features/warehouse/components/defect/defect-view/DefectView";
import TitleActions from "features/warehouse/components/income-expense-history/table/title-actions";
import LaboratoryDrawer from "features/warehouse/components/labaratory/laboratory-drawer/LaboratoryDrawer";
import TransferView from "features/warehouse/components/transfers/transfer-view/TransferView";
import {
  getQuantityStyle,
  getSourceContent
} from "features/warehouse/components/warehouse/income-expense-history-drawer/bottom/bottom";
import IncomeExpenseHistoryDrawer from "features/warehouse/components/warehouse/income-expense-history-drawer/income-expense-history-drawer";
import { useGetWarehouseProductHistory } from "features/warehouse/service/query";
import { warehouseIncomeExpenseHistoryGroupByTableDefaultData } from "features/warehouse/utils/constants/warehouse-income-expense-history-group-by-table-default-data";
import { warehouseIncomeExpenseHistoryTableDefaultData } from "features/warehouse/utils/constants/warehouse-income-expense-history-table-default-data";
import { GroupByIncomeExpenseHistoryModel } from "features/warehouse/utils/models/group-by-incom-expense-history-model";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { laboratoryDrawerActions } from "store";
import { partyReducerActions, warehouseProductsAction } from "store/actions";
import { defectViewActions } from "store/reducers/defectViewReducer";
import { transferActions } from "store/reducers/transferReducer";

import { localeFormatter, RU, TooltipShortName } from "modules/common";

import { PartyView } from "pages/index";

import { LoadingIndicator, Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import IncomeExpenseHistoryFilterDrawer from "../filter-drawer";

import Actions from "./actions";

import styles from "./index.module.scss";

function IncomeExpenseHistoryTable() {
  const { t, i18n } = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const { setResourceHistory } = warehouseProductsAction;
  const [open, setOpen] = useState(false);
  const [taskProgressId, setTaskProgressId] = useState<number | null>(null);

  const { setPartyView } = partyReducerActions;
  const { setOpenDrawer } = laboratoryDrawerActions;
  const { setVisibleView } = transferActions;
  const { setDefectView } = defectViewActions;

  const { queries, searchParams } = useQueryParams();
  const { group_by, size } = queries();

  const { data, isFetching } = useGetWarehouseProductHistory(group_by);

  const currency = getBaseCurrency();

  // eslint-disable-next-line max-len
  const { data: tableConfigData } = useGetTableConfigs(
    !group_by
      ? tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY
      : tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY,
    !group_by ? warehouseIncomeExpenseHistoryTableDefaultData : warehouseIncomeExpenseHistoryGroupByTableDefaultData
  );

  const changeTableConfig = useChangeTableConfig(
    !group_by
      ? tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY
      : tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY,
    true
  );

  const [renderColumns, setColumns] = useState<
    ColumnsType<
      SingleProductIncomeExpenseHistoryViewModel & {
        product: ProductModel;
        unit: UnitModel;
      }
    >
  >([]);

  const onShowDetail = (
    e: React.MouseEvent<HTMLElement>,
    record: SingleProductIncomeExpenseHistoryViewModel & {
      product: ProductModel;
      unit: UnitModel;
    }
  ) => {
    e.stopPropagation();
    if (record?.order_id) {
      dispatch(
        setPartyView({
          visible: true,
          party_id: record?.order_id,
          status: "rejected",
          type: "supply"
        })
      );
    }
    if (record?.laboratory_id) {
      dispatch(
        setOpenDrawer({
          visible: true,
          id: record?.laboratory_id,
          isViewing: true
        })
      );
    }
    if (record?.warehouse_transfer_id) {
      dispatch(
        setVisibleView({
          visible: true,
          data: {
            ...record,
            id: record?.warehouse_transfer_id
          } as never
        })
      );
    }
    if (record?.task_id && record?.task_progress_id) {
      setOpen(true);
      setTaskProgressId(record?.task_progress_id);
    }
    if (record?.warehouse_defect_id) {
      dispatch(
        setDefectView({
          data: {
            ...record,
            id: record?.warehouse_defect_id
          } as never,
          visible: true
        })
      );
    }
  };

  const columns: ColumnsType<
    SingleProductIncomeExpenseHistoryViewModel & {
      product: ProductModel;
      unit: UnitModel;
    }
  > = [
    {
      title: `${t("warehouse.Resurs nomi va turi")}`,
      render: (
        record: SingleProductIncomeExpenseHistoryViewModel & {
          product: ProductModel;
          unit: UnitModel;
        }
      ) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <PopoverShortName title={record?.product?.name[i18n.language]} length={150} />
        </div>
      ),
      fixed: "left",
      width: 1000,
      className: "income_expense_history_resource"
    },
    {
      title: t("warehouse.Miqdori"),
      render: (_value: any, record) => (
        <div className={getQuantityStyle(record)?.className}>
          {getQuantityStyle(record)?.symbol} {record?.total_initial_quantity?.toLocaleString(RU)}{" "}
          {record?.unit?.symbol[i18n.language]}
        </div>
      ),
      width: 250
    },
    {
      title: t("warehouse.Sana"),
      dataIndex: "created_date",
      width: 150,
      key: "created_date",
      className: "income_expense_history_created_date"
    },
    {
      title: t("warehouse.Manba"),
      render: (_value: any, record) => (
        <div className="cursor-pointer text-blue-500" onClick={e => onShowDetail(e, record)}>
          {getSourceContent(record)}
        </div>
      ),
      width: 120,
      className: "income_expense_history_source",
      key: "source"
    },
    {
      title: t("warehouse.Omborxona"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.warehouse?.name} length={15} />
        </div>
      ),
      width: 250,
      key: "warehouse",
      className: "income_expense_history_warehouse"
    },
    {
      title: t("warehouse.Loyiha"),
      render: (_value: any, record) => (
        <div>
          <TooltipShortName title={record?.project?.name} length={15} />
        </div>
      ),
      width: 250,
      key: "project",
      className: "income_expense_history_project"
    },
    {
      title: t("warehouse.Birlik narx"),
      dataIndex: "amount",
      width: 200,
      key: "income_expense_amount",
      className: "income_expense_amount"
    },
    {
      title: t("warehouse.Umumiy narx"),
      render: (_value: any, record) => (
        <span>
          {record?.amount !== null && record?.total_initial_quantity !== null
            ? localeFormatter(String(record?.amount * record?.total_initial_quantity))
            : "-"}
        </span>
      ),
      width: 200,
      key: "income_expense_total_amount",
      className: "income_expense_total_amount"
    },
    {
      title: t("warehouse.Kontragent"),
      render: (_value: any, record) => <span>{record?.company_person ? record?.company_person?.name : "-"}</span>,
      width: 200,
      key: "income_expense_company_person",
      className: "income_expense_company_person"
    },
    {
      title: t("warehouse.To'langan summa"),
      render: (_value: any, record) => <span>{record?.paid_amount ? record?.paid_amount : "-"}</span>,
      width: 200,
      key: "income_expense_paid_amount",
      className: "income_expense_paid_amount"
    }
  ];

  const groupByColumns: ColumnsType<GroupByIncomeExpenseHistoryModel> = [
    {
      title: `${t("warehouse.Resurs nomi va turi")}`,
      render: (
        record: SingleProductIncomeExpenseHistoryViewModel & {
          product: ProductModel;
          unit: UnitModel;
        }
      ) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <PopoverShortName title={record?.product?.name[i18n.language]} length={150} />
        </div>
      ),
      width: 1000,
      fixed: "left",
      className: "income_expense_history_group_by_resource"
    },
    {
      title: t("warehouse.Birligi"),
      render: (record: GroupByIncomeExpenseHistoryModel) => (
        <span>{record?.unit ? record?.unit?.symbol?.[i18n.language] : "-"}</span>
      ),
      width: 120,
      key: "unit",
      className: "income_expense_history_unit"
    },
    {
      title: (
        <div className="flex flex-col items-center">
          <h4 className="m-0 text-[0.75rem] font-medium text-gray-800">{t("warehouse.Soni")}</h4>
          <span>
            ( {t("warehouse.Kirim")} | {t("warehouse.Chiqim")} )
          </span>
        </div>
      ),
      render: (record: GroupByIncomeExpenseHistoryModel) => (
        <div className="flex items-center justify-center gap-4">
          <span className="w-[7.5rem]">{record?.income?.quantity?.toLocaleString(RU)}</span>
          <span className="h-5 w-0.5 border-b-0 border-l-0 border-r border-t-0 border-solid border-r-gray-200" />
          <span className="w-[7.5rem]">{record?.expense?.quantity?.toLocaleString(RU)}</span>
        </div>
      ),
      width: 300,
      key: "quantity",
      className: "income_expense_history_quantity"
    },
    {
      title: (
        <div className="flex flex-col items-center">
          <h4 className="m-0 text-[0.75rem] font-medium text-gray-800">{t("warehouse.Summa")}</h4>
          <span>
            ( {t("warehouse.Kirim")} | {t("warehouse.Chiqim")} )
          </span>
        </div>
      ),
      render: (record: GroupByIncomeExpenseHistoryModel) => (
        <div className="flex items-center justify-center gap-4">
          <span className="w-40">
            {record?.income?.amount?.toLocaleString(RU)} {currency?.symbol}
          </span>
          <span className="h-5 w-0.5 border-b-0 border-l-0 border-r border-t-0 border-solid border-r-gray-200" />
          <span className="w-40">
            {record?.expense?.amount?.toLocaleString(RU)} {currency?.symbol}
          </span>
        </div>
      ),
      width: 500,
      key: "amount",
      className: "income_expense_history_group_by_amount"
    }
  ];

  const table_title = () => (
    <div className="flex items-center justify-end gap-3">
      <PaymentSearch />
      <IncomeExpenseHistoryFilterDrawer />
      <TitleActions />
    </div>
  );

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: !group_by
        ? tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY
        : tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (
    newColumns: ColumnsType<
      SingleProductIncomeExpenseHistoryViewModel & {
        product: ProductModel;
        unit: UnitModel;
      }
    >
  ) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const onRow = (record: SingleProductIncomeExpenseHistoryViewModel & { product: ProductModel }) => ({
    onClick: () => {
      dispatch(setResourceHistory({ visible: true, product: record as never }));
    }
  });

  useEffect(() => {
    if (tableConfigData) {
      setColumns([
        ...filterColumns({
          columns: !group_by ? (columns as never) : (groupByColumns as never),
          tableConfig: tableConfigData
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              configKey={
                !group_by
                  ? tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY
                  : tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY
              }
              defaultData={
                !group_by
                  ? warehouseIncomeExpenseHistoryTableDefaultData
                  : warehouseIncomeExpenseHistoryGroupByTableDefaultData
              }
              locations={[
                !group_by
                  ? CustomFieldLocationEnum.WAREHOUSE_INCOME_EXPENSE_HISTORY
                  : CustomFieldLocationEnum.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY
              ]}
            />
          ),
          render: (__, record) => (
            <Actions record={record as never} setOpen={setOpen} setTaskProgressId={setTaskProgressId} />
          ),
          width: 70,
          className: !group_by ? "income_expense_history_actions" : "income_expense_history_actions_group_by",
          fixed: "right"
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfigData, group_by]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const itemRender = (
    _: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    element: React.ReactNode
  ) => {
    if (type === "prev") {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>
          <ArrowNarrowIcon /> {t("Xodimlar.Oldingi")}
        </a>
      );
    }
    if (type === "next") {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>
          {t("Xodimlar.Keyingi")}
          <ArrowNarrowIcon rotate={180} />
        </a>
      );
    }
    return element;
  };

  useEffect(() => {
    if (searchParams && searchParams?.length > 0) {
      setPage(1);
    }
  }, [searchParams]);

  return (
    <div className={styles.income_expense_history}>
      <div className={styles.top}>
        <Table<
          SingleProductIncomeExpenseHistoryViewModel & {
            product: ProductModel;
            unit: UnitModel;
          }
        >
          columns={renderColumns}
          key={!group_by ? "income_expense_history_table" : "income_expense_history_group_by_tables"}
          // rowKey={row => row.id}
          rowClassName="cursor-pointer"
          dataSource={data}
          title={table_title}
          pagination={
            data && data?.length > 10
              ? {
                  onChange: handlePageChange,
                  current: page,
                  pageSize: Number(size) || 10,
                  total: data?.length,
                  showSizeChanger: false,
                  position: ["bottomLeft"],
                  itemRender
                }
              : undefined
          }
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isFetching,
            indicator: LoadingIndicator
          }}
          scroll={{ y: "calc(100vh - 315px)" }}
          onChangeColumns={onChangeColumns}
          onRow={onRow}
        />
      </div>
      <PartyView />
      <LaboratoryDrawer />
      <TransferView />
      <DefectView />
      <IncomeExpenseHistoryDrawer />
      <WorkModalTabs open={open} setOpen={setOpen} progressId={taskProgressId!} />
    </div>
  );
}

export default IncomeExpenseHistoryTable;
