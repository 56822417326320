import React, { useEffect } from "react";
import { Form, FormInstance, Input, Select } from "antd";
import ProjectSelect from "features/app/components/project-select/project-select";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import WorkSelect from "features/app/components/work-select";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useGetWork } from "features/projects/service/queries";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { cx, formRules, localeFormatter, sliceText } from "modules/common";

import { SelectSuffixIcon } from "components";

import Percent from "./percent";

import styles from "./index.module.scss";

const { Item, useWatch } = Form;

type Props = {
  form: FormInstance<any>;
};

const Top: React.FC<Props> = ({ form }) => {
  const { t, i18n } = useTranslation();
  const projectId = useWatch("project_id", form);
  const { queries, severalSearchParams } = useQueryParams();
  const { statistics_type, search, page } = queries();
  const filters = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.SEARCH, queryParamsKeys.PAGE);

  const record = useAppSelector(state => state.resourceSpendingReducer.drawer?.record);
  const unitName = useAppSelector(state => state.resourceSpendingReducer?.drawer?.unitName);
  const open = useAppSelector(state => state.resourceSpendingReducer?.drawer?.visible);
  const unit_name = useWatch("unit_name", form);
  const taskId = useWatch("task_id", form);
  const sectionCustomFieldOptionIds = useWatch("custom_field_options", form);

  const handleQuantityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("quantity", localeFormatter(event?.target?.value));
  };

  const { data: work } = useGetWork(!!(!record && taskId && open), taskId);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.SECTION], true);

  useEffect(() => {
    if (projectId) {
      form.setFieldsValue({ task_id: undefined });
    }
    if (sectionCustomFieldOptionIds && sectionCustomFieldOptionIds?.length > 0) {
      form.setFieldsValue({ task_id: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, sectionCustomFieldOptionIds]);

  const customFieldOptions = customFields?.flatMap(item =>
    item?.custom_field_options?.map(el => ({
      value: el?.id,
      label: el?.name?.[i18n?.language],
      props: {
        name: el?.name?.[i18n?.language]
      }
    }))
  );

  return (
    <div
      className={cx(
        "box-border grid grid-cols-3 items-center gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-3"
      )}
    >
      {!record && (
        <div className="flex items-center gap-3">
          <ProjectSelect
            allowClear
            mode="form"
            form={form}
            name="project_id"
            rootClassName="flex-1"
            label={t("works.Loyiha")}
          />
          <Item name="custom_field_options" label={t("works.O'zgaruvchilar")} className="m-0 flex-1">
            <Select
              allowClear
              showSearch
              maxTagCount={2}
              placeholder={t("payment.Tanlang")}
              mode="tags"
              options={customFieldOptions}
              filterOption={selectFilterOption}
              notFoundContent={<SelectNotContent />}
              className={styles.custom_field_select}
              suffixIcon={<SelectSuffixIcon />}
            />
          </Item>
        </div>
      )}

      <div className="flex-1">
        <WorkSelect
          form={form}
          name="task_id"
          disabled={!!record}
          project_id={projectId}
          custom_field_options={sectionCustomFieldOptionIds}
          record={record as never}
          formItemClassName="w-full"
          filters={statistics_type === "task" && filters ? filters : undefined}
          cominSearch={statistics_type === "task" && search ? search : undefined}
          page={statistics_type === "task" && page ? (page as never) : undefined}
        />
      </div>
      <Item name="quantity" rules={formRules()} label={t("works.Progress")} className={cx("flex-1")}>
        <Input
          placeholder={t("works.Kiriting")}
          onChange={handleQuantityInputChange}
          suffix={sliceText(unitName || unit_name, 10)}
        />
      </Item>
      <Percent record={record || (work?.task as never)} />
    </div>
  );
};

export default Top;
