import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import ConfirmationUsersList from "features/app/components/confirmation-users-list/ConfirmationUsersList";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { generalEditKeys } from "features/app/utils/constants/generalEditData";
import { ImpostStatusIcons } from "features/app/utils/constants/importStatusIcons";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import WorkModalTabs from "features/projects/components/detailed-project/work/modal/tabs/WorkModalTabs";
import { CompletedWorkModel } from "features/projects/utils/models/completedWorkModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { appReducerActions } from "store/reducers/appReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { colors, isEmptyArr, RU } from "modules/common";
import { doneWorksTableConfig } from "modules/works";
import { useTaskProgressView } from "modules/works/queries/use-task-progress-view";

import { ConditionalRender, LoadingIndicator, Table } from "components";

import useDebounce from "../../../../../components/use-debounce/use-debounce";

import { Actions } from "./actions";

import styles from "./progresses-table.module.scss";

const ProgressesTable: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t, i18n } = useTranslation();

  const [progressId, setProgressId] = React.useState(0);
  const [openProgress, setOpenProgress] = React.useState(false);
  const [foundTaskProgress, setFoundTaskProgress] = React.useState<CompletedWorkModel | null>(null);
  const [tableColumns, setColumns] = useState<ColumnsType<CompletedWorkModel>>([]);

  const dispatch = useDispatch();
  const { setSelectOrderProducts: setSelectProducts } = supplyActions;
  const { setGeneralEdit } = appReducerActions;
  const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

  const { taskProgress, isLoading } = useTaskProgressView({ enabled: true });
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.DONE_WORKS, doneWorksTableConfig);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.DONE_WORKS, true, false);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.DONE_WORKS]);

  const columns: ColumnsType<CompletedWorkModel> = [
    {
      title: t("works.Ish nomi va bo'limi"),
      render: (_, record) => {
        const customFields = [
          ...(record?.task?.section?.custom_field_values?.filter(field => field?.custom_field?.type === "select") || [])
        ];
        const endCustomFields = customFields?.slice(2);

        return (
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <span className="text-sm font-medium text-gray-900">{record?.place}</span>
                <span className="text-sm font-medium text-gray-900">
                  <PopoverShortName title={record?.task?.name} length={40} />
                </span>
              </div>
              <span className="text-xs font-normal text-gray-900">{record?.task?.section?.name}</span>
            </div>

            <div className="flex items-center gap-1">
              {customFields?.slice(0, 2)?.map(field => (
                <div
                  key={field?.id}
                  className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                  style={{ background: `${field?.custom_field_option?.color}20` }}
                >
                  <div
                    className="h-2.5 w-2.5 rounded-full"
                    style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                  />
                  <span
                    className="text-xs font-medium"
                    style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                  >
                    {field?.custom_field_option?.name?.[i18n.language]}
                  </span>
                </div>
              ))}
              <ConditionalRender if={!isEmptyArr(endCustomFields)}>
                <Popover
                  zIndex={11119}
                  placement="top"
                  arrow={false}
                  content={
                    <div className="flex max-w-80 flex-wrap gap-2">
                      {endCustomFields?.map(field => (
                        <div
                          key={field?.id}
                          className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                          style={{ background: `${field?.custom_field_option?.color}20` }}
                        >
                          <div
                            className="h-2.5 w-2.5 rounded-full"
                            style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                          />
                          <span
                            className="text-xs font-medium"
                            style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                          >
                            {field?.custom_field_option?.name?.[i18n.language]}
                          </span>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className="flex items-center gap-2 rounded-xl bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                    + {endCustomFields?.length}
                  </div>
                </Popover>
              </ConditionalRender>
            </div>
          </div>
        );
      },
      width: 550,
      className: "work_name",
      key: "work_name",
      fixed: "left"
    },
    {
      title: t("works.Loyiha"),
      render: (_, record) => (
        <div className="text-ellipsis whitespace-nowrap text-sm font-normal text-gray-800">
          {record?.task?.section?.project?.name}
        </div>
      ),
      width: 150,
      key: "work_section_project_name",
      className: "work_section_project_name"
    },
    {
      title: t("works.Sana"),
      dataIndex: "date",
      width: 150,
      key: "date",
      className: "date"
    },
    {
      title: t("works.Bajarildi"),
      render: (_, record) => (
        <div className="flex items-center gap-1">
          <span className="text-sm font-normal text-gray-700">{record?.quantity?.toLocaleString(RU)}</span>
          <span className="text-sm font-normal text-gray-400">{record?.task?.unit?.symbol[i18n.language]}</span>
        </div>
      ),
      width: 150,
      key: "quantity",
      className: "quantity"
    },
    {
      title: t("works.Tasdiqlanish"),
      render: (__, record) => (
        <div className="flex items-center justify-center">{ImpostStatusIcons[record?.confirmation_payment]}</div>
      ),
      align: "center",
      width: 120,
      key: "confirmation_payment",
      className: "confirmation_payment"
    },
    {
      title: t("works.Guvohlar"),
      render: (_, record) => <ConfirmationUsersList record={record as never} length={20} />,
      width: 200,
      key: "confirmation_users",
      className: "confirmation_users"
    },
    {
      title: t("works.Bajardi"),
      render: (_, record) => <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />,
      width: 150,
      key: "creator",
      className: "creator"
    }
  ];

  const footer = () => (
    <Pagination
      paginationProps={{
        current: taskProgress?.current_page,
        total: taskProgress?.total
      }}
    />
  );

  const onRow = (record: CompletedWorkModel) => ({
    onClick: () => {
      setProgressId(record?.id);
      setFoundTaskProgress(record);
      setOpenProgress(true);
    }
  });

  const rowKey = (record: CompletedWorkModel) => record.id;

  const onSelectRow = (value: CompletedWorkModel) => {
    const someProduct = selectProducts?.some(item => item.id === value.id);
    let newSelectProducts = [...selectProducts];

    if (!someProduct) newSelectProducts.push(value as never);
    else {
      newSelectProducts = newSelectProducts?.filter(item => item.id !== value?.id);
    }

    dispatch(setSelectProducts(newSelectProducts));
  };

  const rowSelection: TableRowSelection<CompletedWorkModel> | undefined = {
    fixed: "left",
    type: "checkbox",
    onSelect: onSelectRow,
    selectedRowKeys: selectProducts?.map(item => item?.id),
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        dispatch(setSelectProducts(selectedRows as never));
      } else {
        dispatch(setSelectProducts([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.DONE_WORKS,
          customFieldKeys: [CustomFieldLocationEnum.WAREHOUSE_PRODUCT]
        })
      );
    }
  };

  const saveColumns = () => {
    const columnsToSave = tableColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.DONE_WORKS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<CompletedWorkModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({ columns: columns as never, tableConfig, customFields }),
        {
          title: (
            <TableSettings
              defaultData={doneWorksTableConfig}
              configKey={tableConfigKeys.DONE_WORKS}
              locations={[CustomFieldLocationEnum.DONE_WORKS]}
              isNeedSize
            />
          ),
          render: (_, record: CompletedWorkModel) => (
            <Actions
              record={record}
              setOpen={setOpenProgress}
              setProgressId={setProgressId}
              setFoundTaskProgress={setFoundTaskProgress}
            />
          ),
          width: 70,
          className: "done_works_action_dots",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div>
      <div className={styles.top}>
        <Table
          onChangeColumns={onChangeColumns}
          onRow={onRow}
          rowKey={rowKey}
          footer={footer}
          columns={tableColumns}
          pagination={false}
          dataSource={taskProgress?.data}
          rootClassName={styles.progresses_table}
          rowClassName={styles.progresses_table_row}
          rowSelection={rowSelection}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          scroll={{ y: "calc(100vh - 315px)" }}
        />
      </div>
      <WorkModalTabs
        open={openProgress}
        setOpen={setOpenProgress}
        progressId={progressId}
        foundTaskProgress={foundTaskProgress}
      />
    </div>
  );
};

export default ProgressesTable;
