import React from "react";
import { Form, FormInstance, Select } from "antd";
import { useGetUnitSelect } from "features/app/service/queries";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { formRules } from "modules/common";

import { SelectSuffixIcon } from "components";

import ProductSelect from "./product-select";

const { Item } = Form;

type Props = {
  form: FormInstance<any>;
  type: "product" | "person" | "mechanism";
  onFinish: (values: any) => void;
};

const Content: React.FC<Props> = ({ form, type, onFinish }) => {
  const { data: units } = useGetUnitSelect();
  const { t } = useTranslation();

  const unitSelectItems = units?.map(el => ({
    value: el?.id,
    label: el?.name?.[i18next.language],
    props: {
      name: el?.name?.[i18next.language]
    }
  }));

  return (
    <Form form={form} className="mt-5 w-full" layout="vertical" onFinish={onFinish}>
      <ProductSelect isHave="yes" type={type} allowClear label={t("works.Resurs")} form={form} />
      <Item name="unit_id" rules={formRules()} label={t("works.Birlik")}>
        <Select
          showSearch
          allowClear
          options={unitSelectItems}
          suffixIcon={<SelectSuffixIcon />}
          placeholder={t("works.Birlik")}
          filterOption={selectFilterOption}
        />
      </Item>
    </Form>
  );
};

export default Content;
