import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Spin, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../hooks/redux";
import { RootState } from "../../../../store";
import { chatActions } from "../../../../store/reducers/chatReducer";
import PaperClipIcon from "../../../projects/assets/icons/PaperClipIcon";
import SendMessageIcon from "../../../projects/assets/icons/SendMessageIcon";
import { useCreateChat, useUploadFile } from "../../service/mutation";
import { useGetChat } from "../../service/queries";
import { endPoints } from "../../utils/constants/endPoints";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import { ChatBodyModel } from "../../utils/models/chatBodyModel";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import ChatMessage from "./ChatMessage";
import EmptyMessage from "./EmptyMessage";

import styles from "./chat.module.scss";

const { Item } = Form;

const Chat: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { visible, objectId, type, dataKeys, zIndex, record, dataType } = useAppSelector(
    (state: RootState) => state.chatReducer
  );
  const { setVisible } = chatActions;
  const qc = useQueryClient();

  const onAfterOpen = () => {
    if (record?.unread_message_count && record?.unread_message_count > 0) {
      if (dataType && dataType === "object") {
        if (dataKeys?.some(el => Array.isArray(el))) {
          qc.setQueryData((dataKeys[0] as string[]) || [], ({ party }: any) => ({
            party: {
              ...party,
              unread_message_count: 0
            }
          }));

          qc.setQueryData((dataKeys[1] as string[]) || [], ({ total, current_page, data }: any) => ({
            total,
            current_page,
            data: data?.map((item: any) => {
              if (item?.id === record?.id) {
                return {
                  ...item,
                  unread_message_count: 0
                };
              }
              return item;
            })
          }));
        }
      } else {
        qc.setQueryData(dataKeys || [], ({ total, current_page, data }: any) => ({
          total,
          current_page,
          data: data?.map((item: any) => {
            if (item?.id === record?.id) {
              return {
                ...item,
                unread_message_count: 0
              };
            }
            return item;
          })
        }));
      }
    }
  };

  const createChat = useCreateChat();
  const uploadFile = useUploadFile();
  const { data, isLoading } = useGetChat(type, objectId, onAfterOpen);

  const onCancel = () => {
    dispatch(
      setVisible({
        visible: false,
        objectId: undefined,
        type: "",
        dataKeys: [""],
        onAfterOpen: undefined
      })
    );
  };

  const onChangeFile = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error(t("Monitoring.File hajmi 5120 kb dan oshmasligi zarur"));
    } else {
      const file = e?.file?.originFileObj;

      form.setFieldsValue({ files: [file] });
      form.submit();
    }
  };

  const onCreateChat = (reqData: ChatBodyModel) => {
    if (reqData.message || reqData.file_ids) {
      createChat.mutateAsync(reqData).then(() => {
        qc.invalidateQueries([endPoints.CHAT_VIEW, objectId, type]).then();
        form.resetFields();
      });
    } else {
      message.info(t("Monitoring.Xabar yoki fayl kiritilmagan"));
    }
  };

  const onFinish = (fields: { message: string; files: UploadFile[] }) => {
    const reqData: ChatBodyModel = {
      message: undefined,
      object_id: objectId,
      file_ids: undefined,
      type
    };

    if (fields.files?.length > 0) {
      delete reqData.message;
      const formData = new FormData();

      fields.files?.forEach(file => {
        // @ts-ignore
        formData.append("files[]", file);
      });

      uploadFile.mutateAsync(formData).then(res => {
        reqData.file_ids = res;
        onCreateChat(reqData);
      });
    } else {
      delete reqData.file_ids;
      reqData.message = fields.message;
      onCreateChat(reqData);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const footer = (
    <div className={styles.chat__footer}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Item name="message">
          <Input
            placeholder={t("Monitoring.Message")}
            autoFocus
            disabled={createChat.isLoading || uploadFile.isLoading}
          />
        </Item>
        <Item name="files" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            name="files"
            multiple={false}
            showUploadList={false}
            onChange={onChangeFile}
            customRequest={() => null}
          >
            <Button>
              <PaperClipIcon />
            </Button>
          </Upload>
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">
            <SendMessageIcon />
          </Button>
        </Item>
      </Form>
    </div>
  );

  const onAfterOpenChange = (open: boolean) => {
    if (!open) {
      form.resetFields();
    }
  };

  useEffect(() => {
    ref.current?.scrollTo({ top: ref.current?.scrollHeight });
  }, [objectId]);

  return (
    <Drawer
      zIndex={zIndex}
      open={visible}
      width="31.5rem"
      footer={footer}
      onClose={onCancel}
      afterOpenChange={onAfterOpenChange}
      title={t("Monitoring.Izohlar")}
      className={`${styles.chat} ${isEmptyArr(data) ? styles.empty_chat : ""}`}
    >
      <Spin spinning={isLoading || createChat.isLoading || uploadFile.isLoading} indicator={LoadingIndicator}>
        <div className={styles.content} ref={ref}>
          {!isEmptyArr(data) ? (
            data?.map((item, index, arr) => <ChatMessage {...item} key={index} prevDate={arr[index - 1]?.created_at} />)
          ) : (
            <EmptyMessage />
          )}
        </div>
      </Spin>
    </Drawer>
  );
};

export default Chat;
