import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Drawer, Input, Tabs, TabsProps } from "antd";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { productsTemplateActions } from "../../../../../../../store/reducers/productsTempleteReducer";
import BreadcrumbSeparatorIcon from "../../../../../../app/assets/icons/BreadcrumbSeparatorIcon";
import { SearchIcon } from "../../../../../../app/assets/icons/SearchIcon";
import SmallFolderIcon from "../../../../../../settings/assets/icons/SmallFolderIcon";
import {
  useGetCategories,
  useGetCategoryProducts,
  useGetSearchProducts,
  useGetSubCategories,
  useGetSubCategoryProducts
} from "../../../../../../settings/service/queries";
import CreateProductModal from "../../../../../../warehouse/components/orders/product-template/CreateProductModal";
import { useCreateProduct } from "../../../../../service/mutations";

import TaskProductsTemplateDetailedFolder from "./detailed-folder/TaskProductsTemplateDetailedFolder";
import TaskProductsTemplateFolders from "./folders/TaskProductsTemplateFolders";
import TaskProductsTemplateTable from "./table/TaskProductsTemplateTable";

import styles from "./taskProductsTemplate.module.scss";

const TaskProductsTemplate: React.FC<{ action?: ActionCreatorWithPayload<any> }> = ({ action }) => {
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState<string>("1");
  const [searchValue, setSearchValue] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [visibleCreateProduct, setVisibleCreateProduct] = useState(false);
  const { setIds, setVisibleTemplate, setTaskId } = productsTemplateActions;
  const { importData: actionData } = useAppSelector(state => state.warehouseProducts.reservation);
  const { visible, products, ids, task_id } = useAppSelector(state => state.productsTemplate);
  const { t } = useTranslation()

  const initialFolderId = {
    category_id: undefined,
    sub_category_id: undefined
  };

  const initialBreadcrumbItem = [
    {
      title: <SmallFolderIcon />,
      onClick: () => {
        setTabKey("1");
        setPage(1);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setBreadcrumbItems(value => [value[0]]);
      }
    }
  ];

  const [folderId, setFolderId] = useState<{
    category_id?: number;
    sub_category_id?: number;
  }>(initialFolderId);

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItemType[]>(initialBreadcrumbItem);

  const createProduct = useCreateProduct();
  const { data: categories, isLoading: isLoadingCategories } = useGetCategories(visible);
  const { data: subCategories, isLoading: isLoadingSubCategories } = useGetSubCategories(folderId.category_id);
  const { data: categoryProducts, isLoading: isLoadingCategoryProducts } = useGetCategoryProducts(
    folderId.category_id,
    page,
    searchValue,
    tabKey
  );
  const { data: subCategoryProducts, isLoading: isLoadingSubCategoryProducts } = useGetSubCategoryProducts(
    folderId.sub_category_id,
    page,
    tabKey,
    // @ts-ignore
    searchValue
  );
  const { data: searchProducts, isLoading: isLoadingSearch } = useGetSearchProducts(searchValue, tabKey);

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(
        setVisibleTemplate({
          visible: false,
          products
        })
      );

      setTabKey("1");
      dispatch(setIds([]));

      setFolderId({
        category_id: undefined,
        sub_category_id: undefined
      });

      setBreadcrumbItems([
        {
          title: <SmallFolderIcon />,
          onClick: () => {
            setTabKey("1");
            setBreadcrumbItems(value => [value[0]]);
            setPage(1);
          }
        }
      ]);
    }
  };

  const onClose = () => {
    dispatch(setTaskId(undefined));
    dispatch(
      setVisibleTemplate({
        visible: false,
        products: []
      })
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "",
      children: (
        <TaskProductsTemplateFolders
          data={categories}
          isCategory={true}
          setTabKey={setTabKey}
          setFolderId={setFolderId}
          isLoading={isLoadingCategories}
          setBreadcrumbItems={setBreadcrumbItems}
          setPage={setPage}
        />
      )
    },
    {
      key: "2",
      label: "",
      children: (
        <TaskProductsTemplateDetailedFolder
          page={page}
          setPage={setPage}
          data={subCategories}
          setTabKey={setTabKey}
          setFolderId={setFolderId}
          products={categoryProducts}
          isLoading={isLoadingSubCategories}
          setBreadcrumbItems={setBreadcrumbItems}
          isLoadingProducts={isLoadingCategoryProducts}
        />
      )
    },
    {
      key: "3",
      label: "",
      children: (
        <TaskProductsTemplateTable
          data={subCategoryProducts}
          page={page}
          setPage={setPage}
          isLoading={isLoadingSubCategoryProducts}
        />
      )
    }
  ];

  const onImportProducts = () => {
    if (task_id) {
      createProduct
        .mutateAsync({
          task_id,
          product_ids: ids,
          amount: 0,
          quantity: 1
        })
        .then(onClose);
    } else {
      action && dispatch(action([...(products ?? []), ...(actionData ?? [])]));
      onClose();
    }
  };

  const disabledImportBtn = (): boolean => !(Array.isArray(ids) && ids.length > 0);

  const onOpenCreateProduct = () => {
    setVisibleCreateProduct(true);
  };

  const footer = () => (
    <div className={styles.footer}>
      <p>{t("projects.Tanlangan mahsulotlar")}: {ids?.length}</p>
      <div className={styles.footer__inner}>
        <Button onClick={onClose}>{t("projects.Yopish")}</Button>
        <Button onClick={onOpenCreateProduct} disabled={+tabKey <= 1}>
          {t("projects.Mahsulot qo'shish")}
        </Button>
        <Button
          type="primary"
          onClick={onImportProducts}
          disabled={disabledImportBtn()}
          loading={createProduct.isLoading}
        >
          {task_id ? t("projects.Saqlash") : t("projects.Qo'shish")}
        </Button>
      </div>
    </div>
  );

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      // setFolderId(initialFolderId);
      // setBreadcrumbItems(initialBreadcrumbItem);
      setSearchValue(e.currentTarget?.value);
      // setTabKey("1");
      // setPage(1);
    }, 800);
  };

  return (
    <div className={styles.template}>
      <Drawer
        open={visible}
        width="68.8rem"
        placement="right"
        footer={footer()}
        onClose={onClose}
        afterOpenChange={onAfterOpen}
        title={task_id ? t("projects.Shablon qo'shish") : t("projects.Resurslar")}
      >
        <div className={styles.template__top}>
          <div className={styles.template__actions}>
            <Breadcrumb items={breadcrumbItems} separator={<BreadcrumbSeparatorIcon />} />
            <Input placeholder={t("projects.Qidirish")} prefix={<SearchIcon />} onChange={onChangeSearch} className={styles.search} />
          </div>
        </div>
        <div className={styles.template__bottom}>
          {searchValue && tabKey === "1" ? (
            <TaskProductsTemplateTable
              data={searchProducts}
              isLoading={isLoadingSearch}
              page={page}
              setPage={setPage}
            />
          ) : (
            <Tabs
              activeKey={tabKey}
              items={items}
              tabBarStyle={{
                display: "none"
              }}
            />
          )}
        </div>
      </Drawer>
      <CreateProductModal
        open={visibleCreateProduct}
        setOpen={setVisibleCreateProduct}
        folderId={folderId}
        tabKey={tabKey}
      />
    </div>
  );
};

export default TaskProductsTemplate;
