import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import InfoIcon from "features/app/assets/icons/info-icon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { licenceExpiringActions } from "store";

import { useAuth } from "modules/auth/hooks";
import { dayjsFormats } from "modules/common";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const LicenseExpiring = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setOpenModal } = licenceExpiringActions;
  const licencingExpireDay = useAppSelector(state => state.licenceExpiringReducer.licenceExpiring);

  if (!user?.company_expired) return null;

  const now = dayjs();
  const companyExpireDate = dayjs(user.company_expired, dayjsFormats.DATE);
  const daysUntilExpire = companyExpireDate.diff(now, "day");
  const isLicencingExpired = licencingExpireDay > 0 && dayjs.unix(licencingExpireDay).isSame(now, "day");

  // Only show the content once a day and if the expiration is within 14 days
  if (daysUntilExpire > 14 || isLicencingExpired) return null;

  // Calculate exact time left
  const diffMs = companyExpireDate.diff(now);
  const durationLeft = dayjs.duration(diffMs);

  let timeLeft;

  if (durationLeft.asDays() >= 1) {
    timeLeft = `${durationLeft.days()} ${t("licence.kun")}`;
  } else if (durationLeft.asHours() >= 1) {
    timeLeft = `${durationLeft.hours()} ${t("licence.soat")}`;
  } else if (durationLeft.asMinutes() >= 1) {
    timeLeft = `${durationLeft.minutes()} ${t("licence.daqiqa")}`;
  } else {
    timeLeft = `${durationLeft.seconds()} ${t("licence.soniya")}`;
  }

  const handleClose = () => {
    dispatch(setOpenModal(dayjs().unix())); // Store the current timestamp in seconds
  };

  return (
    <div className="fixed z-[999] box-border flex h-10 w-screen items-center justify-between bg-error-500 pl-6 pr-2.5">
      <div className="flex items-center gap-2.5">
        <InfoIcon />
        <span className="text-sm font-semibold text-white">
          {t("licence.Litsenziya muddati tugaydi")} • {t("licence.Muddati")}: {timeLeft}
        </span>
      </div>
      <div
        className="flex cursor-pointer items-center justify-center rounded-[35px] bg-[#00000066] px-5 py-[6px] text-xs font-semibold text-white"
        onClick={handleClose}
      >
        {t("Monitoring.Yopish")}
      </div>
    </div>
  );
};

export default LicenseExpiring;
