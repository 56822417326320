import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { InputNumberProps } from "antd";
import axios from "axios";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { CurrencyModel } from "../../settings/utils/models/currency/currencyModel";
import { OPEN_CAGA_DATA, OPEN_CAGA_DATA_KEY } from "../utils/constants/api";
import { endPoints } from "../utils/constants/endPoints";
import { TABLE } from "../utils/constants/localStorageKeys";
import { notificationsTabKeys } from "../utils/constants/notificationsTabKeys";
import { queryKeys } from "../utils/constants/queryKeys";
import { queryParamsKeys } from "../utils/constants/queryParamsKeys";
import { ResourceModel } from "../utils/constants/resourceModel";
import { tableConfigKeys } from "../utils/constants/tableConfigKeys";
import { UnitModel } from "../utils/constants/unitModel";
import { CustomFieldLocationEnum } from "../utils/enums/customFieldLocationEnum";
import $api from "../utils/helpers/axiosInstance";
import { isEmptyArr } from "../utils/helpers/isEmptyArr";
import { IBuilders } from "../utils/models/buildersSelectModel";
import { IBuildersView } from "../utils/models/buildersViewModal";
import { CashSelectModel } from "../utils/models/cash/cashSelectModel";
import { CashTreeModel } from "../utils/models/cash/cashTreeModel";
import { ChatModel } from "../utils/models/chatModel";
import { CompanyPersonModel } from "../utils/models/companyPersonSelect";
import { CurrencySelectModel } from "../utils/models/currencySelectModel";
import { CustomFieldSelectModel } from "../utils/models/customFieldSelectModel";
import { IDistrictsAndRegions } from "../utils/models/districtsModel";
import { FileModel } from "../utils/models/fileModel";
import { GlobalChatModel } from "../utils/models/global-chat-model";
import { LanguageModel } from "../utils/models/LanguageModel";
import { NotificationModel, NotificationPaginationModel } from "../utils/models/notificationModel";
import { OpenCaga } from "../utils/models/openCageData";
import { PaginationType } from "../utils/models/PaginationType";
import { IPaymentExchangeHistory } from "../utils/models/payment/IPaymentExchangeHistory";
import { IPaymentTransferHistory } from "../utils/models/payment/IPaymentTransferHistory";
import { PermissionModel } from "../utils/models/permissionModel";
import { ProductSelectModel } from "../utils/models/productSelectModel";
import { ProjectSelectModel } from "../utils/models/projectSelectModel";
import { UnitTreeModel } from "../utils/models/unitTreeModel";
import { UserSelectModel } from "../utils/models/user/userSelectModel";
import { ColumnConfigModel, UserTableDataModel } from "../utils/models/user/userTableConfigModel";
import { WarehouseTreeModel } from "../utils/models/warehouse/warehouseTreeModel";

import { LocalStorage } from "./LocalStorage";

export function useGetCurrenciesSelect(enabled?: boolean) {
  return useQuery<CurrencySelectModel[]>(
    ["currency-select"],
    async () => {
      const res = await $api.get("company-currency/select");

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetWarehouseTree(enabled?: boolean) {
  return useQuery<WarehouseTreeModel>(
    ["warehouse-tree"],
    async () => {
      const res = await $api.get("warehouse/tree");

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetCashTree(enabled?: boolean) {
  return useQuery<CashTreeModel>(
    ["cash-tree"],
    async () => {
      const res = await $api.get("cash/tree");

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetUsersSelect(enabled?: boolean, notMe?: boolean) {
  return useQuery<UserSelectModel[]>(
    ["user-select"],
    async () => {
      const res = await $api.get(`admin/select?not_me=${notMe ? "true" : "false"}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetCompanyPersons(enabled?: boolean) {
  return useQuery<CompanyPersonModel[]>(
    [queryKeys.COMPANY_PERSON_SELECT],
    async () => {
      const res = await $api.get(endPoints.COMPANY_PERSON_SELECT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}

export function useGetResourceSelect() {
  return useQuery<ResourceModel[]>(
    [queryKeys.RESOURCES],
    async () => {
      const res = await $api.get(endPoints.RESOURCE_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );
}

export function useGetUnitSelect(enabled?: boolean) {
  return useQuery<UnitModel[]>(
    [queryKeys.UNITS],
    async () => {
      const res = await $api.get(endPoints.UNIT_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetUnitTree(enabled?: boolean) {
  return useQuery<UnitTreeModel>(
    [queryKeys.UNIT_TREE],
    async () => {
      const res = await $api.get(endPoints.UNIT_TREE);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetCurrenySelect() {
  return useQuery<CurrencyModel[]>(
    [queryKeys.CURRENCY_SELECT],
    async () => {
      const res = await $api.get(endPoints.CURRENCY_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );
}

export function useGetAllCashSelect() {
  const url = endPoints.CASH_SELECT;

  return useQuery<CashSelectModel[]>(
    [queryKeys.ALL_CASH_SELECT],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetMineCashSelect(enabled: boolean) {
  const url = `${endPoints.CASH_SELECT}?mine=true`;

  return useQuery<CashSelectModel[]>(
    [queryKeys.CASH_SELECT],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetCashSelect(enabled?: boolean, mine?: boolean) {
  let url = endPoints.CASH_SELECT;

  if (mine) url += `?mine=true`;

  return useQuery<CashSelectModel[]>(
    [queryKeys.CASH_SELECT],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: enabled ?? true
    }
  );
}

export function useGetAllProductSelect(active?: boolean) {
  return useQuery<ProductSelectModel[]>(
    [queryKeys.ALL_PRODUCT_SELECT],
    async () => {
      const res = await $api.get(endPoints.PRODUCT_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: active ?? true
    }
  );
}

interface GetProductSelectParams {
  active?: boolean;
  paginate?: boolean;
  search?: string;
}

export function useGetProductSelect({ active, search }: GetProductSelectParams) {
  return useQuery<PaginationType<ProductSelectModel[]>>(
    [queryKeys.PRODUCT_SELECT, search],
    async () => {
      const res = await $api.get(`${endPoints.PRODUCT_SELECT}?search=${search}&paginate=true`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: active ?? true
    }
  );
}

export function useGetLanguages(enabled?: boolean) {
  const { i18n } = useTranslation();

  return useQuery<LanguageModel[]>(
    [queryKeys.LANGUAGES],
    async () => {
      const res = await $api.get(endPoints.LANGUAGES);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled ?? true,
      select(data) {
        return data?.sort(a => {
          if (a.symbol !== i18n.language) return 1;
          return -1;
        });
      }
    }
  );
}

export function useGetChat(type: string, id?: number, onAfterOpen?: () => void) {
  const qc = useQueryClient();

  return useQuery<ChatModel[]>(
    [queryKeys.CHAT_VIEW, id, type],
    async () => {
      const res = await $api.get(`${endPoints.CHAT_VIEW}?object_id=${id}&type=${type}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        // dataKeys?.forEach(key => qc.invalidateQueries([key]));
        onAfterOpen && onAfterOpen();
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
}

export function useGetNotifications(type: notificationsTabKeys) {
  const url: Record<notificationsTabKeys, string> = {
    [notificationsTabKeys.ALL]: `${endPoints.NOTIFICATION}?`,
    [notificationsTabKeys.READ]: `${endPoints.NOTIFICATION}?viewed=true&`,
    [notificationsTabKeys.UNREAD]: `${endPoints.NOTIFICATION}?viewed=false&`
  };

  return useInfiniteQuery<NotificationPaginationModel<NotificationModel[]>>({
    queryKey: [queryKeys.NOTIFICATIONS, type],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await $api.get(`${url[type]}page=${pageParam}`);

      return res.data.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity
  });
}

export function useGetAllUnreadMessage() {
  return useInfiniteQuery<PaginationType<GlobalChatModel[]>>({
    queryKey: [queryKeys.GLOBAL_CHAT],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await $api.get(`${endPoints.GLOBAL_CHAT}?page=${pageParam}`);

      return res.data.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });
}

export function useGetAllUnreadMessageCount() {
  return useQuery<number>(
    [queryKeys.GLOBAL_CHAT_COUNT],
    async () => {
      const res = await $api.get(endPoints.GLOBAL_CHAT_COUNT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0
    }
  );
}

export function useGetProjectsSelect(enabled?: boolean, warehouseId?: InputNumberProps) {
  let url = endPoints.PROJECTS_SELECT;

  if (warehouseId) {
    url += `?warehouse_id=${warehouseId}`;
  }

  return useQuery<{ id: number; name: string }[]>(
    [queryKeys.PROJECTS_SELECT, warehouseId],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled
    }
  );
}

export function useGetSectionsSelect(enabled?: boolean, project_id?: string) {
  return useQuery<{ id: number; name: string }[]>(
    [queryKeys.SECTIONS_SELECT],
    async () => {
      const res = await $api.get(`${endPoints.SECTIONS_SELECT}?project_id=${project_id}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: enabled && !!project_id
    }
  );
}
export function useGetSectionSelect(enabled?: boolean, project_ids?: string[]) {
  let url = `${endPoints.SECTIONS_SELECT}`;

  if (project_ids && project_ids.length > 0) {
    const queryParams = project_ids.map(id => `project_ids[]=${encodeURIComponent(id)}`).join("&");

    url += `?${queryParams}`;
  }

  return useQuery<{ id: number; name: string }[]>(
    [queryKeys.SECTION_SELECT, project_ids],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!enabled // Ensuring 'enabled' is a boolean
    }
  );
}

export function useGetTableConfigs(key?: tableConfigKeys, defaultData?: UserTableDataModel) {
  const { queries } = useQueryParams();
  const { tab } = queries();

  return useQuery<UserTableDataModel>(
    [queryKeys.TABLE_CONFIGS, key, tab],
    async () => {
      const res = await $api.get(`${endPoints.TABLE_CONFIGS}?key=${key}`);

      const resData = res.data?.data ? JSON.parse(res.data?.data) : { column: [], size: 10 };

      if (!isEmptyArr(resData?.column)) {
        const defaultColumnSet = new Set(resData?.column?.map((el: ColumnConfigModel) => el?.id));

        defaultData?.column.forEach(el => {
          if (!defaultColumnSet.has(el.id)) {
            resData.column.push(el);
          }
        });
      }
      const column = isEmptyArr(resData?.column) ? defaultData?.column : resData?.column;

      const size = resData?.size || defaultData?.size;
      const width_data = (JSON.parse(res?.data?.width_data || "[]") as Array<any>) || [];

      try {
        const oldTableSizes = JSON.parse(localStorage.getItem(TABLE) || "{}");

        localStorage.setItem(
          TABLE,
          JSON.stringify({
            ...oldTableSizes,
            [key!]: size
          })
        );
      } catch {
        console.error("Error getting tableConfigs data", [queryKeys.TABLE_CONFIGS, key, tab]);
      }

      return { column, size, width_data, filter: res?.data?.filter };
    },
    {
      enabled: !!key
    }
  );
}

export function useGetPermissionSelect() {
  return useQuery<PermissionModel[]>(
    [queryKeys.PERMISSION_SELECT],
    async () => {
      const res = await $api.get(endPoints.PERMISSON_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCustomFieldSelect(locations: CustomFieldLocationEnum[], selectable?: boolean) {
  const parseLocations = queryString.stringify({ locations }, { arrayFormat: "bracket" });
  let url = `${endPoints.CUSTOM_FIELD_SELECT}?${parseLocations}`;

  if (selectable) {
    url += "&selectable=true";
  }

  return useQuery<CustomFieldSelectModel[]>(
    [queryKeys.CUSTOM_FIELD_SELECT, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false
    }
  );
}

export function usePaymentTransferHistory(id: string | number = "", isDeleted?: boolean) {
  // const qc = useQueryClient();
  const queryKey = [queryKeys.PAYMENT.TRANSFER.HISTORY, id];

  // buni enabledga bergan. buni commit qilishdan maqsad bekor qilib o'chirmoqchi bo'lsam status o'zgarmay qoldi shuning uchun
  // const getCacheData = qc.getQueryData([queryKeys.PAYMENT.TRANSFER.HISTORY, id]);

  return useQuery<IPaymentTransferHistory>(
    queryKey,
    async () => {
      const res = await $api.get(`${isDeleted ? endPoints.PAYMENT_DELETED_VIEW : endPoints.PAYMENT_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
}

export function usePaymentExchangeHistory(id?: string | number, isDeleted?: boolean) {
  const queryKey = [queryKeys.PAYMENT.EXCHANGE.HISTORY, id];

  return useQuery<IPaymentExchangeHistory>(
    queryKey,
    async () => {
      const res = await $api.get(`${isDeleted ? endPoints.PAYMENT_DELETED_VIEW : endPoints.PAYMENT_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
}

export function useGetFileData(fileId?: number, isView?: boolean) {
  return useQuery<FileModel[]>(
    [queryKeys.FILE, fileId],
    async () => {
      const res = await $api.get(`${endPoints.GET_FILE_DATA}?ids[]=${fileId}`);

      return res.data?.data;
    },
    {
      enabled: !!fileId && isView
    }
  );
}

export function useGetRegionsSelect() {
  return useQuery<IDistrictsAndRegions[]>([queryKeys.REGIONS], async () => {
    const res = await $api.get(endPoints.REGIONS_SELECT);

    return res.data.data;
  });
}

export function useGetDistrictsSelect(region_id?: number) {
  return useQuery<IDistrictsAndRegions[]>(
    [queryKeys.DISTRICTS, region_id],
    async () => {
      const res = await $api.get(endPoints.DISTRICTS_SELECT(region_id!));

      return res.data.data;
    },
    {
      enabled: !!region_id
    }
  );
}

export function useGetBuildersSelect() {
  return useQuery<IBuilders[]>([queryKeys.BUILDERS], async () => {
    const res = await $api.get(endPoints.BUILDERS_SELECT);

    return res.data.data;
  });
}

export function useGetBuilderView() {
  let url = endPoints.BUILDERS_VIEW.WITHOUT_ID;
  const { reqQueryParam, searchParams } = useQueryParams();

  if (searchParams) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE, queryParamsKeys.SEARCH)}`;
  }

  return useQuery<PaginationType<IBuildersView[]>>([queryKeys.BUILDERS, searchParams], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetBuilderViewById(id: number | undefined) {
  return useQuery<IBuildersView>(
    [queryKeys.BUILDERS, id],
    async () => {
      const res = await $api.get(endPoints.BUILDERS_VIEW.WITH_ID(id));

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetOpenCageData(search: string) {
  const token = LocalStorage.get("token");

  return useQuery<OpenCaga>(
    [queryKeys.OPEN_CAGE, search],
    async () => {
      const res = await axios.get(
        `${OPEN_CAGA_DATA}?key=${OPEN_CAGA_DATA_KEY}&pretty=1&no_annotations=1&language=uz&${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return res.data;
    },
    {
      enabled: !!search
    }
  );
}

export function useProjectSelect({
  cashId,
  warehouseId,
  enabled = true
}: {
  cashId?: number;
  warehouseId?: number;
  enabled?: boolean;
}) {
  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({ cash_id: cashId, warehouse_id: warehouseId });

  return useQuery<ProjectSelectModel[]>(
    [queryKeys.PROJECT_SELECT, cashId, warehouseId],
    async () => {
      const res = await $api.get(`project/select${searchParams ? `?${searchParams}` : ""}`);

      return res.data.data;
    },
    {
      enabled,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
}
