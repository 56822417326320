import { useMemo } from "react";
import { Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { DynamicEstimateProductModel } from "../../../../utils/models/dynamicEstimateModel";

import ExpandedRow from "./ExpandedRow";

import styles from "./productView.module.scss";

type RowType = "plan" | "fact";

interface ColumnData {
  id: number;
  type: RowType;
  name: string;
  quantity: number;
  unit: string;
  amount: number;
  total_amount: number;
  plan: boolean;
  currency?: string;
  expandable?: boolean;
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  product?: DynamicEstimateProductModel;
  smetaContainer?: () => HTMLElement;
};

const ProductView = ({ open, setOpen, product, smetaContainer }: Props) => {
  const { i18n, t } = useTranslation();
  const currency = useAppSelector(state => state.projectsReducer.projectCurrency);

  const data = useMemo((): ColumnData[] => {
    if (product) {
      if (product?.plan) {
        return [
          {
            id: 1,
            type: "plan" as RowType,
            name: t("project.Rejada"),
            quantity: product?.quantity,
            unit: product?.unit?.symbol[i18n.language],
            amount: product?.amount,
            plan: product?.plan,
            total_amount: product?.total_amount,
            currency: currency?.symbol
          },
          {
            id: 2,
            type: "fact" as RowType,
            name: t("project.Fakt"),
            quantity: product?.spend_total_quantity,
            unit: product?.unit?.symbol[i18n.language],
            amount:
              (product.spend_total_amount + product.expected_total_amount) /
              (product.quantity === 0 ? 1 : product.quantity),
            total_amount: product?.expected_total_amount + product?.spend_total_amount,
            plan: product?.plan,
            currency: currency?.symbol,
            expandable: true
          }
        ];
      }
      return [
        {
          id: 1,
          type: "fact" as RowType,
          name: t("project.Fakt"),
          quantity: product?.spend_total_quantity,
          unit: product?.unit?.symbol[i18n.language],
          amount: product?.spend_total_quantity === 0 ? 0 : product.spend_total_amount / product.spend_total_quantity,
          total_amount:
            product?.spend_total_quantity === 0 ? 0 : product?.expected_total_amount + product?.spend_total_amount,
          plan: product?.plan,
          currency: currency?.symbol,
          expandable: true
        }
      ];
    }
    return [];
  }, [currency?.symbol, i18n.language, product]);

  const onCancel = () => {
    setOpen(false);
  };

  const title = () => (
    <div className={`${styles.name} resource`}>
      <div
        style={{
          borderColor: product?.product?.resource?.color,
          color: product?.product?.resource?.color
        }}
      >
        {product?.product?.resource?.symbol[i18n.language]}
      </div>
      <p>{product?.product?.name[i18n.language]}</p>
    </div>
  );

  const getStatus = (type: RowType) => {
    if (product && type === "fact") {
      if (product?.spend_total_amount + product?.expected_total_amount > product?.total_amount) {
        return "error";
      }
      if (product?.spend_total_amount + product?.expected_total_amount < product?.total_amount) {
        return "success";
      }
    }
    return "";
  };

  const columns: ColumnsType<ColumnData> = [
    {
      title: "T/r",
      dataIndex: "name",
      width: "30%"
    },
    {
      title: t("project.Hajmi"),
      dataIndex: "quantity",
      width: "10%"
    },
    {
      title: t("project.Birligi"),
      dataIndex: "unit",
      width: "15%"
    },
    {
      title: t("project.Narxi"),
      render: (record: ColumnData) => (
        <div className={record?.plan ? styles[getStatus(record.type)] : ""}>{record?.amount?.toLocaleString(RU)}</div>
      ),
      width: "15%"
    },
    {
      title: t("project.Umumiy narxi"),
      render: (record: ColumnData) => (
        <div className={record?.plan ? styles[getStatus(record.type)] : ""}>
          {record?.total_amount?.toLocaleString(RU)}
        </div>
      ),
      width: "20%"
    },
    {
      title: t("project.Valyuta"),
      dataIndex: "currency",
      width: "10%"
    }
  ];

  const rowKey = (record: ColumnData) => record.id;

  return (
    <Modal
      centered
      footer={null}
      open={open}
      title={title()}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      width={1075}
      className={styles.product_view}
      getContainer={smetaContainer}
    >
      <Table<ColumnData>
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        rowClassName={() => styles.row}
        expandable={{
          expandedRowRender: (record, _, __, expanded) => (
            <ExpandedRow id={product?.id} expanded={expanded} product={product} key={record?.id} />
          ),
          rowExpandable: record => !!record?.expandable,
          expandRowByClick: true
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        className={styles.table}
      />
    </Modal>
  );
};

export default ProductView;
