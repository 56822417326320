import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { useAppSelector } from "../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import CreditCardDownloadIcon from "../../../../assets/icons/CreditCardDownloadIcon";
import CreditCardUploadIcon from "../../../../assets/icons/CreditCardUploadIcon";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";

import styles from "./paymentInfoRight.module.scss";

const PaymentInfoRight: React.FC = () => {
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const incomeExpenseActions = actions("Kirim-chiqim");
  const incomeActions = actions("Kutilayotgan to'lovlar");
  const expenseActions = actions("Qarzlarim");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setIncomeExpenseModal, setVisibleExpectedPayment } = paymentReducerActions;
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const type = searchParams.get(queryParamsKeys.TYPE);
  const { firstStatisticsType } = useAppSelector(state => state.paymentReducer);

  const onOpenIncomeExpenseModal = (isIncome: boolean) => {
    dispatch(
      setIncomeExpenseModal({
        visible: true,
        type: isIncome ? PaymentTypeEnum.INCOME : PaymentTypeEnum.EXPENSE,
        isVisibleMoreInfo: false
      })
    );
  };

  const onOpenPendingPaymentModal = () => {
    dispatch(
      setVisibleExpectedPayment({
        visible: true,
        type: type as PaymentTypeEnum
      })
    );
  };

  const typeItems = {
    [PaymentTypeEnum.INCOME]: (
      <>
        {incomeActions.create ? (
          <div className={styles.right}>
            <div className={styles.income} onClick={onOpenPendingPaymentModal}>
              <PlusIcon />
              <span>
                {t("payment.Kirim shartnoma")} <br /> {t("payment.kiritish")}
              </span>
            </div>
          </div>
        ) : null}
      </>
    ),
    [PaymentTypeEnum.EXPENSE]: (
      <>
        {expenseActions.create ? (
          <div className={styles.right}>
            <div className={styles.expense} onClick={onOpenPendingPaymentModal}>
              <PlusIcon />
              <span>{t("payment.Chiqim shartnoma kiritish")}</span>
            </div>
          </div>
        ) : null}
      </>
    )
  };

  const items = {
    [PaymentTabKeys.INCOME_EXPENSE]: (
      <>
        <div className={styles.right}>
          <div className={styles.income_or_expense}>
            {incomeExpenseActions.makeIncome && (
              <div onClick={() => onOpenIncomeExpenseModal(true)} className={styles.income_btn}>
                <CreditCardDownloadIcon />
                <span className="text-success-500">{t("payment.Kirim")}</span>
              </div>
            )}
            {(incomeExpenseActions?.create || incomeExpenseActions.createDraft) && (
              <div onClick={() => onOpenIncomeExpenseModal(false)} className={styles.expense_btn}>
                <CreditCardUploadIcon />
                <span className="text-error-500">{t("payment.Chiqim")}</span>
              </div>
            )}
          </div>
        </div>
      </>
    ),
    [PaymentTabKeys.PAYMENT]:
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      typeItems[(type ?? firstStatisticsType?.second!) as keyof typeof typeItems],
    [PaymentTabKeys.ORDER]: null,
    [PaymentTabKeys.WORK]: null,
    [PaymentTabKeys.INVENTORY]: null,
    [PaymentTabKeys.CALENDAR]: null
  };

  return tabKey ? items[tabKey as keyof typeof items] : items[firstStatisticsType.first as keyof typeof items];
};

export default PaymentInfoRight;
