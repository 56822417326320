import { useEffect, useRef, useState } from "react";
import { Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { cx } from "features/app/utils/helpers/cx";
import { useTranslation } from "react-i18next";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { CheckIcon } from "../../../../app/assets/icons/CheckIcon";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useGetRequests } from "../../../service/queries";
import { InventoryStatusIcons, InventoryStatusValues } from "../../../utils/constants/inventoryStatus";
import { InventoryStatusEnum } from "../../../utils/enums/inventoryStatusEnum";
import { RequestModel } from "../../../utils/models/requestModel";
import Top from "../top/Top";

import ChangeStatus from "./ChangeStatus";

import styles from "./bottom.module.scss";

const Bottom = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const { data: requests, isLoading } = useGetRequests();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.INVENTORY_REQUESTS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.INVENTORY_REQUESTS);
  const [renderColumns, setColumns] = useState<ColumnsType<RequestModel>>([]);

  const columns = [
    {
      title: `${t("Inventar.So’rov tushdi")}`,
      render: (record: RequestModel) => (
        <span className={record?.status === InventoryStatusEnum.PENDING ? styles.bold : ""}>{record?.created_at}</span>
      ),
      width: 220,
      className: "inventory-request"
    },
    {
      title: `${t("Inventar.Mexanizm nomi va kodi")}`,
      render: (record: RequestModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "inventory-name-code")?.width;

        return (
          <div className={`resource ${record?.status === InventoryStatusEnum.PENDING ? styles.bold : ""}`}>
            <div className={styles.resource}>MEX</div>
            <span
              className={
                record?.status === InventoryStatusEnum.PENDING
                  ? cx(styles.bold, "flex items-center gap-1")
                  : "flex items-center gap-1"
              }
            >
              <PopoverShortName title={record?.inventory?.name} length={((columnWidth as number) + 150) / 10} />
              <span>
                (<PopoverShortName title={record?.inventory?.code} length={((columnWidth as number) + 150) / 10} />)
              </span>
            </span>
          </div>
        );
      },
      width: 750,
      className: "inventory-name-code"
    },
    {
      title: `${t("MainLayoutLinks.Loyiha")}`,
      render: (record: RequestModel) => (
        <span className={record?.status === InventoryStatusEnum.PENDING ? styles.bold : ""}>
          {record?.project?.name}
        </span>
      ),
      width: 250,
      className: "inventory-project"
    },
    {
      title: `${t("Xodimlar.Xodim")}`,
      render: (record: RequestModel) => (
        <Popover title={record?.creator?.full_name} overlayClassName={styles.popover}>
          {record?.creator?.image ? (
            <img src={record?.creator?.image} className={styles.agent__image} alt={record?.creator?.full_name} />
          ) : (
            <span className={styles.agent}>
              {record?.creator?.full_name && record?.creator?.full_name?.split(" ")[0][0]?.toUpperCase()}
            </span>
          )}
        </Popover>
      ),
      width: 300,
      className: "inventory-creator"
    },
    {
      title: `${t("Inventar.Muddat")}`,
      render: (record: RequestModel) => (
        <span className={record?.status === InventoryStatusEnum.PENDING ? styles.bold : ""}>
          {record?.start_date} - {record?.due_date}
        </span>
      ),
      width: 450,
      className: "inventory-date"
    },

    {
      title: "",
      render: (record: RequestModel) => (
        <div className={styles.actions}>
          <span
            className={`${record.status === InventoryStatusEnum.PENDING ? styles.bold : ""} ${styles[record.status]}`}
          >
            {InventoryStatusIcons[record.status]} {t(`Inventar.${InventoryStatusValues[record.status]}`)}
          </span>
          {record.status === InventoryStatusEnum.PENDING && (
            <div className={styles.action__buttons}>
              <ChangeStatus id={record.id} status={InventoryStatusEnum.RECIEVED}>
                <CheckIcon />
              </ChangeStatus>
              <ChangeStatus id={record.id} status={InventoryStatusEnum.REJECTED}>
                <CloseIcon />
              </ChangeStatus>
            </div>
          )}
        </div>
      ),
      width: 150,
      className: "inventory-status"
    }
  ];

  const rowKey = (record: RequestModel) => record.id;

  const footer = () => (
    <div className={styles.pagination}>
      <Pagination
        paginationProps={{
          total: requests?.total
        }}
      />
    </div>
  );

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.INVENTORY_REQUESTS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<RequestModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    if (tableConfig!?.width_data!?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    } else {
      setColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfig]);

  return (
    <div className={styles.bottom}>
      <Table<RequestModel>
        onChangeColumns={onChangeColumns}
        dataSource={requests?.data}
        columns={renderColumns}
        pagination={false}
        rowKey={rowKey}
        title={() => <Top />}
        rowClassName={() => styles.row}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        footer={requests?.total && requests?.total > 10 ? footer : () => false}
        // scroll={{
        //   y: "calc(100vh - 18.125rem)"
        // }}
      />
    </div>
  );
};

export default Bottom;
