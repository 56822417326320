import React, { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Dropdown, FormInstance, Input, Popover } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { colors } from "features/app/utils/constants/colors";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";

import { useTaskSelect } from "../../../../../service/queries";
import { TaskBodyModel } from "../../../../../utils/models/taskBodyModel";
import { TaskSelectModel } from "../../../../../utils/models/taskSelectModel";

import styles from "features/projects/components/detailed-project/static-estimate/section/append/sectionAppend.module.scss";

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

type Props = {
  sectionId: number;
  form: FormInstance;
  afterFunc: () => void;
  mutation: UseMutationResult<SuccessRes, ErrorRes, TaskBodyModel>;
};

const CreateTaskName: React.FC<Props> = ({ mutation, afterFunc, sectionId, form }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [time, setTime] = useState<TimeoutModel>();
  const { data, fetchNextPage } = useTaskSelect({ search });
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const { t } = useTranslation();

  const tasks = useMemo(() => {
    let result: TaskSelectModel[] = [];

    data?.pages?.forEach(item => {
      result = [...result, ...(item?.data || [])];
    });

    setOpen(!isEmptyArr(result));

    return result;
  }, [data?.pages, search]);

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 2) {
      fetchNextPage().then();
    }
  };

  const onSearch = (value: string) => {
    clearTimeout(time);

    form.setFieldValue("name", value);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 300)
    );
  };

  const onSelect =
    ({ name, id }: { name: string; id: number }) =>
    () => {
      function removeNumberPrefix(str: string) {
        return str.replace(/^\d+-/, "");
      }

      mutation
        .mutateAsync({
          id,
          is_title: false,
          section_id: sectionId,
          name: removeNumberPrefix(name)
        })
        .then(() => {
          setOpen(false);
          afterFunc();
        });
    };

  return (
    <Dropdown
      open={open}
      arrow={false}
      rootClassName={styles.sections}
      onOpenChange={open => setOpen(open)}
      getPopupContainer={() => smetaContainer}
      dropdownRender={originNode => (
        <div
          onScrollCapture={onPopupScroll}
          className="max-h-96 w-max overflow-y-auto rounded-lg border border-solid border-gray-300 bg-white"
        >
          {originNode}
        </div>
      )}
      menu={{
        items: tasks?.map(task => {
          const customFields = [
            ...(task?.section?.custom_field_values?.filter(field => field?.custom_field?.type === "select") || [])
          ];
          const endCustomFields = customFields?.slice(2);

          return {
            key: task?.id,
            value: task?.name,
            title: String(task?.id),
            label: (
              <div className="flex flex-col">
                <div className="flex text-sm font-medium text-gray-900">
                  {task.place} {task.name}
                </div>
                <div className="flex items-center justify-between gap-8">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-1 text-sm font-normal text-gray-500">
                      <span>{task?.section?.place}.</span>
                      <span>{task?.section?.name}</span>
                    </div>
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-500" />
                    <span className="text-sm font-normal text-gray-500">{task?.section?.project?.name}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    {customFields?.slice(0, 2)?.map(field => (
                      <div
                        key={field?.id}
                        className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                        style={{ background: `${field?.custom_field_option?.color}20` }}
                      >
                        <div
                          className="h-2.5 w-2.5 rounded-full"
                          style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                        />
                        <span
                          className="text-xs font-medium"
                          style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                        >
                          {field?.custom_field_option?.name?.[i18n.language]}
                        </span>
                      </div>
                    ))}
                    <ConditionalRender if={!isEmptyArr(endCustomFields)}>
                      <Popover
                        zIndex={11119}
                        placement="top"
                        arrow={false}
                        content={
                          <div className="flex max-w-80 flex-wrap gap-2">
                            {endCustomFields?.map(field => (
                              <div
                                key={field?.id}
                                className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                                style={{ background: `${field?.custom_field_option?.color}20` }}
                              >
                                <div
                                  className="h-2.5 w-2.5 rounded-full"
                                  style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                                />
                                <span
                                  className="text-xs font-medium"
                                  style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                                >
                                  {field?.custom_field_option?.name?.[i18n.language]}
                                </span>
                              </div>
                            ))}
                          </div>
                        }
                      >
                        <div className="flex items-center gap-2 rounded-xl bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                          + {endCustomFields?.length}
                        </div>
                      </Popover>
                    </ConditionalRender>
                  </div>
                </div>
              </div>
            ),
            onClick: onSelect({ name: task?.name, id: task?.id })
          };
        })
      }}
    >
      <Input onChange={e => onSearch(e.target.value)} placeholder={t("project.Nomi")} />
    </Dropdown>
  );
};

export default CreateTaskName;
