import React from "react";
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn, useWatch } from "react-hook-form";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import PlusCircleIcon from "features/app/assets/icons/PlusCircleIcon";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";

import { localeFormatter, parseLocaledString } from "modules/common";
import {
  AllCompanyPersonsUpdate,
  AllCurrenciesUpdate,
  AllProjectsUpdate,
  AllVatsUpdate,
  AllWarehousesUpdate,
  Amount,
  CompanyPerson,
  Currency,
  Delete,
  PartyForm,
  PartyFormProduct,
  Product,
  Project,
  Quantity,
  TotalAmount,
  Unit,
  Vat,
  Warehouse
} from "modules/party/create";

import { ConditionalRender } from "components";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  remove: UseFieldArrayRemove;
  form: UseFormReturn<PartyForm>;
  fields: PartyForm["warehouse_products"];
  append: UseFieldArrayAppend<PartyForm, "warehouse_products">;
};

const Index: React.FC<Props> = ({ form, remove, append, fields, isUpdate }) => {
  const { t } = useTranslation();
  const { isVat: isVatCreate } = useAppSelector(({ partyReducer }) => partyReducer.create);
  const { isVat: isVatUpdate } = useAppSelector(({ partyReducer }) => partyReducer.update);
  const { warehouse_products } = useWatch({
    control: form.control
  });

  const onAddProduct = () => {
    const oldProducts = [...(form.getValues("warehouse_products") || [])];
    const lastProduct = { ...oldProducts?.[oldProducts.length - 1] } as PartyFormProduct;

    append({
      // @ts-ignore
      product_id: null,
      // @ts-ignore
      unit_id: null,
      unit_name: "",
      quantity: "",
      amount: "",
      project_id: lastProduct?.project_id,
      warehouse_id: lastProduct?.warehouse_id,
      company_person_id: lastProduct?.company_person_id,
      company_person_name: lastProduct?.company_person_name,
      currency_id: lastProduct?.currency_id,
      currency_symbol: lastProduct?.currency_symbol,
      vat_id: undefined,
      vat_value: undefined,
      vat_amount: undefined,
      total: "",
      rowId: uid()
    });
  };

  const footer = () => (
    <div
      onClick={onAddProduct}
      className="flex w-max cursor-pointer items-center gap-2 text-sm font-medium text-primary-500"
    >
      <PlusCircleIcon fill />
      {t("payment.Resurs qo'shish")}
    </div>
  );

  const columns: ColumnsType = [
    {
      key: 1,
      title: t("payment.Resurs turi va nomi"),
      render: (_, __, index) => <Product isUpdate={isUpdate} form={form} index={index} />,
      width: 330
    },
    {
      key: 2,
      title: t("payment.Birligi"),
      render: (_, __, index) => <Unit  isUpdate={isUpdate} index={index} form={form} />
    },
    {
      key: 3,
      title: t("payment.Soni"),
      render: (_, __, index) => <Quantity index={index} form={form} />,
      width: 100
    },
    {
      key: 4,
      title: t("partyCreate.Birlik narxi"),
      render: (_, __, index) => <Amount index={index} form={form} />,
      width: 100
    },
    {
      key: 5,
      title: <AllCurrenciesUpdate form={form} type="initial" />,
      render: (_, __, index) => <Currency key={uid()} form={form} index={index} isUpdate={isUpdate} />
    },
    {
      key: 6,
      title: <AllCompanyPersonsUpdate form={form} type="initial" />,
      render: (_, __, index) => <CompanyPerson key={uid()} index={index} form={form} isUpdate={isUpdate} />
    },
    {
      key: 7,
      title: <AllWarehousesUpdate form={form} type="initial" />,
      render: (_, __, index) => <Warehouse key={uid()} form={form} index={index} isUpdate={isUpdate} />
    },
    {
      key: 8,
      title: <AllProjectsUpdate form={form} type="initial" />,
      render: (_, __, index) => <Project isUpdate={isUpdate} form={form} index={index} />
    },
    {
      key: 9,
      title: <AllVatsUpdate form={form} type="initial" />,
      render: (_, __, index) => <Vat key={uid()} index={index} form={form} isUpdate={isUpdate} />,
      hidden: !(isVatCreate || isVatUpdate)
    },
    {
      key: 10,
      title: t("payment.Summa"),
      render: (_, __, index) => <TotalAmount index={index} form={form} />
    },
    {
      key: 11,
      width: 120,
      title: t("partyCreate.NDS summa"),
      render: (_, __, index) => (
        <div className="box-border flex h-full w-full items-center bg-white p-3.5 text-sm font-medium">
          <ConditionalRender if={warehouse_products?.[index]?.vat_id} else="--">
            {localeFormatter(String(warehouse_products?.[index]?.vat_amount?.toFixed(2)))}
          </ConditionalRender>
        </div>
      ),
      hidden: !(isVatCreate || isVatUpdate)
    },
    {
      key: 12,
      title: t("payment.Jami"),
      render: (_, __, index) => (
        <div className="box-border flex h-full w-full items-center bg-white p-3.5 text-sm font-medium">
          <ConditionalRender if={warehouse_products?.[index]?.vat_id} else="--">
            {localeFormatter(
              String(
                (
                  (warehouse_products?.[index]?.vat_amount || 0) +
                  parseLocaledString(warehouse_products?.[index]?.total || "0")
                ).toFixed(2)
              )
            )}
          </ConditionalRender>
        </div>
      ),
      hidden: !(isVatCreate || isVatUpdate)
    },
    {
      key: 13,
      title: "",
      render: (_, __, index) => <Delete isUpdate={isUpdate} index={index} remove={remove} control={form.control} />
    }
  ];

  return (
    <>
      <Table
        rowKey="rowId"
        footer={footer}
        columns={columns}
        pagination={false}
        dataSource={fields}
        rootClassName={styles.table}
      />
    </>
  );
};

export default Index;
