import React from "react";
import { useOutletContext } from "react-router-dom";
import { Select } from "antd";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { CustomFieldProps } from "../EstimateCustomFieldsContent";

const { Option } = Select;

const LabelCustomFieldView: React.FC<CustomFieldProps> = ({ field, name, form, labelValue, onBlur }) => {
  const { i18n, t } = useTranslation();
  const { smetaContainer } = useOutletContext<OutletContextType>();

  const onChange = (e: number[]) => {
    let result = "";

    e?.forEach((item, index) => {
      result += `${item}${index + 1 === e?.length ? "" : ","}`;
    });

    form?.setFieldValue(name, { custom_field_id: field?.id, value: result });
  };

  return (
    <Select
      className="tag"
      mode="multiple"
      onBlur={onBlur}
      suffixIcon={null}
      showSearch={false}
      onChange={onChange}
      placeholder={t("project.Tanlang")}
      maxTagCount="responsive"
      defaultValue={labelValue}
      popupMatchSelectWidth={false}
      notFoundContent={<TableEmpty />}
      getPopupContainer={() => smetaContainer}
    >
      {field?.custom_field_options?.map(item => (
        <Option key={item.id} value={item?.id}>
          {item?.name?.[i18n.language]}
        </Option>
      ))}
    </Select>
  );
};

export default LabelCustomFieldView;
