import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Select } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { PartyForm } from "modules/party/create";
import { useCurrencies } from "modules/party/view";

type Props = { form: UseFormReturn<PartyForm> } & (
  | {
      type: "initial";
    }
  | {
      type: "group";
      parentIndex: number;
    }
);

const Index: React.FC<Props> = props => {
  const { type, form } = props;
  const { currencies, isLoading } = useCurrencies();
  const { t } = useTranslation();

  const onChange = (e: number, option: any) => {
    const currency_symbol = option?.props?.name || "";

    if (type === "initial") {
      const oldValue = form.getValues("warehouse_products");

      form.setValue(
        "warehouse_products",
        oldValue?.map(item => ({ ...item, currency_id: e, currency_symbol }))
      );
    }

    if (props.type === "group") {
      const oldValue = form.getValues(`company_person_group.${props.parentIndex}`);

      form.setValue(`company_person_group.${props.parentIndex}`, {
        ...oldValue,
        products: [
          ...(oldValue.products?.map(product => ({
            ...product,
            currency_id: e,
            currency_symbol
          })) || [])
        ]
      });
    }
  };

  return (
    <Select
      loading={isLoading}
      onChange={onChange}
      placeholder={t("payment.Valyuta")}
      popupMatchSelectWidth={false}
      labelRender={() => t("payment.Valyuta")}
      suffixIcon={<ChevronDownIcon rotate width={18} />}
      options={currencies?.map(currency => ({
        key: currency?.id,
        value: currency?.id,
        label: currency?.symbol,
        props: {
          name: currency?.symbol
        }
      }))}
    />
  );
};

export default Index;
