import React, { ChangeEvent, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Form, Input, Select, Spin } from "antd";
import classNames from "classnames";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../../../hooks/redux";
import { productsTemplateActions } from "../../../../../../../store/reducers/productsTempleteReducer";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetCustomFieldSelect, useGetTableConfigs, useGetUnitSelect } from "../../../../../../app/service/queries";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { tableConfigKeys } from "../../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { CustomFieldSelectModel } from "../../../../../../app/utils/models/customFieldSelectModel";
import { ReqCustomFieldValueModel } from "../../../../../../app/utils/models/reqCustomFieldValueModel";
import FilterSearchIcon from "../../../../../assets/icons/FilterSearchIcon";
import { useCreateProduct } from "../../../../../service/mutations";
import { estimateTableDefaultData } from "../../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../../utils/helpers/estimateColumnsWidth";
import { TaskProductFormFieldsModel } from "../../../../../utils/models/taskProductFormFieldsModel";
import EstimateCustomField from "../../../estimate-custom-field/EstimateCustomField";

import ProductSelect from "./Select";

import styles from "./productAppend.module.scss";

type Props = {
  task_id: number;
};

interface ProductFormFieldsModel {
  task_id: number;
  product_id: number;
  unit_id?: number;
  amount: string;
  quantity: string;
  total_amount: string;
  custom_field_values: ReqCustomFieldValueModel[];
}

const { Item, useForm } = Form;
const { Option } = Select;

const ProductAppend: React.FC<Props> = ({ task_id }) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const { setVisibleTemplate, setTaskId } = productsTemplateActions;

  const createProduct = useCreateProduct();
  const { data: units, isLoading: isLoadingUnits } = useGetUnitSelect();
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const onOpenForm = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    dispatch(setTaskId(task_id));

    if (!isCreating) setIsCreating(true);
  };

  const onCloseForm = () => {
    setIsCreating(false);

    form.resetFields();

    setSearchProduct("");
  };

  const onOpenTemplate = () => {
    dispatch(
      setVisibleTemplate({
        visible: true,
        products: undefined
      })
    );

    onCloseForm();
  };

  const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const target = event.target as HTMLInputElement;
    const amount = parseLocaledString(target?.value);
    const quantity = parseLocaledString(form.getFieldValue("quantity"));

    form.setFieldValue("amount", localeFormatter(target?.value));

    form.setFieldValue("total_amount", localeFormatter(String((amount * quantity).toFixed(2))));
  };

  const onChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const quantity = parseLocaledString(target.value);
    const amount = parseLocaledString(form.getFieldValue("amount"));

    form.setFieldValue("quantity", localeFormatter(target.value));

    form.setFieldValue("total_amount", localeFormatter(String((amount * quantity).toFixed(2))));
  };

  const defaultColumnsRender = () => {
    const defaultColumns: ReactNode[] = [];

    tableConfigData?.column?.forEach(column => {
      if (column?.checked && column?.id === "quantity") {
        defaultColumns.push(
          <Item<TaskProductFormFieldsModel>
            name="quantity"
            rules={formRules()}
            className={classNames(styles.form__item, styles.amount)}
          >
            <Input placeholder={t("projects.Hajmi")} step="any" onChange={onChangeQuantity} />
          </Item>
        );
      }
      if (column?.checked && column?.id === "unit") {
        defaultColumns.push(
          <Item<TaskProductFormFieldsModel> name="unit_id" className={classNames(styles.form__item, styles.unit)}>
            <Select
              showSearch
              placeholder={t("projects.Birligi")}
              suffixIcon={null}
              popupMatchSelectWidth={false}
              loading={isLoadingUnits}
              filterOption={selectFilterOption}
              getPopupContainer={() => smetaContainer}
            >
              {units?.map(item => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item?.name[i18n.language]
                  }}
                >
                  {item.name[i18n.language]}
                </Option>
              ))}
            </Select>
          </Item>
        );
      }
      if (column?.checked && column?.id === "amount") {
        defaultColumns.push(
          <Item<TaskProductFormFieldsModel>
            name="amount"
            rules={formRules()}
            className={classNames(styles.form__item, styles.price)}
          >
            <Input placeholder={t("projects.Narxi")} onChange={onChangeAmount} />
          </Item>
        );
      }
    });

    return defaultColumns;
  };

  const customFieldsColumnRender = useCallback(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  const onFinish = (values: ProductFormFieldsModel) => {
    createProduct
      .mutateAsync({
        ...values,
        amount: parseLocaledString(values?.amount),
        total_amount: parseLocaledString(values?.total_amount),
        quantity: parseLocaledString(values?.quantity),
        task_id,
        product_ids: [values.product_id],
        custom_field_values: values?.custom_field_values?.filter(item => item?.value)
      })
      .then(() => onCloseForm());
  };

  const gridStyle = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth(tableConfigData)} 16.25rem`
  };

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        form.submit();

        form.validateFields({ validateOnly: true }).then(() => {
          form.submit();
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [form, isCreating]);

  return (
    <Spin spinning={createProduct.isLoading} indicator={LoadingIndicator}>
      <div className={styles.product_append}>
        <ConditionalRender if={isCreating}>
          <div
            ref={contentRef}
            className={classNames(styles.create, styles.task__body, styles.task__header, {
              [styles.active]: isCreating
            })}
          >
            <Form form={form} style={gridStyle} onFinish={onFinish} className={styles.form}>
              <div className={styles.resource__block}>
                <Item<TaskProductFormFieldsModel>
                  name="product_id"
                  rules={formRules()}
                  className={classNames(styles.form__item, styles.name)}
                >
                  <ProductSelect form={form} search={searchProduct} setSearch={setSearchProduct} />
                </Item>
                <div className={cx(styles.add_resource, "cursor-pointer")} onClick={onOpenTemplate}>
                  <FilterSearchIcon />
                </div>
              </div>
              {defaultColumnsRender()}
              {EstimateCustomField({
                locations: CustomFieldLocationEnum.TASK_PRODUCT,
                customFields: customFieldsColumnRender(),
                className: classNames(styles.form__item)
              })}
              <div className={styles.last_column}>
                <Item<TaskProductFormFieldsModel>
                  name="total_amount"
                  className={classNames(styles.form__item, styles.amount_quantity)}
                >
                  <Input placeholder={t("projects.Umumiy narx")} />
                </Item>
                <div className={classNames(styles.delete, styles.active)} onClick={onCloseForm}>
                  <span>
                    <DeleteIcon />
                  </span>
                </div>
              </div>
              <Button className={styles.submit} htmlType="submit" />
            </Form>
          </div>
        </ConditionalRender>
        <ConditionalRender if={!isCreating}>
          <div
            onClick={onOpenForm}
            className={classNames(styles.button, {
              [styles.button__passive]: isCreating
            })}
          >
            <div>+</div>
            <span>{t("project.Resurs qo'shish")}</span>
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default ProductAppend;
