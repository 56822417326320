import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Popover, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import DateStatusWithPercent from "features/app/components/date-status-with-percent/DateStatusWithPercent";
import OrderSortBy from "features/app/components/order-sort-by/OrderSortBy";
import { PageKeys, StatusEnums } from "features/app/components/statuses/statusEnums";
import Statuses from "features/app/components/statuses/Statuses";
import TemplateDownload from "features/app/components/template-download/TemplateDownload";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import PrinterIcon from "features/counterparts/assets/icons/PrinterIcon";
import { counterpartsQueryKeys } from "features/counterparts/utils/constants/counterpartsQueryKeys";
import OrderPrint from "features/payment/components/bottom/order-print/OrderPrint";
import PartTableSettings from "features/payment/components/bottom/tables/order/part-table-settings/PartTableSettings";
import { useGetOnePaymentOrder } from "features/payment/service/mutation";
import { paymentOrderTableDefaultData } from "features/supply/utils/constants/paymentOrderTableDefaultData";
import { OneOrderModel } from "features/warehouse/utils/models/oneOrderModel";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useQueryParams } from "hooks/useQueryParams";

import { chatActions } from "store/reducers/chatReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "../../../../../../app/components/not-image/NotUserImage";
import Pagination from "../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { PaymentOrderModel } from "../../../../../../payment/utils/models/paymentOrderModel";
import { useGetOrderView } from "../../../../../service/queries";

import styles from "./orderTable.module.scss";

type Props = {
  defaultTabKey?: string;
};
interface IPrintPartyDataModel {
  id: number;
  data: OneOrderModel[];
  oneData: PaymentOrderModel | undefined;
}

const OrderTable: React.FC<Props> = ({ defaultTabKey }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { severalSearchParams } = useQueryParams();
  const { data: counterpartOrders, isLoading } = useGetOrderView(defaultTabKey === PaymentTabKeys.ORDER);
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const [oneOrderData, setOneOrderData] = useState<IPrintPartyDataModel>({
    id: 0,
    data: [],
    oneData: undefined
  });
  const { setOrderView } = paymentReducerActions;

  const onDetailOrder = (record: PaymentOrderModel) => ({
    onClick: () => {
      dispatch(
        setOrderView({
          orderId: record?.id,
          visible: true
        })
      );
    }
  });
  const emptyFunc = () => {};

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `P-${oneOrderData?.id}`,
    onBeforePrint: emptyFunc,
    onAfterPrint: () => {
      setOneOrderData({
        id: 0,
        data: [],
        oneData: undefined
      });
    },
    removeAfterPrint: true
  });
  const { setVisible } = chatActions;
  const { setPartyModal } = supplyActions;
  const getOnePaymentOrder = useGetOnePaymentOrder();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PAYMENT_ORDER_CONFIG, true);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.PAYMENT_ORDER_CONFIG,
    paymentOrderTableDefaultData
  );

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const onEditParty = (record: PaymentOrderModel) => {
    dispatch(
      setPartyModal({
        visible: true,
        party_id: record?.id,
        isView: false,
        isEditing: true
      })
    );
  };

  const onPrintParty = (record: PaymentOrderModel) => () => {
    getOnePaymentOrder.mutateAsync(record?.id).then(data => {
      setOneOrderData({
        data,
        id: record?.id,
        oneData: record
      });

      setTimeout(() => {
        handlePrint();
      }, 500);
    });
  };

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const actionItems = (record: PaymentOrderModel) => [
    {
      key: "1",
      label: <UnreadMessageAction count={record?.unread_message_count} />,
      className: styles.dropdown_item,
      onClick: () => {
        dispatch(
          setVisible({
            visible: true,
            objectId: record?.id,
            type: "orders",
            dataKeys: [counterpartsQueryKeys.ORDER, severalSearchParams(queryParamsKeys.STATISTICS_TYPE)],
            record
          })
        );
      },
      isView: true
    },
    {
      key: "2",
      label: (
        <>
          <EditIcon />
          <span>{t("Counterparts.Tahrirlash")}</span>
        </>
      ),
      className: styles.dropdown_item,
      onClick: () => {
        onEditParty(record);
      },
      isView:
        record?.status === StatusEnums.OPEN ||
        record?.status === StatusEnums.ORDERED ||
        record?.status === StatusEnums.PENDING
    },
    {
      key: "3",
      label: (
        <>
          <PrinterIcon />
          <span>{t("Counterparts.Chop etish")}</span>
        </>
      ),
      className: styles.dropdown_item,
      onClick: onPrintParty(record),
      isView: true
    },
    {
      key: "4",
      label: <TemplateDownload object_id={record.id} location={CustomFieldLocationEnum.ORDER} styles={styles} />,
      isView: true
    }
  ];

  const paymentPopoverTitle = (amount: number, debtAmount: number, currency: string) => (
    <div className={styles.popover__payment}>
      <div className={styles.popover__item}>
        <p>{t("Counterparts.To’langan summa")}</p>
        <p>
          {(amount - debtAmount)?.toLocaleString(RU)} {currency}
        </p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("Counterparts.Qoldiq")}</p>
        <p>
          {debtAmount?.toLocaleString(RU)} {currency}
        </p>
      </div>
    </div>
  );

  const columns: ColumnsType<PaymentOrderModel> = [
    {
      title: `${t("Counterparts.Partiya")}`,
      render: (record: PaymentOrderModel) => <span className={styles.bold}>P-{record?.id}</span>,
      width: 200,
      className: "counterparts-detailed-parts"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_ORDERED_DATE} title="Buyurtma sanasi" />,
      render: (record: PaymentOrderModel) => record?.ordered_date,
      key: "new_ordered_date",
      width: 150
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title="Yetkazilish sanasi" />,
      render: (record: PaymentOrderModel) => record?.delivery_date,
      key: "delivery_date",
      width: 150
    },

    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AGENT_FULL_NAME} title={t("Counterparts.Vositachi")} />,
      render: (record: PaymentOrderModel) => record?.agent?.full_name,
      width: 250,
      key: "agent.full_name",
      className: "counterparts-deltailed-agent"
    },
    {
      title: t("Supply.Kontragentlari"),
      render: (record: PaymentOrderModel) => (
        <span>
          {record?.company_persons && record?.company_persons?.length > 0
            ? record?.company_persons?.map(person => person?.name).join(", ") || "-"
            : "-"}
        </span>
      ),
      key: "payment_counterparts",
      width: 400,
      className: "counterparts-parts-counterparts"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PERCENT} title={t("Counterparts.Jarayon")} />,
      render: (record: PaymentOrderModel) => (record?.percent ? `${record?.percent} %` : "-"),
      width: 100,
      key: "percent",
      className: "counterparts-order-percent"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AMOUNT} title={t("Counterparts.Umumiy summa")} />,
      render: (record: PaymentOrderModel) => (
        <span className={styles.bold}>
          {record?.total_amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
      width: 220,
      key: "amount",
      className: "counterparts-detailed-amount"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PAYMENT_DATE} title={t("Counterparts.To’lov sanasi")} />,
      render: (record: PaymentOrderModel) => (
        <DateStatusWithPercent date={record.payment_date!} percent={record?.payment_percent} />
      ),
      key: "new_payment_date",
      width: 150,
      className: "counterparts-order-payment_date"
    },
    {
      title: `${t("Counterparts.Holati")}`,
      render: (record: PaymentOrderModel) => <Statuses statusKey={record?.status} pageKey={PageKeys.PARTY} />,
      width: 150,
      key: "status",
      className: "counterparts-status"
    },
    {
      title: (
        <OrderSortBy queryParamKey={queryParamsKeys.SORT_PAYMENT_PERCENT} title={t("Counterparts.To'lov holati")} />
      ),
      render: (record: PaymentOrderModel) => (
        <Popover
          title={paymentPopoverTitle(record?.total_amount, record?.debt_amount, record?.currency?.symbol)}
          overlayClassName={styles.popover}
        >
          <span>{record?.payment_percent} %</span>
        </Popover>
      ),
      width: 250,
      key: "payment_percent",
      className: "counterparts-detailed-payment_percent"
    },
    {
      title: `${t("Counterparts.Xodim")}`,
      render: (record: PaymentOrderModel) => (
        <div className={styles.avatar}>
          <Tooltip title={record?.creator?.full_name}>
            {record?.agent?.image ? (
              <img src={record?.agent?.image} alt={record?.agent?.full_name} />
            ) : (
              <NotUserImage name={record?.agent?.full_name} isTooltip />
            )}
          </Tooltip>
        </div>
      ),
      width: 150,
      className: "counterparts-detailed-creator"
    }
  ];

  const [tableColumns, setColumns] = useState<ColumnsType<PaymentOrderModel>>([]);

  const saveColumns = () => {
    const columnsToSave = tableColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.PAYMENT_ORDER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentOrderModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: <PartTableSettings classNames="settings_table" isNeedSize />,
          render: record => (
            <Dropdown
              menu={{
                items: actionItems(record).filter(item => item?.isView)
              }}
              trigger={["click"]}
              placement="bottom"
              dropdownRender={dropdownRender}
              overlayStyle={{ zIndex: 1000 }}
            >
              <div
                className={classNames("d_f ai_c jc_c c_p", {
                  hasBadge: !!record?.unread_message_count
                })}
                style={{ width: 24 }}
                onClick={e => e.stopPropagation()}
              >
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          ),
          width: 70,
          fixed: "right",
          className: "counterparts-detailed-actions"
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return (
    <div className={styles.order_table}>
      <Table<PaymentOrderModel>
        onChangeColumns={onChangeColumns}
        columns={tableColumns}
        pagination={false}
        dataSource={counterpartOrders?.data}
        rowKey={record => record.id}
        onRow={onDetailOrder}
        rowClassName={() => styles.row}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: counterpartOrders?.total
          }}
        />
      </div>
      <OrderPrint ref={printComponentRef} data={oneOrderData} />
    </div>
  );
};

export default OrderTable;
