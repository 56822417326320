import React, { useEffect, useRef, useState } from "react";
import { Button, Spin } from "antd";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import { useTranslation } from "react-i18next";

import { colors } from "modules/common";

import styles from "./text-editable.module.scss";

interface EditableProps {
  text: string;
  title: string;
  fields: { [key: string]: string }[];
  updateTemplate: (value: string) => void;
  isLoading: boolean;
  index?: number;
}

const TextEditable: React.FC<EditableProps> = ({ text, title, fields, updateTemplate, isLoading, index }) => {
  const [value, setValue] = useState(text);
  const textReplace = text.replace(
    /&(.*?)&/g,
    `<span contenteditable='false' class='editable_span' key='&$1&'>
      &$1&
      <span class="editable_span_delete" onClick="this.parentElement.remove()">
        &times;
      </span>
    </span>`
  );
  const editable = useRef<HTMLDivElement>(null);
  const valueRef = useRef(value);
  const positionRef = useRef(value?.length);
  const { t, i18n } = useTranslation();

  const deleteItem = (index: number) => {
    const newValue = valueRef.current.split("&");
    let newValueString = "";

    for (let i = 0; i < newValue.length; i++) {
      if (i !== index) {
        const finded = fields.find(item => item.key === `&${newValue[i]}&`);

        if (finded) {
          newValueString += `&${newValue[i]}&`;
        } else {
          newValueString += newValue[i];
        }
      }
    }
    valueRef.current = newValueString;
    setValue(newValueString);
  };

  useEffect(() => {
    const elements = document.getElementsByClassName("editable_span_delete");

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", e => {
        // @ts-ignore
        deleteItem(Number(e.target.attributes[0].value));
      });
    }

    return () => {
      const elements = document.getElementsByClassName("editable_span_delete");

      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", () => {});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (valueString: string) => {
    const firstValue = valueRef.current.slice(0, positionRef.current);
    const secondValue = valueRef.current.slice(positionRef.current);
    const newValue = `${firstValue} ${valueString} ${secondValue}`;

    positionRef.current = positionRef.current + valueString?.length + 4;
    valueRef.current = newValue;
    setValue(newValue);
  };

  async function pasteHtmlAtCaret(html: any) {
    let range: Range;
    const sel = await window.getSelection();
    const focusNode: any = document.querySelector(`.editor`);

    await focusNode.focus();
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      const el = document.createElement("div");

      el.innerHTML = `<span contenteditable="false" class='editable_span' key='${html}'>
          ${html}
          <span class="editable_span_delete" onClick="this.parentElement.remove()">
            &times;
          </span>
        </span>`;
      const frag = document.createDocumentFragment();
      let node;
      let lastNode;

      // eslint-disable-next-line no-cond-assign
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }

  const handleSave = () => {
    if (index !== undefined) {
      const focusNode: any = document.querySelector(`.editor${index}`);
      const textArr: any[] = [];

      focusNode?.childNodes.forEach((item: any) => {
        if (item.nodeName === "#text") {
          textArr.push(item.data);
        } else {
          textArr.push(item.getAttribute("key"));
        }
      });
      updateTemplate(textArr.join(""));
    } else {
      const stringWithoutMultiplicationSymbol = editable.current?.innerText?.replace(/ ×/g, "");

      updateTemplate(stringWithoutMultiplicationSymbol || text);
    }
  };

  return (
    <div className="flex w-full flex-col items-center gap-2">
      <div
        className={`${styles.editable} editor`}
        contentEditable
        dangerouslySetInnerHTML={{ __html: textReplace }}
        ref={editable}
      />
      <div className={styles.save}>
        <p className={styles.aboutHome}>
          {fields.map((field, i) => (
            <span
              key={field[i18n.language] + i}
              onClick={() => {
                handleChange(field[i18n.language]);
                pasteHtmlAtCaret(field[i18n.language]);
              }}
            >
              {field[i18n.language]}
            </span>
          ))}
        </p>
        <Spin spinning={isLoading}>
          <Button className="ml-2" onClick={handleSave}>
            <CheckIcon color={colors.SUCCESS_500} />
          </Button>
        </Spin>
      </div>
    </div>
  );
};

export default TextEditable;
