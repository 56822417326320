import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Dropdown, Form, Input, Popover } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { ReqCustomFieldValueModel } from "features/app/utils/models/reqCustomFieldValueModel";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useGetSectionsSelect } from "features/projects/service/queries";
import { SectionBodyModel } from "features/projects/utils/models/sectionBodyModel";
import { SectionSelectModel } from "features/projects/utils/models/sectionSelectModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { colors, ErrorRes, isEmptyArr, SuccessRes } from "modules/common";
import { TargetType } from "modules/dashboard";

import styles from "./sectionAppend.module.scss";

type Props = {
  creating: boolean;
  setCreating: Dispatch<SetStateAction<boolean>>;
  createSection: UseMutationResult<SuccessRes, ErrorRes, SectionBodyModel, unknown>;
  customField?: {
    value: number;
    custom_field_id: number;
  };
  copySection: UseMutationResult<
    SuccessRes,
    ErrorRes,
    { id: number; project_id: number; custom_field_values?: ReqCustomFieldValueModel[] },
    unknown
  >;
};

const SectionName: React.FC<Props> = ({ setCreating, creating: enabled, copySection, createSection, customField }) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [time, setTime] = useState<TimeoutModel>();
  const { data, fetchNextPage } = useGetSectionsSelect({ enabled, search });
  const { t } = useTranslation();

  const sections = useMemo(() => {
    let result: SectionSelectModel[] = [];

    data?.pages?.forEach(item => {
      result = [...result, ...(item?.data || [])];
    });

    setOpen(!isEmptyArr(result));

    return result;
  }, [data?.pages, search]);

  const onSearch = (value: string) => {
    clearTimeout(time);

    form.setFieldValue("name", value);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 300)
    );
  };

  const onBlur = () => {
    setCreating(false);
    setOpen(false);
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 2) {
      fetchNextPage().then();
    }
  };

  const onFinish = ({ name }: { name: string }) => {
    createSection
      .mutateAsync({
        name,
        project_id: Number(projectId),
        custom_field_values: [
          {
            custom_field_id: customField?.custom_field_id || 0,
            value: customField?.value
          }
        ]
      })
      .then(onBlur);
  };

  const onClickTask = (id: number) => () => {
    copySection
      .mutateAsync({
        id,
        project_id: Number(projectId),
        custom_field_values: [
          {
            custom_field_id: customField?.custom_field_id || 0,
            value: customField?.value
          }
        ]
      })
      .then(onBlur);
  };

  return (
    <Form form={form} onFinish={onFinish} className="w-96">
      <Dropdown
        open={open}
        arrow={false}
        rootClassName={styles.sections}
        onOpenChange={open => setOpen(open)}
        getPopupContainer={() => smetaContainer}
        dropdownRender={originNode => (
          <div
            onScrollCapture={onPopupScroll}
            className="max-h-96 w-max overflow-y-auto rounded-lg border border-solid border-gray-300 bg-white"
          >
            {originNode}
          </div>
        )}
        menu={{
          items: sections?.map(section => {
            const customFields = [
              ...(section?.custom_field_values?.filter(field => field?.custom_field?.type === "select") || [])
            ];
            const endCustomFields = customFields?.slice(2);

            return {
              key: `${section?.id}-${section?.name}`,
              value: section?.name,
              props: {
                id: section?.id,
                project_id: section?.project_id
              },
              title: String(section?.id),
              label: (
                <div className="flex flex-col">
                  <div className="flex text-sm font-medium text-gray-900">
                    {section.place}. {section.name}
                  </div>
                  <div className="flex items-center justify-between gap-8">
                    <span className="text-sm font-normal text-gray-600">{section?.project?.name}</span>
                    <div className="flex items-center gap-1">
                      {customFields?.slice(0, 2)?.map(field => (
                        <div
                          key={field?.id}
                          className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                          style={{ background: `${field?.custom_field_option?.color}20` }}
                        >
                          <div
                            className="h-2.5 w-2.5 rounded-full"
                            style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                          />
                          <span
                            className="text-xs font-medium"
                            style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                          >
                            {field?.custom_field_option?.name?.[i18n.language]}
                          </span>
                        </div>
                      ))}
                      <ConditionalRender if={!isEmptyArr(endCustomFields)}>
                        <Popover
                          zIndex={11119}
                          placement="top"
                          arrow={false}
                          content={
                            <div className="flex max-w-80 flex-wrap gap-2">
                              {endCustomFields?.map(field => (
                                <div
                                  key={field?.id}
                                  className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                                  style={{ background: `${field?.custom_field_option?.color}20` }}
                                >
                                  <div
                                    className="h-2.5 w-2.5 rounded-full"
                                    style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                                  />
                                  <span
                                    className="text-xs font-medium"
                                    style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                                  >
                                    {field?.custom_field_option?.name?.[i18n.language]}
                                  </span>
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <div className="flex items-center gap-2 rounded-xl bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                            + {endCustomFields?.length}
                          </div>
                        </Popover>
                      </ConditionalRender>
                    </div>
                  </div>
                </div>
              ),
              onClick: onClickTask(Number(section?.id))
            };
          })
        }}
      >
        <Form.Item name="name" className="m-0">
          <Input
            autoFocus
            placeholder={t("project.Nomi")}
            autoComplete="off"
            rootClassName={styles.new_section_name}
            onChange={e => onSearch(e.target.value)}
          />
        </Form.Item>
      </Dropdown>
    </Form>
  );
};

export default SectionName;
