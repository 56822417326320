import React, { useEffect, useMemo, useState } from "react";
import { Form, Popover, Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { FormInstance } from "antd/lib";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { useWorkSelect } from "features/projects/service/queries";
import { TaskSelectModel } from "features/projects/utils/models/taskSelectModel";
import { useTranslation } from "react-i18next";

import { colors, isEmptyArr } from "modules/common";
import { TargetType } from "modules/dashboard";

import { ConditionalRender, LoadingIndicator, SelectSuffixIcon } from "components";

const { Item } = Form;
const { Option } = Select;

type Props = {
  name: string;
  label?: string;
  formItemClassName?: string;
  placeholder?: string;
  project_id?: number;
  disabled?: boolean;
  cominSearch?: string;
  page?: number;
  filters?: any;
  record?: TaskSelectModel;
  form?: FormInstance<any>;
  custom_field_options?: number[];
};

const WorkSelect: React.FC<Props> = ({
  name,
  formItemClassName,
  label,
  placeholder,
  project_id,
  disabled,
  cominSearch,
  page,
  filters,
  record,
  form,
  custom_field_options
}) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [time, setTime] = useState<TimeoutModel>();

  const { data, fetchNextPage, isFetching } = useWorkSelect({
    search: cominSearch ?? search,
    project_id,
    page,
    filters,
    section_custom_field_option_ids: custom_field_options
  });

  const tasks = useMemo(() => {
    let result: TaskSelectModel[] = [];

    // Flatten and gather all tasks from the data
    data?.pages?.forEach(item => {
      result = [...result, ...(item?.data || [])];
    });

    // Add the record if it's not already present
    if (record && !result.some(task => task.id === record.id)) {
      result = [record, ...result];
    }

    return result;
  }, [data?.pages, search, record]);

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 2) {
      fetchNextPage().then();
    }
  };

  const onSearch = (value: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 300)
    );
  };

  const onChange: SelectProps["onChange"] = (_, option) => {
    const arg = { ...option } as DefaultOptionType;

    if (form) {
      form.setFieldsValue({
        unit_name: arg.props?.unitName
      });
    }
  };

  useEffect(() => {
    if (project_id) {
      setSearch("");
    }
  }, [project_id]);

  return (
    <div>
      <Spin spinning={isFetching} indicator={LoadingIndicator}>
        <Item name={name} label={label ?? t("work.Ish")} className={formItemClassName}>
          <Select
            showSearch
            allowClear
            onChange={onChange}
            disabled={disabled}
            onSearch={onSearch}
            loading={isFetching}
            filterOption={false}
            onPopupScroll={onPopupScroll}
            suffixIcon={<SelectSuffixIcon />}
            placeholder={placeholder ?? t("work.Tanlang")}
            labelRender={props => (
              <div className="flex truncate text-sm font-medium text-gray-900">
                {(props as unknown as { title: { task_name: string; task_place: number } })?.title?.task_place}{" "}
                {(props as unknown as { title: { task_name: string; task_place: number } })?.title?.task_name}
              </div>
            )}
          >
            {tasks?.map(task => {
              const customFields = [
                ...(task?.section?.custom_field_values?.filter(field => field?.custom_field?.type === "select") || [])
              ];
              const endCustomFields = customFields?.slice(2);

              return (
                <Option
                  key={task?.id}
                  value={task?.id}
                  title={{
                    task_place: task?.place,
                    task_name: task?.name
                  }}
                  props={{
                    unitName: task?.unit?.symbol?.[i18n.language]
                  }}
                >
                  <div className="flex w-full flex-col">
                    <div className="flex truncate text-sm font-medium text-gray-900">
                      {task.place} {task.name}
                    </div>
                    <div className="flex items-center justify-between gap-8">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1 text-sm font-normal text-gray-500">
                          <span>{task?.section?.place}.</span>
                          <span>{task?.section?.name}</span>
                        </div>
                        <div className="h-1.5 w-1.5 rounded-full bg-gray-500" />
                        <span className="text-sm font-normal text-gray-500">{task?.section?.project?.name}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        {customFields?.slice(0, 2)?.map(field => (
                          <div
                            key={field?.id}
                            className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                            style={{ background: `${field?.custom_field_option?.color}20` }}
                          >
                            <div
                              className="h-2.5 w-2.5 rounded-full"
                              style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                            />
                            <span
                              className="text-xs font-medium"
                              style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                            >
                              {field?.custom_field_option?.name?.[i18n.language]}
                            </span>
                          </div>
                        ))}
                        <ConditionalRender if={!isEmptyArr(endCustomFields)}>
                          <Popover
                            zIndex={11119}
                            placement="top"
                            arrow={false}
                            content={
                              <div className="flex max-w-80 flex-wrap gap-2">
                                {endCustomFields?.map(field => (
                                  <div
                                    key={field?.id}
                                    className="flex items-center gap-2 rounded-xl px-2.5 py-0.5"
                                    style={{ background: `${field?.custom_field_option?.color}20` }}
                                  >
                                    <div
                                      className="h-2.5 w-2.5 rounded-full"
                                      style={{ background: field?.custom_field_option?.color || colors.GRAY_800 }}
                                    />
                                    <span
                                      className="text-xs font-medium"
                                      style={{ color: field?.custom_field_option?.color || colors.GRAY_800 }}
                                    >
                                      {field?.custom_field_option?.name?.[i18n.language]}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            }
                          >
                            <div className="flex items-center gap-2 rounded-xl bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                              + {endCustomFields?.length}
                            </div>
                          </Popover>
                        </ConditionalRender>
                      </div>
                    </div>
                  </div>
                </Option>
              );
            })}
          </Select>
        </Item>
      </Spin>
    </div>
  );
};

export default WorkSelect;
