import React from "react";
import { Form, FormInstance } from "antd";
import { FileListType } from "features/app/utils/models/fileListModel";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";

import { ResourceSpendingDrawerEnums } from "modules/works";

import Left from "./left";
import Right from "./right";

type Props = {
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  fileList: FileListType[];
  setFileList: React.Dispatch<React.SetStateAction<FileListType[]>>;
  currentTab: ResourceSpendingDrawerEnums;
  setCurrentTab: React.Dispatch<React.SetStateAction<ResourceSpendingDrawerEnums>>;
  confirmation_users: UserSelectModel[];
  setConfirmationUsers: React.Dispatch<React.SetStateAction<UserSelectModel[]>>;
};

const Content: React.FC<Props> = ({
  form,
  onFinish,
  fileList,
  setFileList,
  currentTab,
  setCurrentTab,
  confirmation_users,
  setConfirmationUsers
}) => (
  <Form form={form} className="flex h-full" layout="vertical" onFinish={onFinish}>
    <Left form={form} currentTab={currentTab} setCurrentTab={setCurrentTab} />
    <Right
      fileList={fileList}
      form={form}
      setFileList={setFileList}
      confirmation_users={confirmation_users}
      setConfirmationUsers={setConfirmationUsers}
    />
  </Form>
);

export default Content;
