import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Form, FormInstance, Popover } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import ArrowNarrowIcon from "../../../../../app/assets/icons/ArrowNarrowIcon";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import ModalCustomFields from "../../../../../app/components/modal-content-custom-fields/ModalContentCustomFields";
import { useGetCustomFieldSelect } from "../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { queryKeys } from "../../../../../app/utils/constants/queryKeys";
import { UnitModel } from "../../../../../app/utils/constants/unitModel";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { ProjectModal } from "../../../../../projects/components/project-modal/ProjectModal";
import SettingsWarehouseModal from "../../../../../settings/components/warehouse/modal/SettingsWarehouseModal";
import CodepenIcon from "../../../../../warehouse/assets/icons/CodepenIcon";
import { OrderFormProductModel } from "../../../../utils/models/orderModalFormModel";

import styles from "./orderModalRight.module.scss";

type Props = {
  form: FormInstance;
};

const { Item, useWatch } = Form;

const OrderModalRight: React.FC<Props> = ({ form }) => {
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { setOrderEstimate } = supplyActions;
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT]);

  const { isEdit, visible } = useAppSelector(state => state.supplyReducer.orderModal);

  const products = useWatch("task_products", form) as OrderFormProductModel[];
  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);

  const allUnitText = useMemo(() => {
    const text: { quantity?: number; unit: string }[] = [];

    products?.forEach(item => {
      const findUnit = unitData?.find(unit => unit?.id === item?.unit_id);

      text.push({
        quantity: item?.quantity ? parseLocaledString(item?.quantity) : 0,
        unit: findUnit?.name[i18n.language] ?? "ta"
      });
    });

    return (
      <div className={styles.units}>
        {text?.map((item, index) => (
          <div className={styles.units__item} key={index}>
            <p>{item?.quantity ?? 0}</p>
            <span>{item?.unit}</span>
            {index + 1 !== text?.length && <p>,</p>}
          </div>
        ))}
      </div>
    );
  }, [products, unitData, i18n.language]);

  const onOpenEstimate = () => {
    dispatch(
      setOrderEstimate({
        visible: true
      })
    );
  };

  const totalElemClass = `${styles.total} ${isEmptyArr(products) ? styles.total__disabled : styles.total__active}`;

  return (
    <div className={styles.right}>
      <div className={styles.right__top}>
        <Item name="id" className="hidden" />
        <Item label={t("order.Kerak bo'lish sanasi")} name="delivery_date" initialValue={dayjs()}>
          <DatePicker
            placeholder={t("payment.Tanlang")}
            format={dayjsFormats.DATE}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        {!isEmptyArr(customFields) && (
          <ModalCustomFields form={form} isView={isEdit} visible={visible} customFields={customFields} />
        )}
      </div>
      <div className={styles.right__bottom}>
        {!isEdit && (
          <div className={styles.card} onClick={onOpenEstimate}>
            <div className={styles.card__left}>
              <CodepenIcon />
            </div>
            <div className={styles.card__right}>
              <h4>{t("order.Smeta bo’yicha keltirilishi kerak resurslar")}</h4>
              <p>
                {t(
                  "payment.Ushbu oyna smeta bo’yicha keltirish vaqti kelgan resurslar bilan tanishib ularni buyurtma qilish uchun"
                )}
              </p>
            </div>
            <div className={styles.card__icon}>
              <span>
                <ArrowNarrowIcon rotate={180} />
              </span>
            </div>
          </div>
        )}
        <div className={totalElemClass}>
          <div className={styles.total__item}>
            <h4>{t("payment.Jami mahsulotlar")}:</h4>
            <Popover title={allUnitText} placement="left">
              <p>{products?.length ?? 0} birlik</p>
            </Popover>
          </div>
        </div>
      </div>
      <ProjectModal />
      <SettingsWarehouseModal />
    </div>
  );
};

export default OrderModalRight;
