import React from "react";
import { UseFormReturn } from "react-hook-form";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { getPopupContainer, PartyForm } from "modules/party/create";
import { useUnits } from "modules/party/view";

import { Select, TooltipShortName } from "components";

type Props = {
  index: number;
  isUpdate?: boolean;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index, isUpdate }) => {
  const { units, isLoading } = useUnits();
  const { t } = useTranslation()

  const onChange = (e: number, option: any) => {
    form.setValue(`warehouse_products.${index}.unit_id`, e);
    form.setValue(`warehouse_products.${index}.unit_name`, option.props?.name);
  };

  return (
    <Select
      withoutMessage
      suffixIcon={null}
      loading={isLoading}
      onChange={onChange}
      placeholder={t("payment.Birligi")}
      control={form.control}
      wrapperClassName="h-full"
      popupMatchSelectWidth={false}
      errorClassName="party-create-error"
      name={`warehouse_products.${index}.unit_id`}
      getPopupContainer={getPopupContainer(isUpdate ? "party-left-content-update" : "party-left-content")}
      options={units?.map(unit => ({
        key: unit?.id,
        value: unit?.id,
        label: (
          <TooltipShortName
            length={12}
            arrow={false}
            title={unit?.symbol[i18n.language]}
            getPopupContainer={getPopupContainer("party-left-content")}
          />
        ),
        props: {
          name: unit?.symbol[i18n.language]
        }
      }))}
    />
  );
};

export default Index;
