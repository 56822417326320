import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Form, FormInstance, Popover, Select as AntSelect, Spin } from "antd";
import classNames from "classnames";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "../../../../../../app/components/select-not-content/SelectNotContent";
import { useGetUnitSelect } from "../../../../../../app/service/queries";
import { colors } from "../../../../../../app/utils/constants/colors";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../../app/utils/helpers/sliceText";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import { TimeoutModel } from "../../../../../../app/utils/models/TimeoutModel";
import { useGetProductsSelect } from "../../../../../../supply/service/queries";

import CreateProduct from "./create-product";

import styles from "./productAppend.module.scss";

const { Option } = AntSelect;

type Props = {
  form: FormInstance;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const Select: React.FC<Props> = ({ form, search, setSearch }) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const dispatch = useDispatch();
  const { setCreateProduct } = projectsReducerActions;
  const [time, setTime] = useState<TimeoutModel>();
  const { data: products, fetchNextPage, isLoading: isLoadingProduct } = useGetProductsSelect(search);
  const { data: units } = useGetUnitSelect();
  const productId = Form.useWatch("product_id", form);
  const { t } = useTranslation();

  const onSearchProduct = (value: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 300)
    );
  };

  const onSelectProduct = (value: number, { props }: { props: { unit_id: number } }) => {
    const isSomeUnit = units?.some(item => item?.id === props.unit_id);

    form.setFieldValue("product_id", value);
    form.setFieldValue("unit_id", isSomeUnit ? props.unit_id : undefined);
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: colors.ERROR_500,
            color: colors.ERROR_500
          }}
        >
          --
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("projects.Resurs nomi")}</div>
    </div>
  );

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();

    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 2) {
      fetchNextPage().then();
    }
  };

  const generateData = useMemo(() => {
    let result: ProductSelectModel[] = [];

    products?.pages?.forEach(page => {
      result = [...result, ...(page?.data || [])];
    });

    return result;
  }, [products?.pages]);

  const onAddProduct = () => {
    dispatch(
      setCreateProduct({
        visible: true,
        name: search
      })
    );
  };

  const onAfterCreateProduct = (data: ProductSelectModel) => {
    form.setFieldsValue({
      product_id: data?.id,
      unit_id: data?.unit.id
    });
  };

  return (
    <>
      <AntSelect
        autoFocus
        showSearch
        suffixIcon={null}
        value={productId}
        onSearch={onSearchProduct}
        onSelect={onSelectProduct}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        className={classNames("product_select", styles.select)}
        getPopupContainer={() => smetaContainer}
        notFoundContent={
          <Spin spinning={isLoadingProduct} indicator={LoadingIndicator}>
            <SelectNotContent title="Mahsulot" action={onAddProduct} />
          </Spin>
        }
      >
        {generateData?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language],
              unit_id: item?.unit?.id
            }}
          >
            <div className={styles.product}>
              <div className="resource">
                <div
                  style={{
                    borderColor: item?.resource?.color ?? colors.ERROR_500,
                    color: item?.resource?.color ?? colors.ERROR_500
                  }}
                >
                  {item.resource?.symbol[i18n.language]}
                </div>
              </div>
              <div className={styles.product__name}>
                {item.name[i18n.language]?.length > 20 ? (
                  <Popover title={item.name[i18n.language]} zIndex={9999999}>
                    {sliceText(item.name[i18n.language], 20)}
                  </Popover>
                ) : (
                  sliceText(item.name[i18n.language], 20)
                )}
              </div>
            </div>
          </Option>
        ))}
      </AntSelect>
      <CreateProduct afterFunc={onAfterCreateProduct} />
    </>
  );
};

export default Select;
