export function parseToCrumbName(name?: string, isParse?: boolean) {
  if (name) {
    if (isParse)
      return decodeURI(
        name
          .split("_")
          .join(" ")
          .replace(/[^\p{L}0-9\s]/gu, "") // Supports all alphabets
      );
    return decodeURI(
      name
        .split(" ")
        .join("_")
        .replace(/[^\p{L}0-9\s]/gu, "") // Supports all alphabets
    );
  }
  return name;
}
