import React from "react";
import { UseFormReturn } from "react-hook-form";
import ProjectSelectWithHookForm from "features/app/components/project-select-with-hook-form/project-select-with-hook-form";
import { useTranslation } from "react-i18next";

import { useWidget, WidgetSettingsForm, widgetStaticData } from "modules/dashboard";

import SelectSuffixIcon from "../../../../features/app/assets/icons/SelectSuffixIcon";

import styles from "./settings.module.scss";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
};

const Project: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const { key } = useWidget();
  const { settings } = widgetStaticData[key];
  const { isNotMultipleProjectSelect } = settings;

  return (
    <ProjectSelectWithHookForm
      form={form}
      label={t("global.Loyiha")}
      placeholder={t("global.Loyiha")}
      suffixIcon={<SelectSuffixIcon />}
      multiple={!isNotMultipleProjectSelect}
      rootClassName={styles.multiple_select}
      maxTagCount={!isNotMultipleProjectSelect ? 2 : undefined}
      name={!isNotMultipleProjectSelect ? "project_ids" : "project_id"}
    />
  );
};

export default Project;
