import React from "react";
import { Tooltip } from "antd";
import { WorkModel } from "features/projects/utils/models/workModel";
import { useTranslation } from "react-i18next";

import { cx, localeFormatter, sliceText } from "modules/common";

type Props = {
  record: WorkModel;
};

const Percent: React.FC<Props> = ({ record }) => {
  const { t, i18n } = useTranslation();

  if (!record) return null;

  // Tooltip content
  const percentTooltipContent = () => (
    <div className="flex h-[96px] w-[184px] flex-col justify-center gap-2 rounded-lg px-3 py-2">
      <div className="flex flex-col">
        <span className="text-[10px] font-medium text-gray-200">{t("works.Plan")}</span>
        <div className="flex w-full items-center gap-2">
          <div className="h-[18px] w-1 bg-white" />
          <span className="text-sm font-semibold text-white">{localeFormatter(String(record?.quantity))} </span>
          <span className="text-sm font-semibold text-gray-400">
            {sliceText(record?.unit?.symbol?.[i18n.language], 10)}
          </span>
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-[10px] font-medium text-gray-200">{t("works.Fakt")}</span>
        <div className="flex w-full gap-2">
          <div className="h-[18px] w-1 bg-primary-500" />
          <span className="text-sm font-semibold text-white">
            {localeFormatter(String(record?.task_progress_quantity))}
          </span>
          <span className="text-sm font-semibold text-gray-400">
            {sliceText(record?.unit?.symbol?.[i18n.language], 10)}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mt-7 flex h-8 items-center justify-center gap-3 rounded-lg border border-solid border-gray-300 px-4 py-[6px]">
      <span className="text-sm font-medium text-gray-700">{t("works.Jarayon")}:</span>
      <Tooltip
        arrow={false}
        placement="top"
        title={percentTooltipContent()}
        rootClassName="[&_.ant-tooltip-inner]:!p-0 [&_.ant-tooltip-inner]:!rounded-lg"
      >
        <div
          className={cx("w-max cursor-pointer rounded-2xl px-2 py-[2px] text-sm font-medium", {
            "bg-gray-50 text-[#344054]": record?.task_percent === 0,
            "bg-green-50 text-green-500":
              record?.task_percent && (record?.task_percent === 100 || record?.task_percent > 100),
            "bg-primary-50 text-primary-500":
              record?.task_percent && record?.task_percent > 0 && record?.task_percent < 100
          })}
        >
          {record?.task_percent?.toLocaleString("ru")} %
        </div>
      </Tooltip>
    </div>
  );
};

export default Percent;
