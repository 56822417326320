import React from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { fileViewerActions } from "store/reducers/file-viewer";

import { useAuth } from "modules/auth/hooks";

import { dayjsFormats } from "../../utils/constants/dayjsFormats";
import { downloadFile } from "../../utils/helpers/downloadFile";
import { downloadFileData } from "../../utils/helpers/downloadFileData";
import { getFirstLetter } from "../../utils/helpers/getFirstLetter";
import { ChatModel } from "../../utils/models/chatModel";

import FilesAndMessage from "./files-and-message";
import OnlyFiles from "./only-files";
import OnlyMessage from "./only-message";

import styles from "./chat.module.scss";

export const imageTypes = ["jpg", "svg", "png"];

export const isImage = (name: string) => {
  const split = name.split(".");
  const type = split[split?.length - 1];

  if (imageTypes.includes(type)) {
    return true;
  }

  return false;
};

export const onDownloadFile = (file: string) => {
  const { fileName, fileType } = downloadFileData(file);
  
  downloadFile(file, fileName, fileType as never);
};

const ChatMessage: React.FC<ChatModel> = ({ user, created_at, message, files, prevDate, ref }) => {
  const { user: userData } = useAuth();
  const dispatch = useDispatch();
  const { handleOpen } = fileViewerActions;
  const isActiveUser = userData?.id === user.id;
  const prev = prevDate && prevDate?.split(" ")[0];
  const date = created_at.split(" ")[0];
  const hour = created_at.split(" ")[1];

  const currentDate = () => {
    const diffDate = dayjs(date, dayjsFormats.DATE).diff(dayjs(), "day");

    if (diffDate === 0) {
      return "Bugun";
    }
    if (diffDate === 1) {
      return "Kecha";
    }
    return date;
  };

  const onOpenFileViewer = (format: string, url: string, name: string, id: number) => {
    dispatch(
      handleOpen({
        isOpen: true,
        format,
        url,
        name,
        id
      })
    );
  };

  return (
    <div ref={ref}>
      {prev !== date && (
        <div className={styles.date}>
          <span className={styles.date__line} />
          <span className={styles.date__content}>{currentDate()}</span>
          <span className={styles.date__line} />
        </div>
      )}
      <div className={`${styles.message} ${isActiveUser ? styles.message__active : ""}`}>
        <div className={styles.message__content}>
          <div className={styles.message__content__left}>
            {user.image ? (
              <img src={user.image} alt="" />
            ) : (
              <div className={styles.name}>{getFirstLetter(user.full_name)}</div>
            )}
          </div>
          <div className={styles.message__content__right}>
            <div className={styles.top}>
              <h5>{isActiveUser ? "Siz" : user.full_name}</h5>
            </div>
            <OnlyMessage files={files} hour={hour} message={message} />
            <OnlyFiles files={files} message={message} hour={hour} onOpenFileViewer={onOpenFileViewer} />
            <FilesAndMessage
              files={files}
              message={message}
              hour={hour}
              onOpenFileViewer={onOpenFileViewer}
              isActiveUser={isActiveUser}
            />
          </div>
        </div>
      </div>
      {/* {openImage && <FullImage setOpen={setOpenImage} imageUrl={imageUrl} />} */}
    </div>
  );
};

export default ChatMessage;
