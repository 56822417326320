import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import $api from "features/app/utils/helpers/axiosInstance";

import { useAppSelector } from "hooks/redux";

import { partyReducerActions } from "store/reducers/partyReducer";

import { errorHandler, ErrorRes, parseLocaledString, SuccessRes } from "modules/common";
import { PartyForm, partyFormGroupSchema, partyFormSchema } from "modules/party/create";
import { parseCustomFields } from "modules/party/create/helpers/parse-custom-fields";
import { successHandler } from "modules/party/create/helpers/success-handler";
import {
  dirtyValues,
  parseCheckProductsToForm,
  parseCustomFieldsToForm,
  parseProductsToForm,
  PartyUpdateReqPayload
} from "modules/party/update";
import { PartyView } from "modules/party/view";

type Props = {
  party: PartyView;
  children: (form: UseFormReturn<PartyForm>) => ReactNode;
};

const Index: React.FC<Props> = ({
  children,
  party: { status, agent, delivery_date, payment_date, custom_field_values, warehouse_products }
}) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setPartyUpdate } = partyReducerActions;
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const { isDiscount, isGroup, id, isProcess } = useAppSelector(({ partyReducer }) => partyReducer.update);

  const form = useForm<PartyForm>({
    resolver: zodResolver(
      isGroup ? partyFormGroupSchema : partyFormSchema.omit({ check_products: true, custom_field_values: true })
    ),
    defaultValues: {
      status,
      company_person_group: [],
      agent_id: agent?.id || undefined,
      payment_date: payment_date || undefined,
      delivery_date: delivery_date || undefined,
      warehouse_products: parseProductsToForm(warehouse_products),
      check_products: parseCheckProductsToForm(warehouse_products),
      custom_field_values: parseCustomFieldsToForm(custom_field_values, customFields)
    },
    mode: "onChange",
    criteriaMode: "all"
  });

  const updateParty = useMutation<SuccessRes, ErrorRes, PartyUpdateReqPayload>(
    async payload => {
      const { data } = await $api.put("supply/order-update", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: successHandler(qc)
    }
  );

  const onError = (error: FieldErrors) => {
    if (!(isGroup && error.warehouse_products)) {
      return new Error(error.root?.message);
    }

    return null;
  };

  const onSubmit = async (values: PartyForm) => {
    await new Promise(onSettled => {
      const payload: PartyUpdateReqPayload = {
        id: id!,
        status: values?.status,
        agent_id: values?.agent_id,
        payment_date: values?.payment_date,
        delivery_date: values?.delivery_date,
        discount_currency_id: isDiscount ? values?.discount_currency_id : undefined,
        discount: isDiscount ? parseLocaledString(values?.discount || "") : undefined,
        warehouse_products: dirtyValues({
          products: warehouse_products,
          values: isGroup ? values?.company_person_group : values?.warehouse_products,
          isGroup
        }),
        custom_field_values: parseCustomFields(form.getValues("custom_field_values"))
      };

      updateParty
        .mutateAsync(payload, {
          onSettled
        })
        .then(() => {
          dispatch(
            setPartyUpdate({
              isDiscount: false,
              isGroup: false,
              visible: false,
              isVat: false,
              id: undefined,
              isProcess
            })
          );
        });
    });
  };

  return (
    <form className="h-full flex-1" onSubmit={form.handleSubmit(onSubmit, onError)}>
      {children(form)}
    </form>
  );
};

export default Index;
