import { useMemo } from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { RU } from "../../../../../app/utils/constants/languages";
import { DynamicEstimateSectionModel } from "../../../../utils/models/dynamicEstimateModel";

import styles from "./diffPopover.module.scss";

type Props = {
  sections?: DynamicEstimateSectionModel[];
  isFeature?: boolean;
  getPopoverContainer?: () => HTMLElement;
};

const DiffPopoverAllAmount = ({ sections, isFeature, getPopoverContainer }: Props) => {
  const currency = useAppSelector(state => state.projectsReducer.projectCurrency);
  const { t } = useTranslation();

  const totalAmount = useMemo(
    () => sections?.reduce((first, second) => first + second.total_amount, 0) ?? 0,
    [sections]
  );

  const spendTotalAmount = useMemo(
    () => sections?.reduce((first, second) => first + second.spend_total_amount, 0) ?? 0,
    [sections]
  );

  const expectedTotalAmount = useMemo(
    () => sections?.reduce((first, second) => first + second.spend_total_amount + second.expected_total_amount, 0) ?? 0,
    [sections]
  );

  const totalClassType = () => {
    if (isFeature) {
      if (totalAmount >= expectedTotalAmount) {
        return "success_amount";
      }
      return "error_amount";
    }
    if (totalAmount > spendTotalAmount) {
      return "success_amount";
    }
    return "error_amount";
  };

  const title = (
    <div className={styles.popover__inner}>
      <div className={styles.popover__inner__item}>
        <p>{t("projects.Plan")}</p>
        <p>
          {totalAmount?.toLocaleString(RU) ?? 0} {currency?.symbol}
        </p>
      </div>
      <div className={styles.popover__inner__item}>
        <p>{t("projects.Fakt")}</p>
        <p>
          {spendTotalAmount?.toLocaleString(RU)} {currency?.symbol}
        </p>
      </div>
      {isFeature && (
        <div className={styles.popover__inner__item}>
          <p>{t("projects.Bashorat")}</p>
          <p>
            {expectedTotalAmount?.toLocaleString(RU)} {currency?.symbol}
          </p>
        </div>
      )}
      <div className={styles.popover__inner__item}>
        <p>{t("projects.Tafovut")}</p>
        <p className={styles[totalClassType()]}>
          {isFeature
            ? Math.abs(totalAmount - expectedTotalAmount)?.toLocaleString(RU)
            : Math.abs(totalAmount - spendTotalAmount)?.toLocaleString(RU)}{" "}
          {currency?.symbol}
        </p>
      </div>
    </div>
  );

  return (
    <Popover getPopupContainer={getPopoverContainer} title={title} overlayClassName={styles.diff__popover}>
      <p className={styles[totalClassType()]}>
        {isFeature ? expectedTotalAmount?.toLocaleString(RU) : spendTotalAmount?.toLocaleString(RU)} {currency?.symbol}
      </p>
    </Popover>
  );
};

export default DiffPopoverAllAmount;
