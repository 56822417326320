import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Select } from "antd";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { parseLocaledString } from "modules/common";
import { PartyForm, useVats } from "modules/party/create";

type Props = { form: UseFormReturn<PartyForm> } & (
  | {
      type: "initial";
    }
  | {
      type: "group";
      parentIndex: number;
    }
);

const Index: React.FC<Props> = props => {
  const { type, form } = props;
  const { vats, isLoading } = useVats();
  const { t } = useTranslation()

  const onChange = (e: number, option: any) => {
    const value = Number(option?.props?.name) || 0;

    if (type === "initial") {
      const oldValue = form.getValues("warehouse_products");

      form.setValue(
        "warehouse_products",
        oldValue?.map(item => {
          const total = parseLocaledString(item?.total || "0");

          return { ...item, vat_id: e, vat_value: value, vat_amount: total * (value / 100) };
        })
      );
    }

    if (props.type === "group") {
      const oldValue = form.getValues(`company_person_group.${props.parentIndex}`);

      form.setValue(`company_person_group.${props.parentIndex}`, {
        ...oldValue,
        products: [
          ...(oldValue.products?.map(product => {
            const total = parseLocaledString(product?.total || "0");

            return {
              ...product,
              vat_id: e,
              vat_value: value,
              vat_amount: total * (value / 100)
            };
          }) || [])
        ]
      });
    }
  };

  return (
    <Select
      placeholder={t("payment.NDS")}
      loading={isLoading}
      onChange={onChange}
      labelRender={() => t("payment.NDS")}
      popupMatchSelectWidth={false}
      suffixIcon={<ChevronDownIcon rotate width={18} />}
      options={vats?.map(vat => ({
        key: vat?.id,
        value: vat?.id,
        label: <>{vat?.value} %</>,
        props: {
          name: String(vat?.value)
        }
      }))}
    />
  );
};

export default Index;
