import React from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormReturn
} from "react-hook-form";
import { Collapse } from "antd";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { cx, isEmptyArr } from "modules/common";
import { PartyForm } from "modules/party/create";

import AddPerson from "pages/party/create/content/left/group/add-person";
import Table from "pages/party/create/content/left/group/table";

import { ConditionalRender } from "components";

import DeletePerson from "./delete-person";
import TotalGroup from "./total";

import styles from "./index.module.scss";

type Props = {
  isUpdate?: boolean;
  remove: UseFieldArrayRemove;
  form: UseFormReturn<PartyForm>;
  append: UseFieldArrayAppend<PartyForm, "company_person_group">;
  update: UseFieldArrayUpdate<PartyForm, "company_person_group">;
  fields: FieldArrayWithId<PartyForm, "company_person_group", "group_id">[];
};

const Index: React.FC<Props> = ({ form, isUpdate, fields, append, remove, update }) => {

  const { t } = useTranslation()

  return (
  <div className="flex flex-1 flex-col gap-3">
    <ConditionalRender
      if={!isEmptyArr(fields)}
      else={
        <div className="flex items-center justify-center rounded-xl border border-solid border-gray-300 bg-white p-4">
          <TableEmpty description={t("partyCreate.Guruhlangan ma'lumotlar mavjud emas")} />
        </div>
      }
    >
      <Collapse
        rootClassName={styles.group}
        items={fields?.map((item, index) => ({
          defaultOpen: true,
          key: item?.group_id,
          label: (
            <div className="flex w-full items-center justify-between">
              <div
                className={cx(
                  "flex items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium",
                  { "border-gray-600 text-gray-600": item?.id },
                  { "border-warning-500 text-warning-500": !item?.id }
                )}
              >
                {item?.name}
              </div>
              <div className="flex items-center gap-2">
                <TotalGroup control={form.control} index={index} key={item?.group_id} />
                <DeletePerson fields={fields} index={index} remove={remove} isUpdate={isUpdate} key={item?.group_id} />
              </div>
            </div>
          ),
          children: (
            <Table
              form={form}
              append={append}
              remove={remove}
              update={update}
              fields={fields}
              parentIndex={index}
              isUpdate={isUpdate}
              key={item?.group_id}
            />
          )
        }))}
        defaultActiveKey={fields?.map(item => item?.id || 0) || []}
        expandIcon={({ isActive }) => <ChevronDownIcon rotate={isActive} />}
      />
    </ConditionalRender>
    <AddPerson form={form} append={append} />
  </div>
)};

export default Index;
