import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, Form, Input, Modal, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import WorkSelect from "features/app/components/work-select";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "modules/common";

import { CalendarPlusIcon } from "components";

import { useAppSelector } from "../../../../../hooks/redux";
import { RootState } from "../../../../../store";
import { defectModalActions } from "../../../../../store/reducers/defectModalReducer";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import ConfirmationUser from "../../../../app/components/confirmation-user/ConfirmationUser";
import CustomUpload from "../../../../app/components/custom-upload/CustomUpload";
import { formRules } from "../../../../app/utils/constants/formRules";
import { RU } from "../../../../app/utils/constants/languages";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { FileListType } from "../../../../app/utils/models/fileListModel";
import { UserSelectModel } from "../../../../app/utils/models/user/userSelectModel";
import { useGetProjectSelect } from "../../../../payment/service/queries";
import { useCreateDefect, useUpdateDefect } from "../../../service/mutation";
import { NewDefectEnum } from "../../../utils/constants/DefectEnum";
import { defectTypes } from "../../../utils/constants/DefectType";
import { WarehouseDefectFormModel } from "../../../utils/models/warehouseDefectFormModel";

import styles from "./defectModal.module.scss";

const { Item, useWatch } = Form;
const { TextArea } = Input;
const { Option } = Select;

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => void;

const DefectModal: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setDefectModal } = defectModalActions;
  const [fileList, setFileList] = useState<FileListType[]>([]);
  const [usersState, setUsersState] = useState<UserSelectModel[] | undefined>([]);
  const { visible, data } = useAppSelector((state: RootState) => state.defectModalReducer);

  const updateDefect = useUpdateDefect();
  const createDefect = useCreateDefect();
  const { data: projects, isLoading: isLoadingProjects } = useGetProjectSelect({
    enabled: visible
  });
  const projectId = useWatch("project_id", form);

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      if (data) {
        form.setFieldsValue({
          project_id: data.project?.id,
          product_id: data.product?.id,
          warehouse_id: data.warehouse?.id,
          unit_id: data.unit?.id,
          quantity: data.total_quantity,
          type: data.type,
          confirmation_user_ids: data.confirmation_users?.map(item => item.id)
        });

        setUsersState(data.confirmation_users?.map(item => item.user));
      }
      form.setFieldValue("defect_date", dayjs());
    }
  };

  const onCancel = () => {
    dispatch(
      setDefectModal({
        visible: false,
        data: undefined
      })
    );

    // setImage(undefined);
    setUsersState([]);
    form.resetFields();
  };

  // const uploadFile = () => {
  //   const copyImg = image as RcFile;

  //   return (
  //     <div className="upload_file">
  //       <div className="upload_file__icon">
  //         <UploadFileIcon />
  //       </div>
  //       <div className="upload_file__name">
  //         <p>{copyImg.name}</p>
  //         <span>{copyImg.size / 1024 / 1000} MB</span>
  //       </div>
  //     </div>
  //   );
  // };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (fields: WarehouseDefectFormModel) => {
    if (!data?.id) {
      const reqBody = {
        ...fields,
        defect_date: dayjs(fields?.defect_date, dayjsFormats.DATE).format(dayjsFormats.DATE)
      };

      createDefect.mutateAsync(reqBody).then(onCancel);
    } else {
      updateDefect
        .mutateAsync({
          id: data.id,
          type: fields.type,
          product_id: fields.product_id,
          unit_id: fields.unit_id,
          quantity: fields.quantity,
          warehouse_id: fields.warehouse_id,
          project_id: fields.project_id,
          task_id: fields?.task_id
        })
        .then(onCancel);
    }
  };

  return (
    <Modal
      centered
      open={visible}
      afterOpenChange={onAfterOpen}
      onCancel={onCancel}
      className={styles.defect_modal}
      okButtonProps={{
        loading: createDefect.isLoading || updateDefect.isLoading
      }}
      onOk={onOk}
      title="Yaroqsizga chiqarish"
      cancelText="Yopish"
      okText="Saqlash"
    >
      <Form onFinish={onFinish} form={form} className={styles.form} layout="vertical">
        <Item name="warehouse_id" className="d_n" />
        <Item name="unit_id" className="d_n" />
        <Item name="product_id">
          <div className={styles.product}>
            <div className="resource">
              <div
                style={{
                  borderColor: data?.product?.resource?.color,
                  color: data?.product?.resource?.color
                }}
              >
                {data?.product?.resource?.symbol[i18n.language]}
              </div>
              <span>{data?.product?.name[i18n.language]}</span>
            </div>
            <div className={styles.product__right}>
              {data?.total_quantity?.toLocaleString(RU) ?? 0} {data?.unit?.symbol[i18n.language]}
            </div>
          </div>
        </Item>
        <div className={styles.form__grid}>
          <Item name="quantity" label="Miqdori" rules={formRules()}>
            <Input type="number" placeholder="soni" suffix={data?.unit?.symbol[i18n.language]} />
          </Item>
          <Item name="type" label="Yaroqsizlik turi" rules={formRules()}>
            <Select suffixIcon={<SelectSuffixIcon />} placeholder="Tanlang">
              {Object.values(NewDefectEnum).map((item, index) => (
                <Option key={index} value={item}>
                  {defectTypes[item]}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className={styles.form__grid}>
          <ConfirmationUser
            form={form}
            data={usersState}
            name="confirmation_user_ids"
            setData={setUsersState as SetUserType}
          >
            <Item label="Guvohlar" name="confirmation_user_ids" className={styles.users_cont} rules={formRules()}>
              {isEmptyArr(usersState) ? (
                <Button className="add_user" type="dashed" shape="circle">
                  <PlusIcon />
                </Button>
              ) : (
                <div className={styles.users}>
                  {usersState?.map(user => (
                    <Tooltip key={user.id} title={user.full_name}>
                      {user?.image ? (
                        <img src={user.image} alt="" />
                      ) : (
                        <span className={styles.non_image}>{getFirstLetter(user?.full_name)}</span>
                      )}
                    </Tooltip>
                  ))}
                </div>
              )}
            </Item>
          </ConfirmationUser>
          <Item name="project_id" label={!data?.project?.id ? "Loyiha" : ""} rules={formRules()}>
            {!data?.project?.id && (
              <Select
                placeholder={t("defect.Tanlang")}
                loading={isLoadingProjects}
                suffixIcon={<SelectSuffixIcon />}
                onChange={e => {
                  form.setFieldValue("task_id", undefined);
                }}
              >
                {projects?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </div>
        <WorkSelect
          name="task_id"
          formItemClassName="w-full"
          label={t("defect.Ish")}
          project_id={projectId}
          disabled={!projectId}
        />
        {!data?.id && (
          <>
            <Item name="defect_date" rules={formRules()} label={t("defect.Sana")}>
              <DatePicker
                suffixIcon={<CalendarPlusIcon />}
                placeholder={t("Xodimlar.Sana")}
                format={dayjsFormats.DATE}
              />
            </Item>
            <Item name="description" label={t("defect.Izoh")}>
              <TextArea placeholder={t("defect.Izoh")} />
            </Item>
            {/* <UploadFile
              setImage={setImage}
              image={image}
              form={form}
              name="file"
              viewImage={false}
            />
            {image && uploadFile()} */}
            <CustomUpload isArray form={form} name="file_ids" fileList={fileList} setFileList={setFileList} />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default DefectModal;
