import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const orderTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "new_ordered_date",
      name: "new_ordered_date",
      title: "Buyurtma sanasi",
      checked: false
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Yetkazilish sanasi",
      checked: true
    },
    {
      id: "payment_date",
      name: "payment_date",
      title: "To'lov sanasi",
      checked: true
    },
    {
      id: "agent",
      name: "agent",
      title: "Vositachi",
      checked: true
    },
    {
      id: "warehouse_counterparts",
      name: "warehouse_counterparts",
      title: "Kontragentlari",
      checked: true
    },
    {
      id: "process",
      name: "process",
      title: "Jarayon",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "confirmation_payment",
      name: "confirmation_payment",
      title: "Tasdiqlash",
      checked: true
    }
  ],
  size: 10
};
