import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { localeFormatter, parseLocaledString } from "modules/common";
import { PartyForm } from "modules/party/create";

import { Input } from "components";

type Props = {
  index: number;
  form: UseFormReturn<PartyForm>;
};

const Index: React.FC<Props> = ({ form, index }) => {
  const { t } = useTranslation()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(e.target.value);
    const { quantity, vat_value } = form.getValues(`warehouse_products.${index}`);
    const parseQuantity = parseLocaledString(quantity || "0");

    form.setValue(`warehouse_products.${index}.amount`, localeFormatter(e.target.value));
    form.setValue(`warehouse_products.${index}.total`, localeFormatter(String(parseValue * parseQuantity)));
    form.setValue(
      `warehouse_products.${index}.vat_amount`,
      parseLocaledString(String(parseValue * parseQuantity) || "0") * ((vat_value || 0) / 100)
    );
  };

  return (
    <Input
      withoutMessage
      autoComplete="off"
      onChange={onChange}
      control={form.control}
      placeholder={t("partyCreate.Birlik narxi")}
      errorClassName="party-create-error"
      wrapperClassName="party-create-input"
      name={`warehouse_products.${index}.amount`}
    />
  );
};

export default Index;
