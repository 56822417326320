import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import DatepickerSuffixIcon from "../../assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../utils/constants/dayjsFormats";
import { CustomFieldProps } from "../../utils/helpers/customFields";

const DateCustomField = ({ disabled, placeholder, suffixIcon, onChange, value, onBlur }: CustomFieldProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DatePicker
        value={value && dayjs(value, dayjsFormats.DATE).isValid() && dayjs(value, dayjsFormats.DATE)}
        placeholder={t(`app.${placeholder}`) || t("app.Tanlang")}
        format={dayjsFormats.DATE}
        suffixIcon={suffixIcon || <DatepickerSuffixIcon />}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(value, dateString) => {
          if (onChange && typeof dateString === "string") {
            onChange(dateString);
          }
        }}
      />
    </>
  );
};

export default DateCustomField;
