import { Select, Spin } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../../../../../../../app/assets/icons/SelectSuffixIcon";
import { LoadingIndicator } from "../../../../../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { projectStatuses } from "../../../../../../../../../../utils/enums/projectStatuses";
import { useTaskUpdate } from "../../../../services/mutation";
import { taskStatus } from "../../../../utils/constants/taskStatus";
import { GanttTaskType } from "../../../../utils/models/GanttTaskType";
import { TaskStatus } from "../../../../utils/models/TaskStatus";

import styles from "./ganttTableTaskStatus.module.scss";

interface IProps {
  status: TaskStatus;
  sectionId: number;
  id: number;
  projectStatus?: projectStatuses;
  elementType?: GanttTaskType;
}

const { Option } = Select;

const GanttTableTaskStatus = ({ id, status, sectionId, projectStatus, elementType }: IProps) => {
  const taskUpdate = useTaskUpdate(sectionId);
  const { t } = useTranslation();

  const onChange = (value: TaskStatus) => {
    taskUpdate.mutate({
      id,
      status: value
    });
  };

  return (
    <div className={styles.status_select}>
      {elementType === "section" || projectStatus !== projectStatuses.PLANNING ? (
        <div
          className={styles.status}
          style={{
            background: taskStatus[status]?.color
          }}
        >
          {taskStatus[status]?.text}
        </div>
      ) : (
        <Spin spinning={taskUpdate.isLoading} indicator={LoadingIndicator}>
          <Select
            value={status}
            onChange={onChange}
            loading={taskUpdate.isLoading}
            suffixIcon={<SelectSuffixIcon />}
            popupClassName={styles.popup__select}
            className={`${styles.select} ${styles[status]}`}
          >
            <Option value={TaskStatus.OPEN}>
              <div className={styles.option}>
                <div className={styles.open} />
                {t(`project.${taskStatus.open.text}`)}
              </div>
            </Option>
            <Option value={TaskStatus.PROCESS}>
              <div className={styles.option}>
                <div className={styles.process} />
                {t(`project.${taskStatus.process.text}`)}
              </div>
            </Option>
            <Option value={TaskStatus.COMPLETED}>
              <div className={styles.option}>
                <div className={styles.completed} />
                {t(`project.${taskStatus.completed.text}`)}
              </div>
            </Option>
          </Select>
        </Spin>
      )}
    </div>
  );
};

export default GanttTableTaskStatus;
