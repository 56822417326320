import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { useAuth } from "modules/auth/hooks";

import Loading from "../../features/app/components/loading/Loading";
import MainLayout from "../../features/app/components/main-layout/MainLayout";
import { exactRouteFixer } from "../../features/app/utils/helpers/exactRouterFixer";
import NotFound from "../../features/not-found/Index";
import { Auth } from "../../pages";

import Protected from "./protected";
import { rootPaths } from "./rootPaths";
import { rootRoutes } from "./rootRoutes";

const RootRouter: React.FC = () => {
  const { permissions, isAuthenticated, user } = useAuth();

  const routes = rootRoutes({ permissions }) || [];

  const defaultRoute =
    routes?.find(item => item?.permissionName === permissions?.[0]?.name?.uz)?.path || rootPaths.PROJECTS;

  return (
    <Routes>
      <Route path={rootPaths.AUTH} element={<Protected allow={!isAuthenticated} to={defaultRoute} />}>
        <Route index element={<Auth />} />
      </Route>

      <Route element={<Protected allow={isAuthenticated} layout={MainLayout} to={rootPaths.AUTH} />}>
        {routes.map(({ path, isExact, element: Element }) => (
          <Route
            key={path}
            path={exactRouteFixer(path!, isExact)}
            element={
              <Suspense fallback={<Loading />}>
                <Element />
              </Suspense>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default RootRouter;
