import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../../store/reducers/companyPersonReducer";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetCompanyPersonSelect } from "../../../../../../../counterparts/service/queries";

type Props = {
  form: FormInstance;
  index: number;
};

const { Item } = Form;
const { Option } = Select;

const PartyCompanyPersonSelect: React.FC<Props> = ({ form, index }) => {
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;

  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const { visible, isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: companyPerson } = useGetCompanyPersonSelect(visible);
  const { t } = useTranslation();

  const onAddCompanyPerson = () => {
    dispatch(
      setVisibleModal({
        visible: true,
        initialData: {
          name: searchValue,
          phone: ""
        },
        onAfterFunc: (data: { id: number }) => {
          form.setFieldValue(["warehouse_products", index, "company_person_id"], data?.id);
        }
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  // const selectedId = () => form.getFieldValue(["warehouse_products", index, "company_person_id"]);
  //
  // const selected = () => companyPerson?.find(item => item?.id === selectedId());

  return (
    // <PerfItem
    //   placeholder={<div className="px-[0.62rem] py-4">{selected()?.name}</div>}
    //   visible={!(isView && !updateProduct?.condition)}
    // >
    <Item name={[index, "company_person_id"]} rules={!isEditing ? formRules()! : []}>
      <Select
        showSearch
        suffixIcon={null}
        onSearch={onSearch}
        searchValue={searchValue}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        disabled={isView && !updateProduct?.condition}
        placeholder={isView ? t("payment.Tanlanmagan") : t("payment.Ta'minotchi")}
        notFoundContent={<SelectNotContent title={t("payment.Ta'minotchi")} action={onAddCompanyPerson} />}
      >
        {companyPerson?.map(item => (
          <Option
            value={item.id}
            key={item.id}
            props={{
              name: item?.name
            }}
          >
            {item?.name}
          </Option>
        ))}
      </Select>
    </Item>
    // </PerfItem>
  );
};

export default PartyCompanyPersonSelect;
