import React from "react";

import IncomeExpenseHistoryTable from "./table";

const IncomeExpenseHistory: React.FC = () => (
  <div>
    <IncomeExpenseHistoryTable />
  </div>
);

export default IncomeExpenseHistory;
