import React, { ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Form, Input, InputRef, Select, Spin } from "antd";
import classNames from "classnames";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../../app/components/modal-confirm/ModalConfirm";
import PopoverShortName from "../../../../../../app/components/popover-short-name/PopoverShortName";
import { useGetCustomFieldSelect, useGetTableConfigs, useGetUnitSelect } from "../../../../../../app/service/queries";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { tableConfigKeys } from "../../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../../app/utils/enums/customFieldLocationEnum";
import { areObjectsEqual, isEmptyObj } from "../../../../../../app/utils/helpers/areObjectsEqual";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { CustomFieldSelectModel } from "../../../../../../app/utils/models/customFieldSelectModel";
import WarningIcon from "../../../../../assets/icons/WarningIcon";
import { useCreateProduct, useDeleteProduct, useUpdateProduct } from "../../../../../service/mutations";
import { estimateTableDefaultData } from "../../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../../utils/helpers/estimateColumnsWidth";
import { TaskProductBodyModel } from "../../../../../utils/models/taskProductBodyModel";
import { TaskProductFormFieldsModel } from "../../../../../utils/models/taskProductFormFieldsModel";
import { TaskProductModel } from "../../../../../utils/models/taskProductModel";
import EstimateCustomField from "../../../estimate-custom-field/EstimateCustomField";

import styles from "../task.module.scss";

type Props = {
  task_id: number;
  active: boolean;
  afterSuccess?: () => void;
  sectionPlan: boolean;
  taskPlan: boolean;
  isSearch?: boolean;
  data?: TaskProductModel;
  setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Item } = Form;
const { Option } = Select;

const TaskProduct: React.FC<Props> = ({ data, task_id, active, afterSuccess, isSearch, sectionPlan, taskPlan }) => {
  const { i18n, t } = useTranslation();
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const [form] = Form.useForm<TaskProductFormFieldsModel>();
  const quantityRef = useRef<InputRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [activeDeleteBtn, setActiveDeleteBtn] = useState<boolean>(false);

  const deleteProduct = useDeleteProduct();
  const createProduct = useCreateProduct();
  const updateProduct = useUpdateProduct();
  const { data: units } = useGetUnitSelect();
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;

    form.setFieldValue("amount", localeFormatter(target.value));

    const amount = parseLocaledString(target.value);
    const quantity = Number(form.getFieldValue("quantity"));

    form.setFieldValue("amount", localeFormatter(target.value));

    form.setFieldValue("total_amount", localeFormatter(String((amount * quantity).toFixed(2))));
  };

  const onBlurForm = () => {
    let oldData;
    const newData = form.getFieldsValue();
    const checkNewData = {
      ...newData,
      custom_field_values: newData?.custom_field_values?.filter(item => !isEmptyObj(item))
    };

    if (active && data) {
      oldData = {
        unit_id: data.unit?.id,
        quantity: data.quantity,
        amount: localeFormatter(String(data.amount)),
        total_amount: localeFormatter(String(data?.total_amount)),
        custom_field_values: data?.custom_field_values?.map(item => ({
          custom_field_id: item?.custom_field?.id,
          value: item?.value
        }))
      };
    }

    if (!oldData) {
      form.submit();
    } else {
      areObjectsEqual(oldData, checkNewData) && form.submit();
    }

    setActiveDeleteBtn(false);
  };

  const onFocusForm = () => {
    setActiveDeleteBtn(false);
  };

  const onDelete = () =>
    deleteProduct.mutateAsync({
      id: data!.id!,
      task_id
    });

  const onFinish = (fields: TaskProductFormFieldsModel) => {
    const isEqual = areObjectsEqual<TaskProductFormFieldsModel, unknown>(
      {
        ...fields,
        custom_field_values: fields?.custom_field_values?.filter(item => !isEmptyObj(item))
      },
      {
        ...data,
        unit_id: data?.unit?.id,
        product_id: data?.product.id,
        custom_field_values: data?.custom_field_values?.map(item => ({
          custom_field_id: item?.custom_field?.id,
          value: item?.value
        }))
      }
    );

    const reqData: TaskProductBodyModel = {
      ...fields,
      task_id,
      amount: parseLocaledString(String(fields?.amount)),
      total_amount: parseLocaledString(fields?.total_amount ?? "0"),
      product_ids: [fields.product_id],
      custom_field_values: fields?.custom_field_values?.filter(item => item?.value)
    };
    // @ts-ignore

    delete reqData.product_id;

    if (isEqual) {
      if (!data) {
        createProduct.mutateAsync(reqData).then(() => {
          afterSuccess && afterSuccess();
          form.resetFields();
        });
      } else {
        updateProduct
          .mutateAsync({
            ...reqData,
            id: data.id
          })
          .then(() => setActiveDeleteBtn(false));
      }
    }
  };

  const onChangeTotalAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const totalAmount = parseLocaledString(target.value);
    const quantity = Number(form.getFieldValue("quantity"));

    form.setFieldValue("total_amount", localeFormatter(target.value));

    form.setFieldValue("amount", localeFormatter(String((totalAmount / quantity).toFixed(2))));
  };

  const onChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const quantity = parseLocaledString(event.currentTarget?.value);
    const amount = Number(parseLocaledString(form.getFieldValue("amount")));
    const totalAmount = parseLocaledString(String(+quantity * amount));

    form.setFieldValue("total_amount", localeFormatter(String(totalAmount)));
  };

  const onClickResource = () => {
    quantityRef.current?.focus();
  };

  const defaultColumnsRender = () => {
    const defaultColumns: ReactNode[] = [];

    tableConfigData?.column?.forEach(column => {
      if (column?.checked && column?.id === "quantity") {
        defaultColumns.push(
          <Item name="quantity" className={classNames(styles.form__item, styles.amount)}>
            <Input placeholder={t("projects.Hajmi")} onChange={onChangeQuantity} />
          </Item>
        );
      }
      if (column?.checked && column?.id === "unit") {
        defaultColumns.push(
          <Item name="unit_id" className={classNames(styles.form__item, styles.unit)}>
            <Select
              showSearch
              placeholder={t("projects.Birligi")}
              suffixIcon={null}
              popupMatchSelectWidth={false}
              filterOption={selectFilterOption}
              onBlur={onBlurForm}
              getPopupContainer={() => smetaContainer}
            >
              {units?.map(item => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item?.symbol[i18n.language]
                  }}
                >
                  {item?.symbol[i18n.language]}
                </Option>
              ))}
            </Select>
          </Item>
        );
      }
      if (column?.checked && column?.id === "amount") {
        defaultColumns.push(
          <Item name="amount" className={classNames(styles.form__item, styles.price)}>
            <Input placeholder={t("projects.Narxi")} onChange={onChangeAmount} />
          </Item>
        );
      }
    });

    return defaultColumns;
  };

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  useEffect(() => {
    if ((active && data) || (isSearch && data)) {
      form.setFieldsValue({
        product_id: data.product.id,
        amount: localeFormatter(String(data.amount)),
        quantity: data.quantity,
        unit_id: data.unit?.id,
        total_amount: localeFormatter(String(data?.total_amount)),
        custom_field_values: customFieldsColumnRender?.map(item => {
          const currentValue = data?.custom_field_values?.find(custom => item?.id === custom?.custom_field?.id);

          return {
            value: currentValue?.value,
            custom_field_id: currentValue?.custom_field?.id
          };
        })
      });
    } else {
      form.setFieldsValue({
        quantity: 1,
        amount: "0"
      });
    }
  }, [data, active, form, isSearch, customFieldsColumnRender]);

  const gridStyle = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth(tableConfigData)} 16.25rem`
  };

  return (
    <Spin
      spinning={deleteProduct.isLoading || createProduct.isLoading || updateProduct.isLoading}
      indicator={LoadingIndicator}
    >
      <div className={classNames(styles.task__body, styles.task__header)} ref={contentRef}>
        <Form
          form={form}
          style={gridStyle}
          autoComplete="off"
          onFinish={onFinish}
          onFocus={onFocusForm}
          className={styles.form}
        >
          <Item className={classNames(styles.form__item, styles.name, styles.product_name)} rules={formRules()}>
            {!data?.plan && sectionPlan && taskPlan && (
              <div className={styles.warning}>
                <WarningIcon />
              </div>
            )}
            <div className={styles.resource} onClick={onClickResource}>
              <div
                style={{
                  color: data?.product?.resource.color,
                  border: `0.0625rem solid ${data?.product?.resource.color}`
                }}
              >
                {data?.product?.resource.symbol[i18n.language]}
              </div>
              <span>
                <PopoverShortName
                  getPopoverContainer={() => smetaContainer}
                  title={data?.product?.name[i18n.language]}
                  length={33}
                />
              </span>
            </div>
          </Item>
          {defaultColumnsRender()}
          {EstimateCustomField({
            form,
            onBlur: onBlurForm,
            values: data?.custom_field_values,
            customFields: customFieldsColumnRender,
            className: classNames(styles.form__item),
            locations: CustomFieldLocationEnum.TASK_PRODUCT
          })}
          <div className={classNames(styles.last_column, styles.product)}>
            <Item name="total_amount" className={classNames(styles.form__item, styles.amount_quantity)}>
              <Input placeholder={t("projects.Umumiy narx")} onChange={onChangeTotalAmount} />
            </Item>
            <div
              className={classNames(styles.delete, {
                [styles.active]: activeDeleteBtn
              })}
            >
              <ModalConfirm onOk={onDelete} getPopupContainer={() => smetaContainer}>
                <span>
                  <DeleteIcon />
                </span>
              </ModalConfirm>
            </div>
          </div>
          <Button className={styles.submit} htmlType="submit" />
        </Form>
      </div>
    </Spin>
  );
};

export default TaskProduct;
