import React from "react";
import { Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import i18n from "i18next";

import { RU } from "modules/common";
import { totalAmount, WarehouseProduct } from "modules/party/view";

import { ConditionalRender } from "components";

import TableEmpty from "../../../../../features/app/components/table-empty/TableEmpty";

import styles from "./index.module.scss";

type Props = {
  products: WarehouseProduct[] | undefined;
};

const Table: React.FC<Props> = ({ products }) => {
  const total = totalAmount(products || []);

  const columns: ColumnsType<WarehouseProduct> = [
    {
      title: "№",
      render: (_, __, index) => index + 1
    },
    {
      title: "Mahsulot nomi",
      render: (_, record) => record?.product?.name?.[i18n.language]
    },
    {
      title: "Soni",
      render: (_, record) => `${record?.quantity}  ${record?.unit?.symbol?.[i18n.language]}`
    },
    {
      title: "Loyiha",
      render: (_, record) => record?.project?.name || "--"
    },
    {
      title: "Omborxona",
      render: (_, record) => record?.warehouse?.name
    },
    {
      title: "Ta'minotchi",
      render: (_, record) => record?.company_person?.name || "--"
    },
    {
      title: "Narxi",
      children: [
        {
          title: "Birlik",
          render: (_, record) => `${record?.amount?.toLocaleString(RU)}  ${record?.currency?.symbol}`
        },
        {
          title: "Summa",
          render: (_, record) =>
            `${Number(record?.vat?.value ? (record?.amount * record?.quantity)?.toFixed(2) : record?.total_amount)?.toLocaleString(RU)}  ${record?.currency?.symbol}`
        },
        {
          title: "NDS",
          render: (_, record) => `${record?.vat?.value || 0} %`
        },
        {
          title: "Jami",
          render: (_, record) => `${record?.total_amount?.toLocaleString(RU)}  ${record?.currency?.symbol}`
        }
      ]
    }
  ];

  const footer = () => (
    <div className="flex items-center justify-between">
      <span className="text-xs font-medium text-black">Jami:</span>
      {total?.map((price, index) => (
        <div key={price?.currencyId} className="flex items-center gap-2 text-xs font-medium text-black">
          <span>{price?.amount?.toLocaleString(RU) || 0}</span>
          <span>{price?.currencySymbol}</span>
          <ConditionalRender if={total[index + 1]?.currencyId}>
            <span>,</span>
          </ConditionalRender>
        </div>
      ))}
    </div>
  );

  return (
    <AntTable<WarehouseProduct>
      rowKey="id"
      dataSource={products}
      columns={columns}
      rootClassName={styles.table}
      pagination={false}
      footer={footer}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
};

export default Table;
