import React from "react";
import { Button, Dropdown, Switch } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import ReportProductModal from "features/warehouse/components/income-expense-history/report-product-modal";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { colors } from "modules/common";
import { GroupingIcon } from "modules/works";

import { DotsVerticalIcon } from "components";

const TitleActions: React.FC = () => {
  const { t } = useTranslation();

  const dropdownRender = (child: React.ReactNode) => <div onClick={e => e.stopPropagation()}>{child}</div>;

  const { queries, append, severalRemove } = useQueryParams();
  const { group_by } = queries();

  const onSwitchChange = (value: boolean) => {
    if (value) {
      append(queryParamsKeys.GROUP_BY, String(value), false, [queryParamsKeys.PAGE, queryParamsKeys.SIZE]);
    } else {
      severalRemove(queryParamsKeys.GROUP_BY, queryParamsKeys.PAGE, queryParamsKeys.SIZE);
    }
  };

  const items = [
    {
      key: "0",
      label: (
        <label
          htmlFor="grouping"
          className="flex cursor-pointer items-center justify-between"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
            <GroupingIcon stroke={colors.GRAY_500} width={15} height={15} /> {t("works.Guruhlash")}
          </div>
          <Switch id="grouping" onChange={onSwitchChange} checked={!!(group_by && group_by === "true")} />
        </label>
      )
    },
    {
      key: "1",
      label: (
        <ReportProductModal>
          <div className="flex items-center gap-3 text-sm font-medium text-gray-700">
            <DownloadIcon />
            {t("payment.Hisobotni yuklab olish")}
          </div>
        </ReportProductModal>
      )
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} dropdownRender={dropdownRender}>
      <Button onClick={e => e.stopPropagation()}>
        <DotsVerticalIcon />
      </Button>
    </Dropdown>
  );
};

export default TitleActions;
