import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Dropdown, Form, FormInstance, Spin } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../../store/reducers/supplyReducer";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../../app/assets/icons/EditIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { StatusEnums } from "../../../../../../app/components/statuses/statusEnums";
import { useGetUnitSelect } from "../../../../../../app/service/queries";
import { colors } from "../../../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import { ErrorRes, SuccessRes } from "../../../../../../app/utils/models/responseType";
import { impostQueryKeys } from "../../../../../../impost/utils/constants/impostQueryKeys";
import CalculateIcon from "../../../../../assets/icons/CalculateIcon";
import CheckCircleIcon from "../../../../../assets/icons/CheckCircleIcon";
import { useSupplyProductUpdate } from "../../../../../service/mutations";
import { routeSubmodules } from "../../../../../utils/constants/routeSubmodules";
import { supplyQueryNames } from "../../../../../utils/constants/supplyQueryNames";
import { PartyFormOfferModel, PartyFormProductsModel } from "../../../../../utils/models/partyModalFormModel";

type Props = {
  form: FormInstance;
  groupIndex?: number;
  index: number;
  isPerson?: boolean;
  rowId?: string;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { useWatch } = Form;

export const PartyCreateOfferAction: React.FC<Props> = ({
  form,
  rowId,
  index,
  groupIndex,
  isPerson = false,
  deleteProduct
}) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { data: units } = useGetUnitSelect();
  const { setOfferModal } = supplyOfferActions;
  const productUpdate = useSupplyProductUpdate();
  const { setPartyUpdateProduct } = supplyActions;
  const status = useWatch("status", form) as StatusEnums;
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const productAction = actions("Buyurtmalar");
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const offers = useWatch("offers", form) as PartyFormOfferModel[];
  const isGroupPerson = useWatch("is_group_person", form) as boolean;
  const product = useWatch(isPerson ? ["person_group", groupIndex, "data", index] : ["warehouse_products", index]);
  const { isView, isEditing, updateProduct, party_id } = useAppSelector(state => state.supplyReducer.partyModal);

  const viewOffers = useMemo(() => isView && !isEmptyArr(offers), [isView, offers]);

  const onCreateOffer = () => {
    const itemData = form.getFieldValue(
      isGroupPerson ? ["person_group", groupIndex, "data", index] : ["warehouse_products", index]
    ) as PartyFormProductsModel;

    const selectProducts = form.getFieldValue("select_products") as ProductSelectModel[];

    const deliveryDate = form.getFieldValue("delivery_date");

    const findProduct = selectProducts.find(product => product.id === itemData.product_id) as ProductSelectModel;

    const findUnit = units?.find(unit => unit?.id === itemData.unit_id);

    dispatch(
      setOfferModal({
        visible: true,
        isEdit: false,
        viewPartySwitch: false,
        data: [
          {
            offers: [],
            amount: parseLocaledString(itemData?.amount),
            quantity: parseLocaledString(itemData?.quantity),
            delivery_date: dayjs(deliveryDate, dayjsFormats.DATE).format(dayjsFormats.DATE),
            product: {
              id: findProduct.id!,
              name: findProduct?.name,
              resource: findProduct?.resource
            },
            id: itemData.id!,
            unit: findUnit!
          }
        ]
      })
    );
  };

  const onUpdateProduct = () => {
    dispatch(
      setPartyUpdateProduct({
        condition: true,
        index: rowId
      })
    );
  };

  const onDeleteProduct = () => {
    deleteProduct.mutateAsync([product?.id]).then(async () => {
      await qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]);
      await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParam]);
      await qc.invalidateQueries([impostQueryKeys.PARTS, searchParam]);
    });
  };

  const offerActionItems = () => ({
    items: [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("payment.Tahrirlash")}
          </div>
        ),
        onClick: onUpdateProduct,
        isView: status !== StatusEnums.RECIEVED
      },
      {
        key: "2",
        label: (
          <div className="flex items-center gap-2">
            <CalculateIcon />
            {t("payment.Taklif yaratish")}
          </div>
        ),
        onClick: onCreateOffer,
        isView: true
      },
      {
        key: "3", 
        label: (
          <div className="flex items-center gap-2">
            <DeleteIcon />
            {t("payment.O'chirish")}
          </div>
        ),
        onClick: onDeleteProduct,
        isView: productAction?.delete && !product?.received_quantity
      }
    ].filter(item => item.isView)
  });

  const onProduct = () => {
    productUpdate
      .mutateAsync({
        id: product?.id,
        product_id: product?.product_id,
        unit_id: product?.unit_id,
        warehouse_id: product?.warehouse_id,
        project_id: product?.project_id,
        quantity: parseLocaledString(product?.quantity || "0"),
        company_person_id: product?.company_person_id,
        currency_id: product?.currency_id,
        amount: parseLocaledString(product?.amount || "0")
      })
      .then(() => {
        dispatch(
          setPartyUpdateProduct({
            condition: false
          })
        );
      });
  };

  const confirmProduct = (
    <Spin indicator={LoadingIndicator} spinning={productUpdate.isLoading}>
      <div
        onClick={onProduct}
        className="mr-2 box-border flex cursor-pointer items-center rounded-lg border border-solid border-primary-600 bg-primary-500 px-2 py-1.5"
      >
        <CheckCircleIcon color={colors.WHITE} />
      </div>
    </Spin>
  );

  const dotsContent = (
    <Dropdown trigger={["hover"]} menu={offerActionItems()} placement="bottom">
      <div className={cx("actions", "cursor-pointer")}>
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );

  return !viewOffers && !isEditing && status !== StatusEnums.RECIEVED ? (
    <ConditionalRender
      if={
        updateProduct?.condition && updateProduct?.index && rowId === updateProduct?.index
        // Endi faqat partiyani statusi yopilgan yoki qabul qilinmagan bo'lsa shu chiqa veradi
        // product?.status !== StatusEnums.RECIEVED
      }
      else={dotsContent}
    >
      {confirmProduct}
    </ConditionalRender>
  ) : null;
};

export default PartyCreateOfferAction;
