import { useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Spin, Tooltip } from "antd";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { cx } from "features/app/utils/helpers/cx";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { useQueryParams } from "../../../../../hooks/useQueryParams";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { getLocationPathValue } from "../../../../app/utils/helpers/getLocationValue";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { useGetDynamicCustomField, useGetDynamicEstimateSection } from "../../../service/queries";
import { getEstimateStatusIcon } from "../../../utils/constants/dynamicEstimateStatusIcon";
import { EstimateSegmentedEnums } from "../../../utils/enums/estimateSegmentedEnum";
import { groupTypes } from "../../../utils/enums/groupTypes";
import { DynamicEstimateSectionModel } from "../../../utils/models/dynamicEstimateModel";

import DiffPopoverAllAmount from "./diff-popover/diffPopoverAllAmount";
import Section from "./section/Section";

import styles from "./dynamicEstimate.module.scss";

type Props = {
  isLoading: boolean;
  isFeature: boolean;
  expandedAll: boolean;
  startPlanning: boolean;
  sections?: DynamicEstimateSectionModel[];
};

const DynamicEstimate = ({ expandedAll, sections, isLoading, isFeature, startPlanning }: Props) => {
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { queries } = useQueryParams();
  const projectId = getLocationPathValue(3);
  const { estimate_group } = useAppSelector(state => state.localStorageReducer);
  const { estimate_type, estimate_group: estimateGroupId, type } = queries();
  const estimateType = estimate_type ?? EstimateSegmentedEnums.PLAN;
  const { smetaContainer } = useOutletContext<OutletContextType>();

  const groupCustomFiled = useMemo(() => {
    if (estimateGroupId) {
      return {
        projectId,
        id: estimateGroupId,
        type: type || groupTypes.SECTION
      };
    }

    return estimate_group?.find(item => item?.projectId === projectId);
  }, [estimateGroupId, type, projectId, estimate_group]);

  const { data, isLoading: isLoadingGroup } = useGetDynamicEstimateSection(
    estimateType !== EstimateSegmentedEnums.PLAN,
    params.projectId
  );

  const { data: groupEstimate } = useGetDynamicCustomField({
    projectId: params.projectId,
    customFieldId: groupCustomFiled?.id,
    type
  });

  const totalAmountContent = {
    [EstimateSegmentedEnums.FACT]: (
      <DiffPopoverAllAmount getPopoverContainer={() => smetaContainer} sections={sections} />
    ),
    [EstimateSegmentedEnums.FEATURE]: (
      <DiffPopoverAllAmount getPopoverContainer={() => smetaContainer} sections={sections} isFeature />
    )
  };

  const sectionsContent = (sections: DynamicEstimateSectionModel[] | undefined) =>
    sections?.map(section => (
      <Section section={section} key={section?.id} isFeature={isFeature} expandedAll={expandedAll} />
    ));

  return (
    <Spin spinning={isLoading || isLoadingGroup} indicator={LoadingIndicator}>
      <div className={styles.dynamic_estimate}>
        <ConditionalRender if={groupCustomFiled?.id}>
          {groupEstimate?.map(item => (
            <div className={styles.container} key={item?.id}>
              <div
                className={cx(styles.custom_field, {
                  "pb-4": isEmptyArr(item?.sections)
                })}
              >
                <div className={styles.custom_field__title}>
                  <h2 className={styles.custom_field__name} style={{ color: item?.color || colors.GRAY_500 }}>
                    {item?.name[i18n.language]}
                  </h2>
                  <span>{item?.process} %</span>
                </div>
                <h2 className={styles.custom_field__amount}>
                  {estimateType === EstimateSegmentedEnums.FACT
                    ? item?.spend_total_amount?.toLocaleString(RU)
                    : item?.prediction_total_amount?.toLocaleString(RU)}
                  <Tooltip getPopupContainer={() => smetaContainer} title={`${item.process} %`}>
                    <div className="flex items-center">{getEstimateStatusIcon(item.process)}</div>
                  </Tooltip>
                </h2>
              </div>
              {sectionsContent(item?.sections as never)}
            </div>
          ))}
        </ConditionalRender>
        <ConditionalRender if={!groupCustomFiled?.id}>{sectionsContent(sections)}</ConditionalRender>
        <ConditionalRender if={!isEmptyArr(data) || startPlanning}>
          <div className={styles.total_amount}>
            <div className="sticky left-16">
              <h2>{t("projects.Jami")}:</h2>
            </div>
            <div className="sticky right-8">
              <h2>{totalAmountContent[estimateType as keyof typeof totalAmountContent]}</h2>
            </div>
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default DynamicEstimate;
