import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";

import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { RU } from "../../../../../app/utils/constants/languages";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import {
  PartyPrintDataModel,
  PartyPrintGenerateDataModel,
  PartyPrintModel
} from "../../../../../supply/utils/models/PartyPrintDataModel";

import styles from "../../../../../supply/components/parts/party-modal/party-print/partyPrint.module.scss";

type Props = {
  data: PartyPrintGenerateDataModel;
  totalPrice: number | undefined;
  projects?: Record<string, string | number>[];
  warehouses?: Record<string, string | number>[];
};

const PrintWarehousePartyTable: React.FC<Props> = ({ data: propsData, totalPrice, projects, warehouses }) => {
  const { data, sameWarehouse, sameProject } = propsData;

  const generateTotal = (item: PartyPrintModel) =>
    item?.data?.reduce((first, second) => first + (parseLocaledString(String(second?.total)) ?? 0), 0);

  const findProject = (id?: number) => projects?.find(item => item.id === id)?.name;

  const findWarehouse = (id?: number) => warehouses?.find(item => item.id === id)?.name;

  const generateSpan = (defaultSpan: number, isMinus?: boolean) => {
    if (sameWarehouse && sameProject) {
      return defaultSpan + (isMinus ? -6 : 6);
    }
    if (sameWarehouse || sameProject) {
      return defaultSpan + (isMinus ? -3 : 3);
    }

    return defaultSpan;
  };

  function getOrdinal() {
    let count = 0;

    return () => {
      count += 1;

      return count;
    };
  }

  const getNextOrdinal = getOrdinal();

  const generateCol = (item: PartyPrintDataModel[]) =>
    item?.map(product => (
      <Row key={product?.id} className={styles.table__row}>
        <Col span={2} className={styles.table__row__col}>
          {getNextOrdinal()}
        </Col>
        <Col span={generateSpan(11)} className={styles.table__row__col}>
          {product?.productName}
        </Col>
        <Col span={3} className={styles.table__row__col}>
          {product?.unitName}
        </Col>
        <Col span={2} className={styles.table__row__col}>
          {product?.quantity}
        </Col>
        <ConditionalRender if={!sameProject}>
          <Col span={3} className={styles.table__row__col}>
            {findProject(product?.projectId)}
          </Col>
        </ConditionalRender>
        <ConditionalRender if={!sameWarehouse}>
          <Col span={3} className={styles.table__row__col}>
            {findWarehouse(product?.warehouseId)}
          </Col>
        </ConditionalRender>
      </Row>
    ));

  return (
    <div className={styles.table}>
      <Row className={styles.table__header}>
        <Col span={2} className={styles.table__header__col}>
          №
        </Col>
        <Col span={generateSpan(11)} className={styles.table__header__col}>
          Mahsulot nomi
        </Col>
        <Col span={3} className={styles.table__header__col}>
          Birligi
        </Col>
        <Col span={2} className={classNames(styles.table__header__col, styles.table__header__col__not_border)}>
          Soni
        </Col>
        <ConditionalRender if={!sameProject}>
          <Col span={3} className={classNames(styles.table__header__col, styles.table__header__col__not_border)}>
            Loyiha
          </Col>
        </ConditionalRender>
        <ConditionalRender if={!sameWarehouse}>
          <Col span={3} className={classNames(styles.table__header__col, styles.table__header__col__not_border)}>
            Omborxona
          </Col>
        </ConditionalRender>
      </Row>
      {data?.map(item =>
        !item?.id ? (
          generateCol(item?.data)
        ) : (
          <div key={item?.id} className={styles.person}>
            <Row>
              <Col span={generateSpan(13)} className={styles.person__col}>
                {item?.name}
              </Col>
              <Col span={generateSpan(11, true)} className={classNames(styles.person__col, styles.person__total)}>
                Jami: {generateTotal(item)?.toLocaleString(RU)}
              </Col>
            </Row>
            {generateCol(item?.data)}
          </div>
        )
      )}
      <div className={styles.footer}>
        <Row>
          <Col span={generateSpan(13)} className={styles.footer__col}>
            Jami:
          </Col>
          <Col span={generateSpan(11, true)} className={classNames(styles.footer__col, styles.footer__total)}>
            {totalPrice?.toLocaleString(RU)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PrintWarehousePartyTable;
