import { z } from "zod";

import {
  companyPersonSchema,
  currencySchema,
  customFieldValueSchema,
  // idAndNameSchema,
  inventoryDetailSchema,
  productSchema,
  unitSchema,
  userSchema
} from "modules/common";

export const processTableSchema = z.object({
  id: z.number().nullable(),
  quantity: z.number().nullable(),
  created_date: z.string().nullable(),
  total_amount: z.number().nullable(),
  currency: currencySchema,
  product: productSchema.nullable().optional(),
  unit: unitSchema.nullable(),
  user: userSchema.nullable().optional(),
  company_person: companyPersonSchema.nullable().optional(),
  inventory: inventoryDetailSchema,
  amount: z.number().nullable(),
  place: z.number().nullable().optional(),
  task: z
    .object({
      id: z.number(),
      name: z.string(),
      place: z.number(),
      unit: unitSchema.nullable().optional(),
      section: z.object({
        id: z.string(),
        name: z.string(),
        place: z.number(),
        project_id: z.number(),
        project: z.object({
          id: z.number(),
          name: z.string()
        }),
        custom_field_values: z.array(customFieldValueSchema)
      })
    })
    .nullable()
    .optional(),
  task_progress_quantity: z.number().nullable().optional()
});

export const warehouseSchema = z.object({
  id: z.number(),
  name: z.string(),
  mine: z.boolean(),
  total_quantity: z.number()
});

export const spendingWorksSchema = z.object({
  id: z.number(),
  plan: z.boolean(),
  product: productSchema.nullable(),
  unit: unitSchema,
  warehouses: z.array(warehouseSchema).nullable()
});

export const spendingWorksByIdSchema = z.object({
  company_person: companyPersonSchema.nullable(),
  user: userSchema.nullable(),
  inventory: inventoryDetailSchema.nullable(),
  amount: z.number(),
  currency: currencySchema
});

export const spendWorksFormSchema = z.object({
  task_id: z.number(),
  quantity: z.number(),
  date: z.string().optional().nullable(),
  product_details: z.array(
    z.object({
      id: z.number(),
      warehouse_id: z.number().optional().nullable(),
      quantity: z.number(),
      unit_id: z.number().optional().nullable(),
      total_quantity: z.number().optional().nullable()
    })
  ),
  person_details: z.array(
    z.object({
      id: z.number(),
      company_person_id: z.number().optional().nullable(),
      user_id: z.number().optional().nullable(),
      quantity: z.number(),
      currency_id: z.number().nullable(),
      amount: z.number()
    })
  ),
  mechanism_details: z.array(
    z.object({
      id: z.number(),
      inventory_id: z.number(),
      quantity: z.number(),
      currency_id: z.number().nullable(),
      amount: z.number()
    })
  ),
  confirmation_user_ids: z.array(z.number()).optional().nullable(),
  file_ids: z.array(z.number()).optional().nullable(),
  description: z.string().optional().nullable(),
  custom_field_options: z.array(z.number()).optional().nullable()
});

export type SpendingWorksForm = z.infer<typeof spendWorksFormSchema>;
export type SpendingWorksByIdData = z.infer<typeof spendingWorksByIdSchema>;
export type Warehouses = z.infer<typeof warehouseSchema>;
export type SpendingWorksData = z.infer<typeof spendingWorksSchema>;
export type ProcessTableData = z.infer<typeof processTableSchema>;
