export enum CustomFieldLocationEnum {
  PROJECT = "project",
  SECTION = "section",
  TASK = "task",
  TASK_PRODUCT = "task_product",
  PAYMENT = "payment",
  WAREHOUSE_PRODUCT = "warehouse_product",
  WAREHOUSE_PRODUCT_OFFER = "warehouse_product_offer",
  WAREHOUSE_TRANSFER = "warehouse_transfer",
  WAREHOUSE_DEFECT = "warehouse_defect",
  INVENTORY_DEFECT = "inventory_defect",
  COMPANY_PERSON = "company_person",
  TASK_PROGRESS = "task_progress",
  INVENTORY = "inventory",
  ORDER = "order",
  WAREHOUSE = "warehouse",
  MONITORING_PAYMENTS = "monitoring_payments",
  MONITORING_WORKS = "monitoring_works",
  LABORATORIES = "laboratories",
  TRANSFER_MAKING_TABLE = "transfer-making-table",
  LIST_OF_WORKS = "list-of-works",
  DONE_WORKS = "done-works",
  PROCESS_DATA = "process-data",
  WAREHOUSE_INCOME_EXPENSE_HISTORY = "warehouse-income-expense-history",
  WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY = "warehouse-income-expense-history-group-by"
}
